/**
 * LIB 2 TRIAL: react-tag-autocomplete
 * example styling: https://github.com/i-like-robots/react-tag-autocomplete/blob/main/example/src/styles.css
 */
 .field label {
  margin-bottom: 5px !important;
}

.field .root {
  position: relative;
  /* padding: 0.25rem 0 0 0.25rem; */
  padding: 5px 2px 2px 5px;
  border: 1px solid rgba(255, 255, 255, .1);
  border-radius: .28571429rem; /* match the SUI input radius */
  background: var(--bg-dark);
  /* shared font styles */
  font-size: 1rem;
  line-height: 1.2;
  /* clicking anywhere will focus the input */
  cursor: text;

  display: flex;
  /* height: 38px; */
  /* display: flex;
  flex-direction: row;
  flex-wrap: nowrap; */
  /* overflow-x: scroll;
  overflow-y: hidden;
  overflow-wrap: normal; */
}
.field .isActive {
  /* border-color: #4f46e5; */
}
.field .isDisabled {
  opacity: 0.75;
  background-color: #eaeef2;
  /* Prevent any clicking on the component */
  pointer-events: none;
  cursor: not-allowed;
}
.field .isInvalid {
  border-color: #fd5956;
  box-shadow: 0 0 0 2px rgba(253, 86, 83, 0.25);
}
.field .tagsLabel {
  display: none;
}
.field .list {
  /* Do not use display: contents, it's too buggy */
  display: flex; /*inline;*/
  flex-direction: row;
  flex-wrap: nowrap;
  padding: 0;
  overflow-y: scroll;
  overflow-y: hidden;
  margin: 0;
}
.field .listItem {
  display: inline;
  list-style: none;
}
.field .tag {
  margin: 0 0.25rem 0.25rem 0;
  padding: 0.375rem 0.5rem;
  /* border: 0;
  border-radius: 3px;
  background: #eaeef2; */
  /* match the font styles */
  font-size: inherit;
  line-height: inherit;
  border: 1px solid var(--bg-dark);
  background-color: var(--th-ui-actionable);
  border-radius: 5px;
  color: var(--tx-light);

  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}
.field .tag:hover {
  color: #ffffff;
  background-color: var(--blue);
}
.field .tag::after {
  content: '';
  display: inline-block;
  width: 0.65rem;
  height: 0.65rem;
  clip-path: polygon(10% 0, 0 10%, 40% 50%, 0 90%, 10% 100%, 50% 60%, 90% 100%, 100% 90%, 60% 50%, 100% 10%, 90% 0, 50% 40%);
  margin-left: 0.5rem;
  font-size: 0.875rem;
  background-color: #7c7d86;
}
.field .tag:hover::after {
  background-color: #ffffff;
}

.field .tagName {
}

.field .combobox {
  display: inline-block;
  /* match tag layout */
  padding: 0.375rem 0.25rem;
  margin-bottom: 0.25rem;
  /* prevents autoresize overflowing the container */
  max-width: 100%;
}

.field .comboboxInput {
  /* prevent autoresize overflowing the container */
  max-width: 100%;
  /* remove styles and layout from this element */
  margin: 0;
  padding: 0 !important;
  border: 0;
  outline: none;
  background: none;
  border: none !important; /* override the SUI input border */
  /* match the font styles */
  font-size: inherit;
  line-height: inherit;
}

.field .comboboxInput::placeholder {
  color: #7c7d86;
  opacity: 1;
}

.field .listbox {
  position: absolute;
  z-index: 1;
  top: calc(100% + 0px);
  /* Negate the border width on the container */
  left: 0px;
  right: 0px;
  max-height: 12.5rem;
  overflow-y: auto;
  background: var(--th-ui-actionable);
  border: 1px solid rgba(255, 255, 255, .1);
  border-radius: 2px 2px 5px 5px;
  box-shadow: rgba(0, 0, 0, 0.1) 0 10px 15px -4px, rgba(0, 0, 0, 0.05) 0 4px 6px -2px;
}

.field .listboxOption {
  padding: 0.375rem 0.5rem;
}

.field .listboxOption:hover {
  cursor: pointer;
  background: rgba(0, 0, 0, .05);
}

.field .listboxOption:not([aria-disabled='true']).listboxOptionAction {
  background: var(--blue);
  color: #ffffff;
}

.field .listboxOption[aria-disabled='true'] {
  color: #7c7d86;
  cursor: not-allowed;
  pointer-events: none;
}

.field .listboxOption[aria-selected='true']::after {
  content: '✓';
  margin-left: 0.5rem;
}

.field .listboxOption[aria-selected='true']:not(.listboxOptionAction)::after {
  color: var(--blue);
}

.field .listboxOptionHighlight {
  background-color: var(--tx-muted); /*#ffdd00*/
}


/**
 * LIB 3 TRIAL: react-multi-select-component
 * NB: the lib imports its css directly, we just override specific styles here...
 */
.rmsc {
  --rmsc-main: #4285f4;
  --rmsc-hover: #f1f3f5;
  --rmsc-selected: #e2e6ea;
  --rmsc-border: #ccc;
  --rmsc-gray: #aaa;
  --rmsc-bg: #fff;
  --rmsc-p: 10px;
  --rmsc-radius: 4px;
  --rmsc-h: 38px;
  font-family: var(--th-font-main);
}
.rmsc.dark {
  --rmsc-main: #4285f4;
  --rmsc-hover: var(--blue);
  --rmsc-selected: #1d1915;
  --rmsc-border: rgba(255, 255, 255, .1);
  --rmsc-gray: var(--tx-muted);
  --rmsc-bg: var(--bg-dark);
  color: var(--tx-light);
}

/*
.rmsc * {
  box-sizing: border-box;
  transition: all 0.2s ease;
}

.rmsc .gray {
  color: var(--rmsc-gray);
}
*/
.rmsc :global(.dropdown-content) {
  /* position: absolute;
  z-index: 1;
  top: 100%;
  width: 100%;
  padding-top: 8px; */
  padding-top: 0;
}

.rmsc :global(.dropdown-content .panel-content) {
  /* overflow: hidden;
  border-radius: var(--rmsc-radius);
  background: var(--rmsc-bg);
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), 0 4px 11px rgba(0, 0, 0, 0.1); */
}

.rmsc :global(.dropdown-container) {
  /* position: relative;
  outline: 0;
  background-color: var(--rmsc-bg);
  border: 1px solid var(--rmsc-border);
  border-radius: var(--rmsc-radius); */
}
/*
.rmsc .dropdown-container[aria-disabled="true"]:focus-within {
  box-shadow: var(--rmsc-gray) 0 0 0 1px;
  border-color: var(--rmsc-gray);
}
*/
.rmsc :global(.dropdown-container):focus-within {
  /* box-shadow: var(--rmsc-main) 0 0 0 1px;
  border-color: var(--rmsc-main); */
  box-shadow: var(--rmsc-border) 0 0 0 0;
  border-color: var(--rmsc-border);
}

.rmsc :global(.dropdown-heading) {
  /* position: relative;
  padding: 0 var(--rmsc-p);
  display: flex;
  align-items: center;
  width: 100%;
  height: var(--rmsc-h);
  cursor: default;
  outline: 0; */
  padding: 0 0 0 var(--rmsc-p); /* NB: don't pad right side with custom arrow */
  height: 36px;
}

.rmsc :global(.dropdown-heading .dropdown-heading-value) {
  /* overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  flex: 1; */
  user-select: none; /* disable text selection - to avoid accidental selection/highlighting when clicking */
}
/*
.rmsc .clear-selected-button {
  cursor: pointer;
  background: none;
  border: 0;
  padding: 0;
  display: flex;
}
*/
.rmsc :global(.options) {
  /* max-height: 260px;
  overflow-y: auto;
  margin: 0;
  padding-left: 0; */
  padding: 0;
  margin: 0;
}

.rmsc :global(.options) li {
  /* list-style: none;
  margin: 0; */
}

.rmsc :global(.select-item) {
  /* box-sizing: border-box;
  cursor: pointer;
  display: block;
  padding: var(--rmsc-p);
  outline-offset: -1px;
  outline-color: var(--rmsc-primary); */
  color: var(--tx-muted) !important;
}

.rmsc :global(.select-item .item-renderer) {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.rmsc :global(.select-item) input[checkbox] {
}
.rmsc :global(.select-item) span {
  user-select: none; /* disable text selection - to avoid accidental selection/highlighting when clicking */
  text-transform: none;
}

.rmsc :global(.select-item):hover {
  /* background: var(--rmsc-hover); */
}

.rmsc :global(.select-item.selected) {
  /* background: var(--rmsc-selected); */
}
.rmsc :global(.select-item.selected):hover {
  background: var(--rmsc-hover);
}
/*
.rmsc .no-options {
  padding: var(--rmsc-p);
  text-align: center;
  color: var(--rmsc-gray);
}

.rmsc .search {
  width: 100%;
  position: relative;
  border-bottom: 1px solid var(--rmsc-border);
}

.rmsc .search input {
  background: none;
  height: var(--rmsc-h);
  padding: 0 var(--rmsc-p);
  width: 100%;
  outline: 0;
  border: 0;
  font-size: 1em;
}

.rmsc .search input:focus {
  background: var(--rmsc-hover);
}

.rmsc .search-clear-button {
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  background: none;
  border: 0;
  padding: 0 calc(var(--rmsc-p) / 2);
}

.rmsc .search-clear-button [hidden] {
  display: none;
}

.rmsc .item-renderer {
  display: flex;
  align-items: baseline;
}

.rmsc .item-renderer input {
  margin: 0;
  margin-right: 5px;
}

.rmsc .item-renderer.disabled {
  opacity: 0.5;
}

.rmsc .spinner {
  animation: rotate 2s linear infinite;
}

.rmsc .spinner .path {
  stroke: var(--rmsc-border);
  stroke-width: 4px;
  stroke-linecap: round;
  animation: dash 1.5s ease-in-out infinite;
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
}
*/

/* custom arrow - match our existing dropdowns */
.arrow {
  cursor: pointer;
  width: auto;
  height: auto;
  line-height: 1.21428571em;
  padding: 10px;
  font-family: Dropdown;
  font-weight: 400;
  font-style: normal;
  text-align: center;
  font-size: .85714286em;
  color: var(--tx-light);
  opacity: .8;
  speak: none;
  margin-right: 2px;
  /* border: 1px solid red; */
}
.arrow::before {
  content: "\f0d7";
}
.arrow.active {
  transform: rotate(180deg);
}

/* styleType: 2 == dropdownStyle */
.rmsc.dark.dropdownStyle {
  --rmsc-main: #4285f4;
  --rmsc-hover: var(--blue);
  --rmsc-selected: #1d1915;
  --rmsc-border: rgba(255, 255, 255, .1);
  --rmsc-gray: var(--bg-light);
  --rmsc-bg: var(--th-ui-actionable);
  color: var(--tx-light);
}
.rmsc.dark.dropdownStyle {
}
.rmsc.dark.dropdownStyle :global(.dropdown-container) {
  border: 1px solid var(--rmsc-bg); /* add a border the same colour as the bg so active/changes border changes don't visually change the dropdown size */
}

.rmsc.dark.dropdownStyle :global(.select-item.selected) {
  background: var(--rmsc-bg) !important;
}
.rmsc.dark.dropdownStyle :global(.select-item.selected):hover {
  background: var(--rmsc-hover) !important;
}


/**
 * LIB 1 TRIAL: react-tags
 * example styling: https://github.com/react-tags/react-tags/blob/master/example/public/reactTags.css
 */
/*
.field label {
  margin-bottom: 10px !important;
}

.field :global(.ReactTags__tagInput) {
  margin-top: 10px;
}
.field :global(.ReactTags__tags) {
  position: relative;
}
.field :global(.ReactTags__selected) {
}

.field :global(.ReactTags__tag) {
  padding: 5px 2px 5px 10px;
  margin-right: 5px;
  border: 1px solid var(--bg-dark);
  background-color: var(--th-ui-actionable);
  border-radius: 5px;
  color: var(--tx-light);
}
.field :global(.ReactTags__tag:first-child) {
  margin-left: 2px;
}
.field :global(.ReactTags__tag) button {
  margin-left: 5px;
  color: var(--bg-dark);
  background-color: var(--th-ui-actionable);
  border: none;
  border-radius: 5px;
  font-size: 1.2em;
}
.field :global(.ReactTags__tag) button:hover {
  color: var(--red);
}

.field :global(.ReactTags__suggestions) {
  position: absolute;
  z-index: 1001;
}
.field :global(.ReactTags__suggestions ul) {
  min-width: 150px;
  background: var(--th-ui-actionable);
  list-style-type: none;
  margin: 0;
  padding: 0;
  box-shadow: 0.05em 0.01em 0.5em rgba(0, 0, 0, 0.2);
  border-radius: 0 0 5px 5px;
}
.field :global(.ReactTags__suggestions ul li) {
  border-bottom: 1px solid var(--bg-dark);
  padding: 5px 10px;
  margin: 0;
  color: var(--tx-light);
}
.field :global(.ReactTags__suggestions ul li mark) {
  text-decoration: underline;
  background: none;
  font-weight: 600;
  color: var(--tx-light);
}
.field :global(.ReactTags__suggestions ul li.ReactTags__activeSuggestion) {
  background: rgba(0, 0, 0, .05);
  cursor: pointer;
}
*/
