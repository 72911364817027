
.detailsFieldset {
  margin-bottom: 0px;
}

.flagsFieldset {
  padding: 18px 15px 5px 15px; /* reduce the left & right padding to make more room for warning messages */
  margin-bottom: 0px;
}
.flagsFieldset :global(.field .field) {
  padding: 0 0 10px 20px;
}
.flagsFieldset :not(.flagWrapper) + .flagWrapper { /* first flag (wrapper) element only - ref: https://stackoverflow.com/a/13287975 */
  padding-top: 8px !important;
}
.videoEngineForm .flagLabel .flagTitle {
  min-width: 80px;
}
.videoEngineForm .flagLabel .flagTitle span {
  color: #fff; /* override the default toggle label colour change when its enabled (we just force it regardless of its on/off state) */
}
.flagsFieldset .flagWrapper {
  /* border: 1px solid red; */
}
.flagsFieldset .flagHint {
  /* border: 1px solid blue; */
  align-self: flex-start !important;
}

.hasChanged label,
.hasChanged label .flagTitle span {
  color: var(--green) !important; /* --orange */
  font-style: italic;
}
.hasChanged :global(.field .fieldsetTitle) {
  color: var(--green) !important; /* --orange */
  font-style: italic;
}

.controlsAndPortsGroup {
  margin-bottom: 20px !important;
}

.controlsAndPortsGroup .controlFieldset {
  margin-bottom: 0px !important;
}
.controlsAndPortsGroup .controlFieldset .controlField:global(.ten.wide.field) {
  width: 100% !important;
  margin-bottom: 5px;
}
.controlsAndPortsGroup .controlFieldset .controlField :global(.ui.input) {
  width: auto !important;
}
.controlsAndPortsGroup .controlFieldset .controlField :global(.ui.fluid.input) {
  flex: auto !important;
}

.controlsAndPortsGroup .portsFieldset {
  margin-bottom: 0px !important;
}
.controlsAndPortsGroup .portsFieldset > :global(.ui.field) {
  display: flex !important;
  flex-direction: row;
  flex-wrap: wrap;
  row-gap: 5px;
}
.controlsAndPortsGroup .portsFieldset .portField:global(.ten.wide.field) {
  width: 124px !important;
}
.controlsAndPortsGroup .portsFieldset .portField :global(.ui.input) {
  width: auto !important;
}
.controlsAndPortsGroup .portsFieldset .portField :global(.ui.fluid.input) {
  flex: auto !important;
}
.controlsAndPortsGroup .portsFieldset .portField :global(.ui.input) > input {
  /* width: 140px !important;
  flex: none !important; */
}
.controlsAndPortsGroup .portsFieldset .inputNumberRangeFields {
  min-width: 186px;
  padding: 15px 5px 10px 5px !important;
  margin: 5px 5px 5px 0 !important;
}
.controlsAndPortsGroup .portsFieldset .inputNumberRangeFields .inputNumberRangeFieldsAddBtn {
  text-align: center;
  margin-top: 0px !important;
}
.controlsAndPortsGroup .portsFieldset .inputNumberRangeFields .inputNumberRangeGroup {
  margin: 0 0 5px 0 !important;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items:flex-end;
}
.controlsAndPortsGroup .portsFieldset .inputNumberRangeFields .inputNumberRangeGroup.inputNumberRangeGroupLast {
  margin-bottom: 8px !important;
}
.controlsAndPortsGroup .portsFieldset .inputNumberRangeFields :global(.ten.wide.field) {
  width: 70px !important;
}
.controlsAndPortsGroup .portsFieldset .inputNumberRangeFields .inputNumberRangeStart,
.controlsAndPortsGroup .portsFieldset .inputNumberRangeFields .inputNumberRangeEnd {
  padding: 0 !important;
}
.controlsAndPortsGroup .portsFieldset .inputNumberRangeFields .inputNumberRangeStart label,
.controlsAndPortsGroup .portsFieldset .inputNumberRangeFields .inputNumberRangeEnd label {
  /* padding: 0 0 0 5px; */
  text-align: center;
}
.controlsAndPortsGroup .portsFieldset .inputNumberRangeFields .inputNumberRangeStart input,
.controlsAndPortsGroup .portsFieldset .inputNumberRangeFields .inputNumberRangeEnd input {
  padding: 10px !important;
  text-align: center;
}
.controlsAndPortsGroup .portsFieldset .inputNumberRangeFields .inputNumberRangeRemoveBtn {
  padding: 0 !important;
  margin: 0 0 2px 2px !important;
}
.controlsAndPortsGroup .portsFieldset .inputNumberRangeFields .inputNumberRangeRemoveBtn button {
  margin: 0 0 0 0 !important;
  padding: 12px 10px !important;
  color: var(--tx-light);
  background-color: var(--bg-dark);
}
.controlsAndPortsGroup .portsFieldset .inputNumberRangeFields .inputNumberRangeRemoveBtn button:hover {
  background-color: var(--bg-hover);
}
