.email {
  margin-top: 5px;
}
.email .title {
  font-weight: bold;
  margin-right: 5px;
}
.email .value {}

.divider {
  margin: 15px 0 !important;
}

.tfaFieldset {
}
.tfaInput {
  padding-top: 5px;
}
.tfaCancel {
  margin-top: 10px;
  text-align: center;
}

.passwordChangeForm2FA .fieldset {
  position: relative;
  padding: 18px 20px 20px 20px;
  margin: 0 0 2px 0;
}
.passwordChangeForm2FA .fieldsetBorder {
  border-radius: 5px;
  border: 1px solid var(--bd-light);
}
.passwordChangeForm2FA .fieldsetTitle {
  position: absolute;
  padding: 0px 10px 0 15px;
  top: -13px;
  left: 5px;
  /* background-color: var(--bg-dark); */
  background-color: var(--bg-dark-mid);
  font-weight: bold;
  font-size: 1.1em !important;
  text-transform: capitalize;
}
.passwordChangeForm2FA .divider {
  margin-top: 15px !important;
  margin-bottom: 20px !important;
}
.passwordChangeForm2FA .passwordChange2FANotice {
  margin-top: 0px;
}
