.modal {
}
.modal>:global(.content) {
  padding: 12px 20px 20px 20px !important;
}
.modal .modalTitle {
  text-transform: capitalize;
}
.modal .lowercase {
  text-transform: lowercase;
}
.projectlist {}
/* NB: using fixed blocks all scrolling, & avoids dealing with covering the scrolled area */
.loadingOverlay {
  position: fixed;
  top: 0px;
  bottom: 0px;
  left: 0px;
  height: auto;
  width: 100%;
  background-color: #000;
  opacity: 0.65;
}

.projectlist :global(.listIcon) {
  margin-right: 8px !important;
}
.projectlist :global(.listBody) {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.projectlist .accessDisabled :global(.listIcon svg) {
  fill: var(--tx-dim) !important;
}
.projectlist .accessDisabled :global(.listBody .header),
.projectlist .accessDisabled :global(.listBody .description) {
  color: var(--tx-dim) !important;
}
.projectlist .accessDisabled .accessDisabledMsg {
  padding-left: 8px;
  font-weight: normal;
  font-style: italic;
}