.nodeOuter {
  animation: nodeOuterAnimation 0.5s;
  cursor: pointer;
  padding: 20px;
  transition: 0.5s;
  /* background-color: rgba(255, 0, 0, 0.25); */
}

@keyframes nodeOuterAnimation {
  to { opacity: 1 }
}

.nodeBlurred {
  animation: nodeBlurredAnimation 2s;
  opacity: 0.25;
}

@keyframes nodeBlurredAnimation {
  from { opacity: 1 }
  to { opacity: 0.25 }
}

.nodeFocused {
  transform: scale(1.1);
}

.nodeInner {
  position: relative;
}

.handle {
  cursor: inherit !important;
  opacity: 0;
}

.icon {
  position: absolute;
  right: 0;
  top: 0;
  transform: translate(50%, -50%);
}

.selected {
  border: 2px solid var(--blue);
  border-radius: 6px;
  bottom: 0;
  left: 0;
  margin: -22px;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 0;
  transition: 0.2s;
}

.selectedHidden {
  opacity: 0
}

.popup {
  min-width: 200px !important;
}

.popupTitle {
  font-size: 11px;
  margin-top: -4px;
  opacity: 0.5;
  text-transform: uppercase;
}

.popupName {
  font-weight: bold;
}

.popupDisabledContainer {
  align-items: center;
  display: flex;
}

.popupDisabledText {
  color: var(--th-danger);
  font-size: 12px;
  text-transform: uppercase;
}

.popupEditButton {
  position: absolute;
  right: 0;
  top: 0;
}
