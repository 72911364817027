.container {
  background-color: var(--bg-dark-card);
  border: 1px solid transparent;
  border-radius: 5px;
  cursor: pointer;
  padding: 10px;
}

.active {
  border-color: var(--ui-selected);
}

.container:not(.active):hover {
  border-color: var(--ui-hover);
}

.row {
  align-items: center;
  display: flex;
  /* background-color: rgba(255, 0, 0, 0.25); */
}

.date {
  font-family: var(--th-font-mono);
  font-size: 12px;
}

.user {
  font-weight: 600;
}

.message {}

.messageClamp {
  display: -webkit-box;
  line-clamp: 2;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

.detailsFieldset {
  border: 1px solid var(--bd-mid);
  border-radius: 5px;
  margin: 0;
  padding: 10px;
}

.detailsRow {
  display: flex;
  font-size: 13px;
  line-height: 1;
}

.detailsRowTitle {
  font-weight: 600;
  width: 100px;
}

.detailsRowValue {}

.detailsRowValueDisabled {
  color: var(--tx-dim);
}

.buttons {
  display: flex;
  justify-content: flex-end;
}
