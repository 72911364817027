.container {
  align-items: center;
  display: flex;
  flex-direction: column;
  min-width: 80px;
  text-align: center;
}

.container.horizontal {
  flex-direction: row;
  justify-content: center;
}

.title {
  align-items: center;
  color: var(--tx-muted);
  display: flex;
}
