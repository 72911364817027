.hintContainer {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.dateRangeContainer {
  display: flex;
}

.nestedContainer {
  margin-left: 20px;
}

.footer {
  display: flex;
}

.footerButton {
  margin: 0 !important;
}
