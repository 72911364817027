.button {
  /* background-color: var(--th-ui-actionable) !important; */
  color: var(--tx-light) !important;
  flex-shrink: 0;
  font-family: var(--th-font-main) !important;
  font-weight: 500 !important;
  /* margin: 0 !important; */
  text-transform: uppercase !important;
  transition: background-color 0.3s ease, color 0.3s ease, opacity 0.3s ease !important;
}
.button.defaultColor {
  background-color: var(--th-ui-actionable) !important;
}

.button.defaultColor:focus,
.button.defaultColor:hover {
  background-color: var(--th-ui-actionable-hover) !important;
  color: var(--ui-hover) !important;
}

:global(.blue).button {
  background-color: var(--blue) !important;
}

:global(.green).button {
  background-color: var(--green) !important;
}

:global(.orange)button {
  background-color: var(--orange) !important;
}

:global(.red).button {
  background-color: var(--red) !important;
}

:global(.yellow).button {
  background-color: var(--yellow) !important;
}

:global(.blue).button:hover,
:global(.green).button:hover,
:global(.orange)button,
:global(.red).button:hover,
:global(.yellow).button:hover {
  color: var(--tx-light) !important;
  opacity: 0.875;
}

.group > .button {
  flex: 1 !important;
}
