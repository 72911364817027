.view {
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  min-height: 100%;
}

.buttons {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: -10px;
}

.button {
  border-radius: 10px !important;
  height: 240px;
  margin: 10px !important;
  width: 320px;
  background-color: var(--th-ui-actionable)!important;
}

.button:hover {
  background-color: var(--th-ui-actionable-hover)!important;
  color: var(--bg-light)!important;
}

.buttonTitle {
  font-size: 15px;
}
