.watermarkView {
  /* width: 100%; */
  width: 1000px;
  padding-top: 10px;
  border: 0px solid red;
}

.watermarkView .watermarkingDisabled {
  padding: 5px;
}

.watermarkView :global(.ui.message.positive),
.watermarkView :global(.ui.message.negative) {
  padding: 10px;
  margin: 0px !important; /* reset some defaults */
  margin-bottom: 10px !important;
  margin-right: 10px !important;
}
.watermarkDataError {
  margin: 10px 5px 5px 5px;
  min-width: 360px;
}

.panel {
  border: 1px solid var(--tx-light-mid);
  border-radius: 4px;
  padding: 10px;
  margin: 0 10px 10px 0;
}

.topPanel {
  display: flex;
}

.topPanel .imagePanel {
  display: flex;
}
.topPanel .imagePanel .preview {
  width: 250px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.topPanel .imagePanel .preview .noImg {
  width: 100%; /*173px;*/
  height: 100%; /*90px;*/
  border-radius: 4px;
  background-color: var(--tx-light-mid);
  opacity: 0.2;
  margin: auto;
}
.topPanel .imagePanel .preview img {
  max-height: 100px;
  max-width: 200px;
  display: block;
  margin: auto;
}
.topPanel .imagePanel .imageControls .stats {
  font-size: 0.9em;
}
.topPanel .imagePanel .imageControls .stats .size {
  padding-top: 2px;
}
.topPanel .imagePanel .imageControls .upload {
  display: flex;
}
.topPanel .imagePanel .imageControls .upload .imgUpload {
  display: none;
}
.topPanel .imagePanel .imageControls .upload .imgUploadBtn, 
.topPanel .imagePanel .imageControls .upload .imgUploadRemoveBtn {
  display: block;
}
.topPanel .imagePanel .imageControls .upload .imgUploadRemoveBtn {
  margin-left: 10px !important;
}

.topPanel .controlsPanel {
  /* parent container */
  flex-grow: 2;
  /* border & spacing */
  border: 1px solid var(--tx-light-mid);
  border-radius: 4px;
  padding: 10px 10px 10px 10px;
  margin: 0 10px 10px 0;
  /* child content */
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  gap: 10px
}
.topPanel .controlsPanel .controlsCol1,
.topPanel .controlsPanel .controlsCol2 {
  flex-grow: 1;
  /* flex-basis: 50%; */
}
.topPanel .controlsPanel .slider {
  min-width: 150px;
  max-width: 250px;
  margin: 0 auto 5px auto;
}
.topPanel .controlsPanel .slider .sliderLabels {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0px;
  font-size: 0.9em;
}
.topPanel .controlsPanel .controlsCol1 .checkbox {
  text-align: right;
}
.topPanel .controlsPanel .checkbox {
  font-size: 0.9em;
}
.topPanel .controlsPanel .checkbox input {
  margin-right: 10px;
}

.watermarkPanel {
  display: flex;
}
/*
.watermarkPanel .controls {
  flex-grow: 1;
  display: flex;
  justify-content: space-between;
}
.watermarkPanel .controls .controlsCol1,
.watermarkPanel .controls .controlsCol2 {
  border: 1px solid var(--tx-light-mid);
  border-radius: 10px;
  padding: 30px 10px 10px 10px;
  margin: 10px;
  flex-grow: 1;
  flex-basis: 50%;
}
.watermarkPanel .controls .controlsCol1 {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.watermarkPanel .controls .slider {
  min-width: 150px;
  max-width: 250px;
  margin: 0 auto 10px auto;
}
.watermarkPanel .controls .slider .sliderLabels {
  display: flex;
  justify-content: space-between;
  margin-bottom: 2px;
}
.watermarkPanel .controls .checkbox {
  min-width: 150px;
  max-width: 250px;
  margin: 0 auto 10px auto;
  text-align: right;
}
.watermarkPanel .controls .checkbox input {
  margin-right: 10px;
}
.watermarkPanel .controls .watermarkSize {
  flex-grow: 2;
}
.watermarkPanel .controls .watermarkTransparency {
  margin-bottom: 20px;
}
.watermarkPanel .controls .watermarkFlip {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  min-width: 150px;
  max-width: 250px;
  margin: auto;
  padding: 0 0 10px 0;
  border: 0px solid red;
}
.watermarkPanel .controls .watermarkFlip .checkbox {
  min-width: 0 !important;
  margin: 0;
  padding: 0;
  text-align: left;
}
.watermarkPanel .controls .watermarkOrigin .title {
  margin-bottom: 5px;
}
.watermarkPanel .controls .watermarkOrigin .grid {
  display: grid;
  gap: 10px;
  grid-template-columns: repeat(3, 1fr);
}
.watermarkPanel .controls .watermarkOrigin .grid .originItem {
  border: 1px solid var(--tx-light-mid);
  border-radius: 4px;
  text-align: center;
  padding: 10px;
}
.watermarkPanel .controls .watermarkOrigin .grid .originItem:hover {
  cursor: pointer;
}
.watermarkPanel .controls .watermarkOrigin .grid .originItem.current {
  background-color: red;
}
*/
.watermarkPanel .preview {
  /* width: 444px; */
  /* height: 250px; */
  /* aspect-ratio: 16/9; */ /* TESTING - TODO: compatibility? */
  border-radius: 4px;
  border: none;
  position: relative;
  margin: 0 10px 10px 0;
  /* background-color: var(--tx-light-mid);
  display: flex;
  justify-content: center;
  align-items: center;*/
  overflow: hidden;
}
.watermarkPanel .screenBG {
  background-color: var(--tx-light-mid);
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.watermarkPanel .preview .playIcon {
  opacity: 0.5;
}
.watermarkPanel .preview .img {
  position: absolute;
  /* top: 0px;
  left: 0px;
  width: 50px;
  height: 50px; */
  /* margin: 0 !important;
  padding: 0 !important; */
  /* border: 1px solid red; */
}
.watermarkPanel .preview .img img {
  border: 1px solid red;
  margin: 0;
  padding: 0;
}

.watermarkPanel .rightPanel {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* align-items: center; */
  gap: 10px;
  margin: 0 10px 10px 0;
}
.watermarkPanel .rightPanel .previewBGSelector {
}
.watermarkPanel .rightPanel .previewBGSelector .previewBGField label {
  font-weight: normal;
  font-size: 0.9em;
}
.watermarkPanel .rightPanel .previewBGSelector .previewBGField :global(.ui.dropdown) {
  padding: 6px 6px 6px 1em;
  min-height: auto;
  font-size: 0.9em;
}
.watermarkPanel .rightPanel .previewBGSelector .previewBGField :global(.ui.dropdown .icon) {
  padding: 6px 10px;
}
.watermarkPanel .rightPanel .previewBGSelector .previewBGField :global(.ui.dropdown .menu>.item) {
  padding: 6px 6px 6px 1.1em !important;
  font-size: 1em;
}
.watermarkPanel .rightPanel .previewBGSelector .previewBGField :global(.ui.dropdown .menu>.item.active) {
  /* background-color: red; */
}
.watermarkPanel .saveDelControls {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
}
.watermarkPanel .saveDelControls .watermarkSave,
.watermarkPanel .saveDelControls .watermarkDelete {
  width: 100%;
}
.watermarkPanel .saveDelControls button {
  font-size: 1.0em !important;
  font-weight: bold;
}
.watermarkPanel .saveDelControls .watermarkSave button {
  background-color: #2185d0 !important;
}


.watermarkCanvas canvas {
  border: 1px solid var(--tx-light-mid);
}

.watermarkExport {
  max-width: 80vw;
  border: 1px solid var(--tx-light-mid);
  border: 1px solid var(--tx-light-mid);
  border-radius: 10px;
  padding: 10px;
  margin: 10px;
  display: flex;
  overflow:scroll;
}
.watermarkExportHidden {
  display: none;
}
