.projectStatus {
  border: 1px solid var(--white);
  background-color: var(--white);
  border-radius: 5px;
  padding: 10px;
  margin: 15px 0 10px 0;
}
.projectStatus.projectStatusSlim {
  margin-top: 0px;
}
.projectStatus .title {
  font-size: 1.3em;
  font-weight: bold;
}
.projectStatus .desc {
  font-style: italic;
  font-size: 1.2em;
  margin-top: 5px;
}

.videoEngineNone {
  border-color: var(--orange);
  background-color: var(--orange);
}
.videoEngineDisabled {
  border-color: var(--orange);
  background-color: var(--orange);
}
.videoEngineEnabled {
  border-color: var(--green);
  background-color: var(--green);
}
