.companyForm {}

.detailsFieldset {
  margin-bottom: 0px;
}

.flagsFieldset {
  padding: 18px 15px 5px 15px; /* reduce the left & right padding to make more room for warning messages */
  margin-bottom: 0px;
}
.flagsFieldset :global(.field .field) {
  padding: 0 0 10px 20px;
}
.flagsFieldset :not(.flagWrapper) + .flagWrapper { /* first flag (wrapper) element only - ref: https://stackoverflow.com/a/13287975 */
  padding-top: 8px !important;
}
.companyForm .flagLabel .flagTitle {
  min-width: 80px;
}
.companyForm .flagLabel .flagTitle span {
  color: #fff; /* override the default toggle label colour change when its enabled (we just force it regardless of its on/off state) */
}
.flagsFieldset .flagWrapper {
  /* border: 1px solid red; */
}
.flagsFieldset .flagHint {
  /* border: 1px solid blue; */
  align-self: flex-start !important;
}

.hasChanged label,
.hasChanged label .flagTitle span {
  color: var(--green) !important; /* --orange */
  font-style: italic;
}