
.wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}
.image {
  margin-right: 10px;
  padding-top: 20px;
  border: 0px solid red;
}

.formFields {
  flex-grow: 2;
}

.videoFilename.hasValue {
  margin-bottom: 5px !important;
}

.videoInputFileDupeNotice {
  color: var(--orange);
}
.videoInputFileDupeNotice p {
  margin: 0;
}
.videoInputFileDupeNotice ul {
  margin-top: 0px;
  margin-bottom: 0px;
  padding: 0 0 0 20px;
}

.inputAudioFieldset {
  margin-bottom: 0px;
}
.inputAudioFieldset:global(.closed) {
  border: 1px solid red;
  padding: 0px;
  margin-bottom: 0px;
}
.audioInputNotice {
}
.audioInputWarning {
  display: block;
  margin-top: 5px;
  color: var(--orange);
}

.overlaysWarning {
  color: var(--orange);
}

.assetAVInfo {
  margin: 0 0 25px 5px !important;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 2px;
  font-size: 0.9em;
}
