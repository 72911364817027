.container {
  position: relative;
}

.textArea {
  background-color: var(--bg-dark) !important;
  border-radius: 5px !important;
  color: var(--tx-light) !important;
  font-family: var(--th-font-main);
  resize: none !important;
}

.textArea::-webkit-scrollbar {
  display: none;
}

.textArea:not(:focus) {
  border: 1px solid var(--bd-mid) !important;
}

.textAreaClear {
  padding-right: 40px !important;
}

.clearButton {
  position: absolute;
  right: 0;
  top: 0;
}
