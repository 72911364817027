/**
 * ArkPanel
 */
.panel {
}
.panel.panelBorder {
  border-radius: 6px;
  border: 1px solid var(--bd-light);
  background-color: var(--bg-dark-card);
  /* margin-bottom: 10px; */
}
.panelTitle {
  margin: 10px 0px 10px!important;
  padding: 5px 5px 5px 15px !important;
  text-transform: uppercase!important;
}


/**
 * ArkPanel.Header
 */
.headerPanel {
  text-transform: capitalize;
}
.headerPanel.bordered {
  margin-bottom: 5px !important;
  border-bottom: 1px solid var(--bd-light);
}
.headerPanel :global(.content) {
  display: flex !important;
  flex-direction: column;
  justify-content: center;
}
.headerPanel :global(.description) {
  margin-top: 0 !important;
}
.headerPanel .desc {
  opacity: 0.65;
}
.headerPanel .meta {
  opacity: 0.65;
}

/**
 * ArkPanel.Footer
 */
.footerPanel {
  margin: 5px 0px 0px 0px !important;
}
.footerPanel.bordered {
  padding-top: 10px;
  border-top: 1px solid var(--bd-light);
}

/**
 * ArkPanel.Properties
 */
.propertiesPanel {
}

/**
 * ArkPanel.PropertyRow
 */
.propertiesPanel .property {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  padding: 5px 0 5px 0;
  /* border: 1px solid red; */
}
.propertiesPanel .property .title, 
.propertiesPanel .property .value,
.propertiesPanel .property .hint {
  border: 0px solid red;
}
.propertiesPanel .property .title {
  flex-basis: var(--panel-title-width);
  flex-grow: 0;
  flex-shrink: 0;
  text-align: right;
  font-weight: bold;
  text-transform: capitalize;
  color: var(--tx-muted);
}
.propertiesPanel .property .title:global(.small) {
  flex-basis: var(--panel-title-width-small) !important;
}
.propertiesPanel .property .title:global(.medium) {
  flex-basis: var(--panel-title-width-medium) !important;
}
.propertiesPanel .property .title:global(.large) {
  flex-basis: var(--panel-title-width-large) !important;
}
.propertiesPanel .property .title:global(.xlarge) {
  flex-basis: var(--panel-title-width-xlarge) !important;
}
.propertiesPanel .property.hasChanges .title {
  font-style: italic;
  color: var(--green);
}
.propertiesPanel .property .value {
  flex-basis: 0; /* var(--panel-value-width, 0); */
  flex-grow: 1;
  flex-shrink: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  /* text-transform: uppercase; */
  min-width: 0;
  /* overflow-x: hidden; */
  /* overflow-wrap: break-word; */
  /* word-wrap: break-word; */
  word-break: break-all;
}
.propertiesPanel.uppercaseValues .property .value {
  text-transform: uppercase;
}
.propertiesPanel .property .hint {
  flex-basis: var(--panel-hint-width, 50px);
  flex-shrink: 0;
  display: flex;
  align-items: center;
  padding-right: 10px;
}

.propertiesPanel .property .value :global(.ui.toggle.checkbox label:before) {
  background-color: rgba(255, 255, 255, .20);
}
.propertiesPanel .property .value :global(.ui.checkbox.disabled label) {
  cursor: not-allowed !important;
  opacity: 0.4;
}
.propertiesPanel .property .hint {
}

.propertiesPanel .property .content {
  width: 100%;
  padding: 5px 10px !important;
  line-height: 1.4em;
}

.propertiesPanel .property .message {
  padding: 5px;
  flex-grow: 1;
}
.propertiesPanel .property .message :global(.ui.message) {
  margin: 0 !important;
  white-space: break-spaces; /* enables word-wrap */
}

/**
 * ArkPanel.PropertyDivider
 */
.propertyDivider {
  border-bottom: 1px solid var(--bd-light);
  margin: 5px 0 5px 0;
}

/**
 * ArkPanel.PropertySection
 */
 .section {
 }
.section > .sectionTitle {
  padding: 5px 10px 0px 10px;
  font-weight: bold;
  user-select: none;
}
.section.sectionCollapsible > .sectionTitle {
  cursor: pointer;
}
.section.sectionCollapsible:not(.open) .sectionTitle {
  padding-bottom: 5px;
}
/* arrow ref: https://www.w3schools.com/howto/howto_css_arrows.asp */
.section:not(.sectionCollapsible) > .sectionTitle > .sectionArrow {
  display: none;
}
.section.sectionCollapsible > .sectionTitle > .sectionArrow {
  border: solid #fff;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
  margin-right: 10px;
}
.section.sectionCollapsible > .sectionTitle .sectionArrow.sectionArrowClosed {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}
.section.sectionCollapsible > .sectionTitle .sectionArrow.sectionArrowOpen {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  margin-bottom: 2px;
}
 .section.sectionCollapsible > .sectionContent {
  display: none;
  overflow: hidden;
}
.section.sectionCollapsible.open > .sectionContent {
  display: block;
}

/* sub-section (nested sections) - NB: only currently supports 1 level deep/down of nesting */
.section .section {
  padding-left: 20px;
}
.section .section .property .title {
  flex-basis: calc(var(--panel-title-width, 200px) - 20px); /* remove the sub-section padding */
}
