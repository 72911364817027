.background {
  background-color: rgba(0, 0, 0, 0.35);
  bottom: 0;
  left: 0;
  opacity: 0;
  pointer-events: none;
  position: fixed;
  right: 0;
  top: 0;
  transition: 0.5s;
  z-index: 3; /* above footer */
}

.background.show {
  opacity: 1;
  pointer-events: all;
}

.spacer {
  transition: 0.5s;
  width: var(--left-sidebar-width);
}

.spacer.compact {
  width: var(--left-sidebar-compact-width);
}

.spacer.hidden {
  width: 0;
}

.container {
  border-right: 1px solid var(--th-ui-actionable);
  bottom: var(--footer-height);
  left: 0;
  overflow-x: hidden;
  position: fixed;
  top: var(--header-height);
  transition: 0.5s;
  width: var(--left-sidebar-width);
  z-index: 3; /* above footer */
}

.container.compact {
  width: var(--left-sidebar-compact-width);
}

.container.hidden {
  width: 0;
}

.container.mobile {
  background-color: var(--bg-dark);
  box-shadow: 0 0 100px rgba(0, 0, 0, 0.65);
  bottom: 0;
  top: 0;
  width: 0;
  z-index: 5; /* above header */
}

.container.mobile.open {
  width: var(--left-sidebar-width);
}

.main {
  display: flex;
  flex-direction: column;
  height: 100%;
  min-width: var(--left-sidebar-compact-width);

}

.container.mobile .main {
  width: var(--left-sidebar-width);
}

.dropdowns {
  padding: 0 10px;
}

/**
 * controls
 */

.controlsContainer {
  bottom: 5px;
  display: flex;
  justify-content: flex-end;
  left: 0;
  position: fixed;
  transition: 0.5s;
  width: var(--left-sidebar-width);
  z-index: 3; /* above footer */
}

.container.compact .controlsContainer {
  width: var(--left-sidebar-compact-width);
}

.container.hidden .controlsContainer {
  width: 40px;
}

.controls {
  background-color: var(--bg-dark);
  border: 1px solid var(--th-ui-actionable);
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  border-top-color: transparent;
  display: flex;
  position: relative;
  transition: 0.5s;
}

/* hide tail */
.controls::after {
  background-color: var(--bg-dark);
  content: '';
  height: 7px;
  left: -1px;
  position: absolute;
  top: -1px;
  width: 1px;
}

.container.hidden .controls {
  border-top-color: var(--th-ui-actionable);
  border-top-right-radius: 5px;
}
