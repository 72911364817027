/**
 * button
 */

.button {
  align-items: center;
  background-color: var(--th-ui-actionable);
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 70px;
  justify-content: center;
  overflow-x: hidden;
  position: relative;
  transition: 0.5s;
  user-select: none;
}

.button:not(.disabled) {
  cursor: pointer;
}

.button:not(.disabled):hover {
  background-color: var(--th-ui-actionable-hover);
}

.button:not(.disabled):active {
  background-color: var(--blue);
}

.button.active {
  background-color: var(--blue) !important;
}

.button.disabled {
  opacity: 0.35;
}

.button.hidden {
  flex: 0;
  opacity: 0;
}

.iconContainer {
  position: relative;
}

.lock {
  position: absolute;
  right: -10px;
  top: -5px;
}

.title {
  font-size: 11px;
  text-transform: uppercase;
}

.chevron {
  opacity: 0;
  position: absolute;
  right: 0;
  top: 12.5px;
  transition: 0.5s;
}

.button.compact .chevron {
  opacity: 1;
}

/**
 * group
 */

.group {
  border-radius: 5px;
  display: flex;
  flex-shrink: 0;
  margin: 10px;
  overflow: hidden;
  transition: 0.5s;
}

.group.compact {
  border-radius: 0;
  margin: 10px 0;
}

.group.popup {
  margin: 0;
}
