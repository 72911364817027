.container {
  position: relative;
}

.input {
  background-color: var(--bg-dark);
  border: 1px solid var(--bd-mid);
  border-radius: 5px;
  width: 100%;
}

.input input {
  background-color: transparent !important;
  color: var(--tx-light) !important;
  color-scheme: dark;
  font-family: var(--th-font-main) !important;
}

.input input:read-only {
  color: var(--tx-muted) !important;
}

.inputClear input {
  padding-right: 40px !important;
}

.clearButton {
  position: absolute;
  right: 0;
  top: 0;
}
