.preview {
  /* width: 444px; */
  /* height: 250px; */
  /* aspect-ratio: 16/9; */ /* TESTING - TODO: compatibility? */
  border-radius: 4px;
  border: none;
  position: relative;
  /* background-color: var(--tx-light-mid); */
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.preview .screenBG {
  background-color: var(--tx-light-mid);
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.preview .playIcon {
  opacity: 0.2;
}
.preview .img {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 50px;
  height: 50px;
  /* border: 1px solid red; */
}
.preview .img img {
  /* width: 100%;
  height: 100%; */
}