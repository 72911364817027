.details {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}
.details .org {
  text-transform: capitalize;
  min-width: 140px;
}
.details .domain {
  min-width: 140px;
  /* border: 1px solid red; */
}
.details .ip {
  min-width: 120px;
  /* border: 1px solid red; */
}
.details .status {
  /* border: 1px solid red; */
}
