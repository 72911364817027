.modal {
}
.modal>:global(.content) {
  padding: 12px 20px 20px 20px !important;
}
.companylist {}
/* NB: using fixed blocks all scrolling, & avoids dealing with covering the scrolled area */
.loadingOverlay {
  position: fixed;
  top: 0px;
  bottom: 0px;
  left: 0px;
  height: auto;
  width: 100%;
  background-color: #000;
  opacity: 0.65;
}