.badge {
  align-items: center;
  background-color: var(--grey);
  border-radius: 4px;
  display: flex;
  flex-shrink: 0;
  height: 20px;
  justify-content: center;
  min-width: 20px;
  padding: 0 5px;
}

.text {
  font-family: var(--th-font-small);
  font-size: 10px;
  font-weight: 600;
  line-height: 1;
}
