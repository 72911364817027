
.guestName {
  font-size: 1.1em;
}
.guestName .title {
  font-weight: bold;
  padding-right: 10px;
}

.channelsFieldset {
  padding: 0 0 0 0;
  margin: 0;
}
.channelsDivider {
  border: none;
  border-top: 1px solid var(--bd-light);
}
.channelHeader {
  margin: 0 0 5px 0 !important;
}
.channelHeader .channelTitle {
  font-weight: bold;
  margin-bottom: 5px;
  text-transform: capitalize;
}
.channelHeader .channelDesc p {
  margin: 0 0 2px 0;
  font-size: 0.9em;
}
.channelHeader .noChannelsWarning {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.channelHeader .noChannelsWarning .warningIcon {
  margin-right: 10px;
  font-size: 1.5em;
}
.channelHeader .noChannelsWarning .warningMsg {
  
}
.channelOption {}
.channelAvatar {
  margin-right: 10px;
}

/* .link h3 {} */
.linkTitle {
  font-weight: bold;
  text-transform: capitalize;
  margin-bottom: 5px;
}
.linkWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  width: 100%;
}
.linkPreview {
  overflow: hidden; /* don't overflow beyond the parent flex-enabled width */
}
.linkPreview pre {
  white-space: pre-wrap;
  word-wrap: break-word;
  border-radius: 6px;
  background-color: var(--th-ui-actionable);
  padding: 10px;
  margin: 0;
}

.linkDbg pre {
  white-space: pre-wrap;
  word-wrap: break-word;
}
