.companyDropdown {
  align-items: center;
  display: flex;
  z-index: 3010 !important; /* above project dropdown */
}

/* project dropdown - items */
.companyDropdown :global(.item) {
  display: flex !important;
  justify-content: space-between;
  /* border: 1px solid red !important; */
}
.companyDropdown :global(.item .description) {
  /* color: red !important; */
  float: none !important;
  order: 1;
}

/* project dropdown - trigger (shows the current selection when the dropdown is closed) */
/* NB: tries to miimic what the project item looks like in the dropdown list/menu when its open */
.dropdownTrigger {
  font-size: 1rem;
  line-height: 1em;
}
.dropdownTrigger :global(.text) {
  font-size: 16px;
}
.dropdownTrigger :global(.description) {
  border: 0px solid red;
}