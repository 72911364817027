.popup {
  border-radius: 5px !important;
  padding: 10px !important;
  transition: opacity 0.3s;
}

.videoContainer {
  background-color: black;
  border: 1px solid var(--bd-mid);
  height: 110px;
  position: relative;
  width: 194px;
}

.noFragmentIcon {
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}

.video {
  display: block;
  height: 108px;
  opacity: 0;
  width: 192px;
}

.canvas {
  position: absolute;
  top: 0;
}

.time {
  align-items: center;
  font-family: var(--th-font-mono);
  font-size: 13px;
  line-height: 1;
  text-align: center;
}

.seekTime {
  color: var(--tx-muted);
  font-family: var(--th-font-mono);
  font-size: 10px;
  font-weight: 600;
  line-height: 1;
  margin-top: 2.5px;
  text-align: center;
}
