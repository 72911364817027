.background {
  background-color: rgba(0, 0, 0, 0.35);
  bottom: 0;
  left: 0;
  opacity: 0;
  pointer-events: none;
  position: fixed;
  right: 0;
  top: 0;
  transition: 0.5s;
  z-index: 3; /* above footer */
}

.spacer {
  transition: 0.5s;
  width: 0;
}

.spacer.open {
  width: var(--right-sidebar-width);
}

.background.show {
  opacity: 1;
  pointer-events: all;
}

.container {
  border-left: 1px solid var(--th-ui-actionable);
  bottom: var(--footer-height);
  /* overflow-x: hidden; */
  position: fixed;
  right: 0;
  top: var(--header-height);
  transition: 0.5s;
  width: 0;
  z-index: 7; /* above menu button */
  /* border: 1px solid red; */
}

.container.mobile {
  background-color: var(--bg-dark);
  box-shadow: 0 0 100px rgba(0, 0, 0, 0.65);
  bottom: 0;
  top: 0;
}

.container.open {
  width: var(--right-sidebar-width);
}

.container.mobile.open {
  width: min(var(--right-sidebar-width), 90vw);
}

.content {
  overflow-x: hidden;
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  /* border: 1px solid blue; */
}

.main {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  padding: 10px 11px 10px 10px;
  position: relative;
  width: var(--right-sidebar-width);
}

.container.mobile .main {
  width: min(var(--right-sidebar-width), 90vw);
}

/* .controlsContainer {
  top: calc(var(--header-height) + 5px);
  right: calc(var(--right-sidebar-width) - 1px);
  display: flex;
  justify-content: flex-end;
  position: fixed;
  transition: 0.5s;
  width: 32px;
  z-index: 3000; 
  above footer
  border: 1px solid red;
} */
.controlsContainer {
  position: absolute;
  top: 6px;
  left: -32px;
  z-index: 3000;
  transition: opacity 0.5s;
  opacity: 1;
  /* border: 1px solid red; */
}
.controls {
  background-color: var(--bg-dark);
  border: 1px solid var(--th-ui-actionable);
  border-bottom-left-radius: 5px;
  border-top-left-radius: 5px;
  border-right-color: transparent;
  display: flex;
  position: relative;
  transition: 0.5s;
}

.closeButton {
  width: 32px;
  padding-left: 4px;
  /* border: 1px solid red; */
}

.container:not(.open) .controlsContainer {
  opacity: 0;
}

/* .container.open .controlsContainer {
  animation: controlsFadeIn 0.5s;
}
.container:not(.open) .controlsContainer {
  animation: controlsFadeOut 0.5s;
}
@keyframes controlsFadeIn {
  from { opacity: 0 }
  to { opacity: 1 }
}
@keyframes controlsFadeOut {
  from { opacity: 1 }
  to { opacity: 0 }
} */
