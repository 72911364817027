:global(input#shortName) {
  text-transform: uppercase!important;
}

.programPassSaveFirst {
  font-style: italic;
  opacity: 0.8;
}

/* program popup hint styling */
.programHint {
  /* max-width: 300px !important; */
}
.programHint p {
  margin-bottom: 8px;
}
.programHint p:last-child {
  margin-bottom: 0px;
}
.programHint :global(.header) {
  margin-bottom: 8px;
}
.programHint :global(.header+.content) {
  padding-top: 0px !important;
}

.programForm .srtOutputOptions :global(.field.disabled .checkbox.disabled input:checked~label:after) {
  opacity: 0; /* hide checkbox ticks when its disabled (currently only applies to the `SRT Pass` checkbox if SRT hotlinking enabled  */
}

.detailsFieldset {
  margin-bottom: 0px;
}

.flagsFieldset {
  padding: 18px 15px 5px 15px; /* reduce the left & right padding to make more room for warning messages */
  margin-bottom: 0px;
}
.flagsFieldset :global(.field .field) {
  padding: 0 0 10px 20px;
}
.flagsFieldset :not(.flagWrapper) + .flagWrapper { /* first flag (wrapper) element only - ref: https://stackoverflow.com/a/13287975 */
  padding-top: 8px !important;
}
.programForm .flagLabel .flagTitle {
  min-width: 80px;
}
.programForm .flagLabel .flagTitle span {
  color: #fff; /* override the default toggle label colour change when its enabled (we just force it regardless of its on/off state) */
}
.flagsFieldset .flagWrapper {
  /* border: 1px solid red; */
}
.flagsFieldset .flagHint {
  /* border: 1px solid blue; */
  align-self: flex-start !important;
}

.flagsFieldset .flagsWarningField {
  padding: 0 0 5px 0 !important;
}
.flagsFieldset .flagsWarningField .flagsWarningNotice {
  display: flex;
  flex-direction: row;
  align-items: center;
  /* padding: 0px 10px 10px 10px !important; */
}
.flagsFieldset .flagsWarningField .flagsWarningNotice .warningIcon {
  margin-right: 10px;
}

.programForm .hotlinkFieldset {
  padding: 18px 20px 10px 20px;
}
.programForm .hotlinkTitle {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.programForm .hotlinkTitle .hotlinkTitleIcon {
  padding-right: 5px;
}
.programForm .hotlinkTitle span {
  display: block;
}
.programForm .hotlinkProtocolLabel .hotlinkProtocolTitle {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 0px;
}
.programForm .hotlinkProtocolLabel .hotlinkProtocolTitle .hotlinkProtocolTitleIcon {
  padding-right: 5px;
}
.programForm .hotlinkProtocolLabel .hotlinkProtocolTitle span {
  color: #fff; /* override the default toggle label colour change when its enabled (we just force it regardless of its on/off state) */
}
.programForm .hotlinkProtocolsSRTNotice {
  color: var(--orange);
}

.programForm .srtInputOptions .srtInputPassphraseEnable {
  margin: 10px 0 8px 0 !important;
}
.programForm .srtInputOptions .srtLatency,
.programForm .srtInputOptions .srtInputMaxBandwidth {
  margin-top: 10px !important;
}
.programForm .srtInputOptions .srtLatency :global(.ui.fluid.input > input),
.programForm .srtInputOptions .srtInputMaxBandwidth :global(.ui.fluid.input > input) {
  width: 170px !important;
  flex: none;
}

.programForm .srtOutputOptions .srtOutputPassphraseEnable {
  margin: 10px 0 8px 0 !important;
}
.programForm .srtOutputOptions .srtOutputWarningField {
  padding: 0 !important;
}
.programForm .srtOutputOptions .srtOutputWarningField .srtOutputWarningNotice {
  display: flex;
  flex-direction: row;
  align-items: center;
  /* padding: 0px 10px 10px 10px !important; */
}
.programForm .srtOutputOptions .srtOutputWarningField .srtOutputWarningNotice .warningIcon {
  margin-right: 10px;
}
.programForm .srtOutputOptions .srtLatency,
.programForm .srtOutputOptions .srtOutputMaxBandwidth {
  margin-top: 10px !important;
}
.programForm .srtOutputOptions .srtLatency :global(.ui.fluid.input > input),
.programForm .srtOutputOptions .srtOutputMaxBandwidth :global(.ui.fluid.input > input) {
  width: 170px !important;
  flex: none;
}

.programForm .srtPorts .srtCustomPortsEnabled {
  margin: 10px 0 8px 0 !important;
}
.programForm .srtPorts .srtInputPort {
  margin-bottom: 10px !important;
}
.programForm .srtPorts .srtInputPortsHidden {
  display: none;
}
.programForm .srtPorts .srtCustomPortsDisabledNotice {
  font-style: italic;
}

.hasChanged label,
.hasChanged label .flagTitle span,
.hasChanged label .hotlinkProtocolTitle span {
  color: var(--green) !important; /* --orange */
  font-style: italic;
}
.hasChanged :global(.field .fieldsetTitle) {
  color: var(--green) !important; /* --orange */
  font-style: italic;
}

/**
 * SRT Ports (nested) modal form:
 * - force vertical center alignment on the nested modal (as it doesn't do it by default when nested within another SUIR modal content region it seems)
 * - NB: uses the `:has()` pseudo-class selector to target the parent modal container that contains the specific nested modal we want to alter (as we can't set a css class on it directly)
 */
:global(div.ui.modals):has(> .srtPortModal) {
  justify-content: center !important;
}

/**
 * SRT Ports (nested) modal form:
 */
 .programPortEdit h1 {
  text-transform: capitalize;
 }
 .programPortForm .portGroup {
  /* border: 1px solid red; */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-end;
  margin-bottom: 14px !important;
  margin-left: 15px !important;
 }
.programPortForm .portGroup .srtPort {
  /* flex-basis: auto !important; */
  /* flex-grow: 2 !important; */
}
.programPortForm .portGroup .srtPort :global(.input) {
  width: 150px !important;
}
.programPortForm .portGroup .srtPort input {
  font-size: 1.5em !important;
  padding: 8px 10px !important;
}
.programPortForm .portGroup .checkPortBtn {
  /* flex-grow: 1 !important; */
  margin-bottom: 3px !important;
}

.availablePorts {
  margin: 0 0 14px 6px;
  padding: 5px 10px;
  border-radius: 5px;
  background: var(--bd-light);
}
.availablePorts h3 {
  font-size: 1.1em;
  font-weight: bold;
  margin-bottom: 2px;
}
.availablePorts .loadingPorts {
  margin: 10px 0 0 0;
}
.availablePorts .portRanges {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 2px 8px;
  font-size: 1.2em;
}
.availablePorts .portRanges .portRange {
  white-space: nowrap;
}

.checkPortResult {
  margin: 0 4px 15px 4px;
}
.checkPortResult h3 {
  margin-bottom: 5px;
}
.checkPortResult .checkResult {
  font-size: 1.2em;
}
.checkPortResult .checkResult .checkResultAvailable,
.checkPortResult .checkResult .checkResultInput,
.checkPortResult .checkResult .checkResultOutput,
.checkPortResult .checkResult .checkResultInvalid {
  border: 1px solid #fff;
  border-radius: 5px;
  padding: 5px 10px;
}
.checkPortResult .checkResult .checkResultAvailable span,
.checkPortResult .checkResult .checkResultInput span,
.checkPortResult .checkResult .checkResultOutput span,
.checkPortResult .checkResult .checkResultInvalid span {
  display: block;
  font-style: italic;
}
.checkPortResult .checkResult .checkResultAvailable {
  border-color: var(--green);
  background-color: var(--green);
}
.checkPortResult .checkResult .checkResultInput,
.checkPortResult .checkResult .checkResultOutput {
  border-color: var(--orange);
  background-color: var(--orange);
}
.checkPortResult .checkResult .checkResultInvalid {
  border-color: var(--red);
  background-color: var(--red);
}

/**
 * dvr
 */

.dvrDisabledText {
  color: var(--tx-muted);
  font-weight: bold;
}

.dvrDisabledTextDanger {
  color: var(--th-danger);
}
