.container {
  background-color: var(--bg-black);
  border-bottom: 1px solid var(--th-ui-actionable);
  grid-area: header;
}

.content {
  display: flex;
  height: var(--header-height);
  padding: 0px 10px;
}

/**
 * left side
 */

.left {
  flex: 1;
  align-items: center;
  display: flex;
  border: 0px solid green;
}

.dropdowns {
  align-items: center;
  display: flex;
}

.dropdown {
  width: 230px !important;
}

.logo {
  display: block;
  height: 30px;
  border: 0px solid green;
}

.companySettingsButton {
  align-items: center;
  cursor: pointer; 
  display: flex;
  font-size: 22px;
  height: 40px;
  justify-content: center;
  transition: 0.5s;
  width: 40px;
}

.companySettingsButton:hover {
  color: var(--ui-hover);
}

.companySettingsButton:active {
  color: var(--ui-selected);
  transition: 0.1s;
}

.companyDropdown,
.projectDropdown {
  font-size: 16px;
  width: 240px !important;
}


/**
 * right side
 */
 .center {
  align-items: center;
  display: flex;
  flex: 1;
  justify-content: center;
  border: 0px solid blue;
 }

.right {
  justify-content: flex-end;
  flex: 1;
  align-items: center;
  display: flex;
  border: 0px solid red;
}

.switchButton {
  height: 40px;
  min-width: 200px;
  margin-left: 10px!important;
}

/**
 * mobile menu
 */

.mobileMenuButton {
  left: 10px;
  position: fixed;
  top: 10px;
  z-index: 4000 !important; /* above popup */
}

.mobileMenuPopup {
  max-height: calc(100vh - 80px);
  padding: 0 !important;
}

.mobileMenuPopupScroll {
  height: 100%;
  overflow-y: auto;
}

.mobileMenuPopupDropdowns {
  padding: 0 10px;
}
