.controls {
  bottom: 0;
  display: flex;
  flex-direction: column;
  left: 0;
  overflow: hidden;
  pointer-events: none; /* for three sixty */
  position: absolute;
  right: 0;
  top: 0;
  user-select: none;
  /* background-color: rgba(255, 0, 0, 0.25); */
}

/**
 * top
 */

.topBar {
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  height: 40px;
  opacity: 0;
  overflow-x: scroll;
  pointer-events: all;
  transition: 1s;
}

.show .topBar {
  opacity: 1;
  transition: 0.3s;
}

.topBar::-webkit-scrollbar {
  display: none;
}

.programName {
  font-size: 15px;
  overflow: hidden;
  text-overflow: ellipsis;
  text-wrap: nowrap;
}

/**
 * middle
 */

.middle {
  align-items: flex-end;
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow: hidden;
  /* background-color: rgba(255, 0, 0, 0.25); */
}

.infoPanel {
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  margin: 5px;
  opacity: 0;
  overflow-y: scroll;
  padding: 15px;
  pointer-events: all;
  transition: 1s;
  width: 250px;
}

.show .infoPanel {
  opacity: 1;
  transition: 0.3s;
}

.infoTitle {
  color: var(--tx-muted);
  font-size: 12px;
  text-align: center;
}

.infoRow {
  display: flex;
  font-size: 13px;
  line-height: 1.75;
}

.infoName {
  flex: 1;
}

.infoValue {
  flex: 1;
  font-family: var(--th-font-mono);
}

/* TODO got to here */

/**
 * bottom
 */

.bottomBar {
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  pointer-events: all;
  transition: 1s;
}

.show .bottomBar {
  opacity: 1;
  transition: 0.3s;
}

.timelineContainer {
  align-items: center;
  display: flex;
  height: 45px;
  padding: 5px 5px 0 5px;
  /* background-color: rgba(255, 0, 0, 0.25); */
}

.bottomMain {
  display: flex;
  height: 40px;
  overflow-x: scroll;
  /* background-color: rgba(255, 0, 0, 0.25); */
}

.bottomMain::-webkit-scrollbar {
  display: none;
}

.bottomLeft {
  align-items: center;
  display: flex;
  flex: 1;
  /* background-color: rgba(255, 0, 0, 0.25); */
}

.timeContainer {
  padding: 0 5px;
  /* background-color: rgba(255, 0, 0, 0.25); */
}

.time {
  font-family: var(--th-font-mono);
  font-size: 13px;
  line-height: 1;
  text-align: center;
}

.seekTime {
  color: var(--tx-muted);
  font-family: var(--th-font-mono);
  font-size: 10px;
  font-weight: 600;
  line-height: 1;
  text-align: center;
  margin-top: 2.5px;
}

.buttonLabel {
  font-family: var(--th-font-small);
  font-size: 10px;
  font-weight: 600;
  left: 50%;
  position: absolute;
  text-align: center;
  top: 50%;
  transform: translate(-50%, -50%);
  white-space: nowrap;
  /* background-color: rgba(255, 0, 0, 0.25); */
}

.bottomMiddle {
  align-items: center;
  display: flex;
  /* background-color: rgba(255, 0, 0, 0.25); */
}

.bottomRight {
  align-items: center;
  display: flex;
  flex: 1;
  /* background-color: rgba(255, 0, 0, 0.25); */
}

/**
 * utilities
 */

.blur {
  backdrop-filter: blur(10px);
}
