.inactiveOrg {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.message {
  color: var(--tx-light);
}
.message a {
  font-weight: bold;
}
.message .email {
  font-weight: bold;
}