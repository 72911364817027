.avatarWrapper {
  position: relative;
}
/** 
 * NB: for some reason we react-avatar renders 2 nested containers with the same classname (including the one we specify)
 *     so you may need to target the inner one when adding certain styles e.g: `.avatar .avatar {}`
 *     or use `nth-child(1)` to target the outer offset one using `.avatar:nth-child(1) {}`
 */
.avatar:nth-child(1) {}
.avatar .avatar {
  user-select: none;
}
.avatar .avatar div {
  border-radius: 100%;
}

.avatar .avatar.userAvatar {
  font-family: var(--th-font-main);
  font-weight: 500;
  text-transform: uppercase;
}
.avatar .avatar.userAvatar div {
  border: 1px solid #fff;
}

.avatar .avatar.userGuestAvatar {
  border: 1px solid #fff;
}

.avatar .avatar.userAvatar,
.avatar .avatar.userProjectManagerAvatar,
.avatar .avatar.userProjectAdminAvatar,
.avatar .avatar.userCompanyAdminAvatar,
.avatar .avatar.userSiteAdminAvatar {
  border: 1px solid #fff;
}
.avatar .avatar.userAvatar div,
.avatar .avatar.userProjectManagerAvatar div,
.avatar .avatar.userProjectAdminAvatar div,
.avatar .avatar.userCompanyAdminAvatar div,
.avatar .avatar.userSiteAdminAvatar div {
  border: 2px solid var(--bg-dark-card);
}

.avatarWrapper .avatarText {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Helvetica, Arial, sans-serif; /* match the Avatar font */
}

.avatarWrapper .icon {
  position: absolute;
}
.avatarWrapper .icon.iconTR {
  right: 0;
  top: 0;
  transform: translate(35%, -35%);
}
.avatarWrapper .icon.iconTL {
  left: 0;
  top: 0;
  transform: translate(-35%, -35%);
}

.onlineStatusIndicator {
  bottom: 0;
  position: absolute;
  right: 0;
  transform: translate(calc(100% / 3), calc(100% / 3));
}
