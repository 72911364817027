.container {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.containerHorizontal {
  flex-direction: row;
}

.containerInline {
  display: inline;
}

.dots {
  display: inline-flex;
}

@keyframes dotAnimation1 {
  0% { opacity: 0.4}
  50% { opacity: 0.8; transform: scale(1.5) translateX(40%) }
  100% { opacity: 0.4}
}

@keyframes dotAnimation2 {
  0% { opacity: 0.4}
  50% { opacity: 0.8; transform: scale(1.5); }
  100% { opacity: 0.4}
}

@keyframes dotAnimation3 {
  0% { opacity: 0.4}
  50% { opacity: 0.8; transform: scale(1.5) translateX(-40%)}
  100% { opacity: 0.4}
}

.dot { 
  border-radius: 100px;
  height: 15px;
  width: 15px;
  mix-blend-mode: screen;
}

.one {
  animation: dotAnimation1 2s infinite;
  opacity: 0.4;
}

.two {
  animation: dotAnimation2 2s infinite;
  opacity: 0.4;
}

.three {
  animation: dotAnimation3 2s infinite;
  opacity: 0.4;
}

/* .one {} */
.one {
  background-color: var(--red);
}

.two {
  background-color: var(--green);
  animation-delay: 0.25s;
}

.three {
  background-color: var(--th-feature1);
  animation-delay: 0.5s;
}

.small > * {
  height: 10px;
  margin: 5px 2px 5px 2px; /* NB: was just 5px for all, but dots didn't overlap properly */
  width: 10px;
}

.message {
  color: var(--tx-light-mid);
  text-align: center;
  text-transform: uppercase;
  cursor: default;
}
