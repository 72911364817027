.container {
  aspect-ratio: 16 / 9;
  position: relative;
  user-select: none;
  border: 0px solid red;
}

.content {
  height: 1080px;
  left: 50%;
  position: absolute;
  top: 50%;
  width: 1920px;
  border: 0px solid green;
}

.body {
  animation: obfuscationAnimation 20s infinite;
  height: 100%;
  opacity: 0.35;
  position: relative;
  width: 100%;
  border: 0px solid blue;
}

.upperThird {
  font-family: var(--th-font-watermark);
  font-size: 100px;
  font-weight: 500;
  left: 0;
  line-height: 1.2;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  text-align: center;
  text-overflow: ellipsis;
  text-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
  top: 15%;
  transform: translateY(-50%);
  width: 1920px;
  /* background-color: rgba(255, 0, 0, 0.25); */
}

.lowerThird {
  font-family: var(--th-font-watermark);
  font-size: 100px;
  font-weight: 500;
  left: 0;
  line-height: 1.2;
  overflow: hidden;
  position: absolute;
  text-align: center;
  text-overflow: ellipsis;
  text-shadow: 0 8px 16px rgba(0, 0, 0, 0.5);
  top: 85%;
  transform: translateY(-50%);
  white-space: nowrap;
  width: 1920px;
  /* background-color: rgba(255, 0, 0, 0.25); */
}

@keyframes obfuscationAnimation {
  0% { transform: translateX(-1px, -1px) }
  50% { transform: translate(1px, 1px) }
  100% { transform: translateX(-1px, -1px) }
}

/* DEBUG exagerate obfuscation animation */
/* @keyframes obfuscationAnimation {
  0% { transform: translateX(-50px, -50px) }
  50% { transform: translate(50px, 50px) }
  100% { transform: translateX(-50px, -50px) }
} */
