.main {
  align-items: center;
  display: flex;
  flex: 1;
  flex-shrink: 0;
  height: 40px;
  position: relative;
  z-index: 1;
  /* background-color: rgba(255, 0, 0, 0.25); */
}

.disabled {
  opacity: 0.35;
}

.track {
  background-color: var(--th-ui-actionable);
  border-radius: 2px;
  display: flex;
  flex: 1;
  height: 10px;
  overflow: hidden;
}

.bar {
  background-color: var(--th-feature1);
}

/**
 * markers
 */

.markers {
  bottom: 10px;
  display: flex;
  justify-content: space-between;
  left: 5px;
  position: absolute;
  right: 5px;
  top: 10px;
  /* background-color: rgba(255, 0, 0, 0.25); */
}

.marker {
  background-color: var(--bd-light);
  position: relative;
  width: 1px;
}

.markerLabel {
  color: var(--tx-muted);
  font-family: var(--th-font-small);
  font-size: 8px;
  font-weight: 600;
  text-align: center;
  line-height: 1;
  position: absolute;
  transform: translate(-50%, -100%);
  white-space: nowrap;
  /* background-color: rgba(255, 0, 0, 0.25); */
}

/**
 * input
 */

.input {
  appearance: none;
  background-color: transparent;
  bottom: 0;
  cursor: pointer;
  left: 0;
  outline: none;
  position: absolute;
  right: 0;
  top: 0; 
}

.disabled .input {
  cursor: auto;
}

.input::-moz-range-thumb {
  background-color: var(--tx-light);
  border: none;
  border-radius: 2px;
  height: 20px;
  width: 10px;
}

.input:not(.inputDisabled)::-moz-range-thumb:hover {
  background-color: var(--ui-hover);
}

.input::-webkit-slider-thumb {
  appearance: none;
  background-color: var(--tx-light);
  border-radius: 2px;
  height: 20px;
  transition: 0.3s;
  width: 10px;
}

.container:not(.disabled) .input::-webkit-slider-thumb:hover {
  background-color: var(--ui-hover);
}

.container:not(.disabled) .input::-webkit-slider-thumb:active {
  background-color: var(--ui-selected);
  transition: 0.1s;
}
