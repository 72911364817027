.authSessions {
  position: relative;
}

.loading {
  padding: 40px 0;
}

button.refresh {
  position: absolute;
  top: 0px;
  right: 0px;
}
button.refresh :global(.icon) {
  width: auto !important;
  height: auto !important;
  font-size: 1.2em !important;
  padding: 1px 0px;
}

.list {
  padding: 0px 0 !important;
}
.list :global(.item) {
  border-top: none !important;
  border-bottom: 1px solid rgba(255,255,255,.1) !important;
  padding-top: 12px !important;
  padding-bottom: 8px !important;
}
.list :global(.item:first-child) {
  border-top: 1px solid rgba(255,255,255,.1) !important;
}
.list :global(.item:not(:first-child)) {
}

.list button.edit {
  margin-left: 8px !important;
  padding: 6px !important;
}

.logoutAll {
  display: flex;
  justify-content: flex-end;
}