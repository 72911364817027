/* :global(.ui.dimmer) {
  background-color: transparent !important;
} */

.modal {
  border: 1px solid var(--bd-mid)!important;
  background-color: var(--bg-dark-mid) !important;
  position: relative;
  /* transition: width 2s, height 4s !important; */
  transition: all 0.5s ease !important;
}

.modalAnimating {
  overflow: hidden !important; /* mask content while animating */
}

.modal:global(.transition) {
  /* border: 1px solid red !important; */
  margin: 20px !important;
}
.header {
  padding: 1.25rem 1.5rem 1.25rem 1.5rem !important;
  color: var(--tx-light) !important;
  background-color: var(--bg-dark-card) !important;
  border-bottom: 1px solid var(--bd-light) !important;
}

:global(.ui.header.inverted)  {
  background-color: transparent !important;
  text-transform: capitalize !important;
}

.contentOuter {
  overflow: hidden;
}
.content {
  background-color: transparent!important;
  /* border: 1px solid red !important; */
  /* overflow: visible !important; */
  position: relative;
  transition: all 0.5s ease !important; /* linear */
}

.noPadding {
  padding: 0 !important;
}


.closeIcon:global(.bordered.icon) {
  box-shadow: 0 0 0 0.1em var(--th-ui-actionable) inset !important;
  background-color: var(--th-ui-actionable) !important;
  color: var(--tx-light) !important;
  margin: 3px 3px!important;
}
:global(.ui.modal>.close:hover) {
  background-color: var(--th-ui-actionable-hover) !important;
  color: var(--ui-hover) !important;
  opacity: 0.8 !important;
}

.closeButton {
  position: absolute;
  top: 5px;
  right: 5px;
  z-index: 1000;
  /* background-color: rgba(255, 0, 0, 0.25); */
}

/* 
TESTING: nudge the close icon partially outside the modal, so it overlaps the corner
NB: needs the outer modal to not have `overflow: hidden` set, but that causes content to show outside the modal when it animates its height
TODO: if we want to go with something like this, can the close icon be moved outside/above the modal? 
TODOL ..if not, we'll need to add a wrapper around the content & mask using that, but that will also need its height setting in the JS updates
:global(.ui.modal>.close) {
  top: -1em !important;
  right: -1em !important;
}
*/


/* LEGACY/OLD STYLING? */
/** 
 * modal dimmer (semi-transparent background around/behind the modal window)
 * NB: we set the default modal dimmer to transparent with an ease transition, 
 *     & then only set the colour when a certain class is added (to the body element currently)
 */
/* :global(.ui.dimmer) {
  background-color: transparent !important;
  transition: background-color 0.5s ease !important;
}
:global(.modal-fade-in .ui.dimmer) {
  background-color: rgba(0,0,0,.85) !important;
} */

/**
 * fixed height testing/debugging
 */
/* .modal {
  height: 400px;
}
.header {
  height: 50px;
}
:global(.ui.modal .scrolling.content) {
  height: 350px;
} */
