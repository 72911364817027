.managerList {
  width: 100%;
  height: 100%;
}

.managerList.withTopbar {
  display: flex;
  flex-direction: column;
  border: 0px solid red;
}

.listTopbar {
  padding-left: 10px;
  margin-top: 6px;
  border: 0px solid red;
}

.filterHeader {
  font-weight: bold;
  font-size: 1.1em;
  padding: 10px;
  margin-bottom: 10px;
  background-color: var(--blue);
}
.filterClear {
  cursor: pointer;
}
.filterClear:hover {
  text-decoration: underline;
}

.section {
  margin-bottom: 10px;
}
.section .sectionHeader {
  padding: 5px;
  border-radius: 5px;
  margin-bottom: 10px;
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
}
.section .sectionHeader .sectionTitle {
  font-size: 1.2em;
  font-weight: bold;
}
/* .section .ssectionItems {} */
.section.sectionCollapsible {
}
.section.sectionCollapsible .sectionTitle {
  cursor: pointer;
  user-select: none; /* disable text selection so it doesn't accidentally select as you click to collapse/open */
}
.section.sectionCollapsible.sectionClosed .sectionItems {
  display: none;
}
.section.sectionCollapsible.sectionClosed .sectionTitle i {
  transform-origin: 50% 60%;
  transform: rotate(-90deg);
}
.section .sectionHeader .sectionDesc {
  opacity: 0.6;
}

.errors {
  margin-bottom: 10px;
}
