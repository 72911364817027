.container {
  position: relative;
  z-index: 100; /* above lines */
}

.iconBackground {
  background-color: var(--bg-dark-card);
  border-radius: 50%;
  height: 30px;
  left: 20px;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 30px;
}

.button {
  align-items: center;
  cursor: default;
  display: flex;
  padding: 10px 0;
  z-index: 200;
}

/* .checked {} */

.disabled {
  opacity: 0.25;
}

.nested {
  padding-left: 30px;
}

.button:not(.checked):not(.disabled):not(.readOnly) {
  cursor: pointer;
  transition: 0.5s;
}

.button:not(.checked):not(.disabled):not(.readOnly):hover {
  opacity: 0.65;
}

.button:not(.checked):not(.disabled):not(.readOnly):active {
  opacity: 0.825;
  transition: 0.1s;
}

.iconContainer {
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  width: 40px;
}

.icon {
  z-index: 1;
}

.title {
  font-size: 16px;
  line-height: 1.5;
}

.titleChecked {
  text-decoration: line-through;
}

.description {
  font-size: 12px;
  line-height: 1.25;
  opacity: 0.65;
}

.status {
  font-size: 12px;
  line-height: 1.25;
  opacity: 0.65;
}

.arrow {
  flex-shrink: 0;
  margin-left: 10px;
}
