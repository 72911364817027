.sidebarHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.sidebarTitle {
  flex-grow: 1;
  padding: 10px 0px 10px 10px !important;
  margin: 0 !important;
}

.keys {
  padding: 5px 20px 0px 20px;
}
.keys .key:not(.keyMultiVal) {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 10px;
}
.keys .key.keyMultiVal {
  /* border: 1px solid red; */
}
.keys .key .keyTitle {
  font-weight: bold;
}
.keys .key .keyValue {}
.keys .key .keySubValues {
  padding: 0px 10px 0px 10px;
}
.keys .key .keySubValues .keySub {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 10px;
}
.keys .key .keySubValues .keySubTitle {
  font-weight: bold;
}

.configs {
  padding: 5px 20px 0px 20px;
}
.configs .config:not(.configMultiVal) {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 10px;
}
.configs .config.configMultiVal {
  /* border: 1px solid red; */
}
.configs .config .configTitle {
  font-weight: bold;
}
.configs .config .configValue {}
.configs .config .configSubValues {
  padding: 0px 10px 0px 10px;
}
.configs .config .configSubValues .configSub {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 10px;
}
.configs .config .configSubValues .configSubTitle {
  font-weight: bold;
}

.packagesSection {
  padding: 5px 20px 0px 20px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 10px;
}
.packagesSection .packageTitle {
  /* font-weight: bold; */
}
/* .packagesSection .packageVersion {} */

.driversSection {
  padding: 5px 20px 10px 20px;
}
.driversSection .driversSubSection {
  padding: 5px 10px 0px 10px;
}
.driversSection .driversTitle,
.driversSection .driversSubSection .driversSubTitle {
  font-weight: bold;
}
.driversSection .driversSubSection .driversDetails {
  padding: 0px 5px 0px 5px;
}
.driversSection .driversSubSection .driversDetails .driversDetailTitle {
  font-weight: bold;
}

.scriptsSection {
  padding: 5px 20px 0px 20px;
}

.cronSection {
  padding: 5px 20px 0px 20px;
}
.cronSection .cronTitle {
  font-weight: bold;
}
.cronSection .cronDetails {
  padding: 5px 10px 0px 10px;
}
.cronSection .cronDetails .cronDetailTitle {
  font-weight: bold;
}

.authSection {
  padding: 5px 20px 0px 20px;
}
.authSection .authApiIpTitle {
  font-weight: bold;
  text-transform: capitalize;
}