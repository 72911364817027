.dropdown {
  background-color: var(--th-ui-actionable) !important;
  border: 0 !important;
  color: var(--tx-light) !important;
  flex-shrink: 0;
}

.dropdown :global(.menu) {
  background-color: var(--bg-dark-mid) !important;
  border: 0 !important;
}

.dropdown :global(.item) {
  border-color: var(--th-ui-actionable) !important;
}

.dropdown :global(.item.selected .text)  {
  color: var(--ui-selected) !important;    
}

.dropdown :global(.item:hover:not(.disabled) .text)  {
  color: var(--ui-hover) !important;
}

.dropdown :global(.text:not(.default))  {
  color: var(--bg-light) !important;
}

.dropdown :global(.description) {
  color: var(--ui-hover) !important;
}