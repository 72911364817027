/**
 * Picker Field
 */
.field {
  position: relative;
  /* z-index: 1; */
}

.btn {
  width: 38px;
  height: 38px;
  border-radius: 5px;
  border: 3px solid var(--bg-dark);
}
.btn:hover {
  cursor: pointer;
  border: 3px solid var(--th-ui-actionable);
}

/**
 * Picker Popup/Popover
 */
 .pickerOverlay {
   position: fixed;
   top: 0px;
   left: 0px;
   right: 0px;
   bottom: 0px;
   /*background-color: rgba(0,0,0,0.25); */
   z-index: 10;
 }
.picker {
  position: absolute;
  width: 580px;
  left: 60px;
  top: -10px;
  padding: 0px 2px 16px 0;
  border-radius: 4px;
  background-color: var(--th-ui-actionable);
  /* dropshadow - ref: https://html-css-js.com/css/generator/box-shadow/ */
  -webkit-box-shadow: 5px 6px 10px -3px rgba(0,0,0,0.85); 
  box-shadow: 5px 6px 10px -3px rgba(0,0,0,0.85);
  z-index: 100;
}

/* custom arrow indicator - ref: https://csslayout.io/patterns/popover-arrow/ */
.arrow {
  height: 16px;
  width: 16px;
  background-color: var(--th-ui-actionable);
  position: absolute;
  /* left center position */
  left: 0;
  top: 50%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  border-left: 1px solid rgba(0, 0, 0, 0.3);
  transform: translate(-50%, -50%) rotate(45deg);
}

.picker1 {
  display: inline !important;
  background: red !important;
}

.picker1 :global(.twitter-picker) {
  background-color: transparent!important;
  box-shadow: rgb(0 0 0 / 0%) 0px 0px 0px!important;
}

.picker1 :global([id^="rc-editable-input"]) {
  height: 11px !important;
  color: white !important;
}

.picker2 {
  margin: 0 auto;
  width: 95%; 
  background: green !important;
}