.form {
  width: 100%;
}

.filters {
  display: flex;
  flex-direction: row;
  gap: 6px;
}

.filters .searchIcon {
  margin-top: 5px !important;
}

.clearFilter {
  padding: 0px 5px !important;
}
.clearFilter svg {
  padding: 2px 0 0 0;
}
.clearFilter:hover svg {
  fill: var(--tx-light);
}
.clearFilter:active svg {
  opacity: 0.8;
}

.clearAllFilters button {
  padding: 7px 7px !important;
  background-color: var(--th-ui-actionable) !important;
}
.clearAllFilters:hover button {
  opacity: 0.7;
}

.filters :global(.field .ui.icon.input>input) {
  padding-left: 8px !important; /* reduce the left side padding slightly while we're at it */
  padding-right: 0px !important; /* remove the excess right side padding for icon based input fields, cuts off the text quite early without it */
}

.statusDropdown {
  /* border: 1px solid red; */
  /* width: 700px; */ /* TODO: why is this not applying as expected, have to use much larger values than expected */
  display: block !important;
}
.statusDropdown :global(div.dropdown) {
  min-height: 38px !important;
  /* min-width: 100px !important; */
}
.statusDropdown :global(.dropdown .icon) {
  padding: 10px !important;
}
.statusDropdown :global(.dropdown .menu) {
  left: 1px !important;
}

.tags {
  min-width: 100px;
}

.filters .hasChanges :global(.input input[type=text]) {
  border-color: green !important; /* var(--bd-lighter) */
}
.filters .hasChanges > :global(.dropdown) {
  border: 1px solid green !important; /* var(--bd-lighter) */
}
.filters .hasChanges > :global(.dropdown-container) { /* tags */
  transition: none !important;
  border: 1px solid green !important;
}

.form.mobile .filters {
}

/* override sui form field mobile defaults (NB: must be `max-width: 767px` to match up with the sui css declaration) */
@media only screen and (max-width: 767px) {
  .form.mobile:global(.ui.form:not(.unstackable) .fields:not(.unstackable)>.thirteen.wide.field) {
    width: 21% !important;
  }
  .form.mobile .filters :global(.field.wide) {
    width: auto !important;
  }
}