.field {}

.field .fieldWrapper{}

.field .numberInput {
  /* max-width: 140px; */
}

.field .numberInput input {
  color: inherit !important;
  background-color: #fff !important;
}

.field .numberInput :global(.ui.input) > input {
  width: 35px !important;
  padding: 0;
  text-align: center !important;
}
/* .field .numberInput button:global(.ui.button.mini) {
  padding: 9px 5px;
} */

.field .required {
}