.containerOuter {
  flex: 1;
  position: relative;
  /* background-color: rgba(255, 0, 0, 0.25); */
}

.containerMiddle {
  align-items: center;
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  margin: -5px;
  position: absolute;
  right: 0;
  top: 0;
  /* background-color: rgba(0, 255, 0, 0.25); */
}

.containerInner {
  display: flex;
  flex-shrink: 0;
  /* background-color: rgba(0, 0, 255, 0.25); */
}

.item {
  display: flex;
  margin: 5px;
}

.itemHidden {
  height: 0 !important;
  overflow: hidden;
  position: absolute;
  width: 0 !important;
}

.placeholder {
  background-color: var(--bg-dark-card);
  align-items: center;
  border: 1px dashed var(--bd-mid);
  display: flex;
  justify-content: center;
  width: 100%;
}

.placeholderLabel {
  color: var(--tx-light);
}

.pagination {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}
