.dimmer {
  background-color: rgba(0, 0, 0, 0.6);
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0; 
  top: 0;
  z-index: 3000 !important;
}

.popup {
  border-radius: 6px !important;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.35) !important;
  max-width: none !important;
  padding: 15px !important;
  transition: opacity 0.3s;
  z-index: 3000 !important; /* above header */
}

.popup::before {
  background-color: inherit !important;
  transform: translateY(-1px) rotate(45deg) !important;
  z-index: -1 !important;
}

:global(.left).popup::before {
  left: 1.25rem !important;
}

.title {
  color: var(--tx-muted);
  font-size: 12px;
  margin-bottom: 10px;
  text-align: center;
}
