.sidebarHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.sidebarTitle {
  flex-grow: 1;
  padding: 10px 0px 10px 10px !important;
  margin: 0 !important;
}

.loginServicesDetail {}
.loginServices {
  min-width: 250px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 5px;
  flex-wrap: wrap;
}
.loginServicesSidebar {
  justify-content: flex-start;
}
.loginService {
  padding: 2px 5px;
  border-radius: 4px;
  background-color: var(--bd-light);
  border: 1px solid var(--bd-mid);
  
}
