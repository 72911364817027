.demoBanner {
  color: var(--tx-light);
  background-color: var(--orange);
  border-radius: 5px;
  padding: 5px;
  margin: 10px 10px 0 10px;
  font-size: 1.2em;
  text-align: center;
  transition: margin 0.5s ease-out, border-radius 0.5s ease-out, font-size 0.5s ease-out;
}
.demoBannerCompact {
  border-radius: 0px;
  margin: 10px 0 0 0;
  font-size: 1.1em;
}