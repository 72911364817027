.meterTrack {
  align-items: flex-end;
  background-color: var(--th-ui-actionable);
  border-radius: 1px;
  display: flex;
  height: 16px;
  overflow: hidden;
  transform: translateX(-4.5px);
  width: 4px;
}

.meterBar {
  background-color: var(--tx-light);
  width: 100%;
}
