.popup {
  background-color: var(--bg-dark-mid) !important;
  font-size: 13px !important;
  max-width: 400px !important;
  padding: 15px !important;
}

.popup::before {
  background-color: var(--bg-dark-mid) !important
}

.popup :global(ul) {
  margin: 5px 0;
  padding-left: 15px;
}
