.sidebarHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.sidebarTitle {
  flex-grow: 1;
  padding: 10px 0px 10px 10px !important;
  margin: 0 !important;
}

.roleUpdated {
  margin: 10px;
}

.adminActionsPanel {
  padding: 6px 0 5px 0;
}
.adminActionsTitle {
  /* flex-grow: 1;
  padding: 10px 0px 0px 10px !important;
  margin: 0 !important;
  font-size: 0.9em; */
}
.adminActionsContent {
  padding: 0px 10px 5px 10px;
}