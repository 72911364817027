.quickview {
  text-align: center;
  max-width: 500px; /* increased the default max-width by ArkCenterLayout */
}
.quickview h3 {
  margin: 5px 0 5px 0;
}
.quickview .dbg {
  text-align: left;
}
ul.values {
  list-style: none;
  margin: 0 0 10px 0;
  padding: 0 0 0 10px;
}
.title {
  font-weight: bold;
  padding-right: 5px;
}
.error {
  font-weight: bold;
  color: red;
}