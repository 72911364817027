.segment {
  padding: 20px !important;
  min-width: 40% !important;
}

.header {
  align-items: center;
  border-bottom: 1px solid var(--bd-mid);
  display: flex;
  margin: -20px -20px 20px -20px;
  padding: 10px 20px;
}

.headerTitle {
  margin-bottom: 0 !important;
}

.menu {
  margin-top: 0 !important;
}

.profileFooter {
  text-align: center;
}

/**
 * breadcrumbs
 */

/* override the SUI button defaults for the custom breadcrumb back button (so its styled more like the breadcrumb) */
.breadcrumbBack:global(.ui.basic.inverted.button) {
  padding: 0.8em 0.85em 0.8em 0.1em !important;
  margin-right: 0px !important;
  box-shadow: none !important;
  color: #fff !important;
  opacity: 0.7;
}
.breadcrumbBack:global(.ui.basic.inverted.button):hover {
  box-shadow: none !important;
}
.breadcrumbBack:global(.ui.basic.inverted.button):active {
  box-shadow: none !important;
  background-color: transparent !important;
}
.breadcrumbBack:global(.ui.basic.inverted.button):focus {
  box-shadow: none !important;
}
.breadcrumbBack :global(i.icon) {
  margin: 0px !important;
  width: auto !important;
  font-size: 1.6em !important;
  line-height: 1em !important;
  transform: translateY(2px) !important; /* nudge the icon down a tiny bit to match the breadcrumb `>` equivilent one */
}

.breadcrumb {
  font-size: 1em !important;
  font-weight: 500 !important;
  /* padding: 10px; */
}
.breadcrumb :global(a) {
  color: #fff !important;
  opacity: 0.7;
}
.breadcrumb :global(i) {
  color: #fff !important;
  font-weight: 500 !important;
}
.breadcrumb :global(.section) {
  font-weight: 500 !important;
}
