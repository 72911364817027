.outer {
  align-items: center;
  display: flex;
  height: 24px;
  justify-content: center;
  position: relative;
  transition: 0.5s;
  /* background-color: rgba(255, 0, 0, 0.25); */
}

.disabled {
  opacity: 0.35;
  pointer-events: none;
}

.large .outer {
  height: 36px;
}

.vertical .outer {
  width: 24px;
}

.large.vertical .outer {
  width: 36px;
}

.vertical .inner {
  flex-shrink: 0;
  transform: rotate(-90deg);
}

.track {
  background-color: var(--th-ui-actionable);
  border-radius: 2px;
  height: 8px;
  overflow: hidden;
}

.large .track {
  height: 10px;
}

.bar {
  background-color: var(--th-feature1);
  height: 100%;
}

.input {
  appearance: none;
  bottom: 0;
  background-color: transparent;
  cursor: pointer;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.input::-moz-range-thumb {
  background-color: var(--tx-light);
  border: none;
  border-radius: 2px;
  height: 20px;
  width: 10px;
}

.large .input::-moz-range-thumb {
  height: 30px;
  width: 15px;
}

.input::-moz-range-thumb:hover {
  background-color: var(--ui-hover);
}

.input::-webkit-slider-thumb {
  appearance: none;
  background-color: var(--tx-light);
  border-radius: 2px;
  height: 20px;
  transition: background-color 0.3s;
  width: 10px;
}

.large .input::-webkit-slider-thumb {
  height: 30px;
  width: 15px;
}

.input::-webkit-slider-thumb:hover {
  background-color: var(--ui-hover);
}

.input::-webkit-slider-thumb:active {
  background-color: var(--ui-selected);
  transition: background-color 0.1s;
}
