/**
 * button
 */

.button {
  align-items: center;
  border-radius: 10px !important;
  display: flex !important;
  flex-direction: column;
  height: 240px;
  justify-content: center;
  width: 320px;
}

.buttonMobile {
  height: 160px;
  width: 100%;
}

.iconContainer {
  position: relative;
}

.lock {
  position: absolute;
  right: -20px;
  top: -10px;
}

.title {
  font-size: 15px;
}

/**
 * group
 */

.group {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: -10px;
  padding: 10px;
  width: 100%;
}

.group > * {
  margin: 10px !important;
}
