.text {
  position: relative;
  cursor: pointer;
}

.text .innerText {
  border: 0px solid red;
}

.text .popup {
  /* display: none; */
  position: absolute;
  top: 16px; /* NB: careful adjusting this, it effects the popup show/hide trigger as you move the mouse down */
  right: 0px;
  background-color: #333;
  border: 1px solid #fff;
  border-radius: 4px;
  padding: 5px;
  cursor: pointer;
}
/* .text.showPopup:hover .popup {
  display: block;
} */
.text .popup .copied {
  display: block;
  color: var(--green);
}