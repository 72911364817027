.modal {
  overflow: hidden;
  width: auto !important;
}

.modal :global(.close) {
  align-items: center;
  color: var(--tx-light) !important;
  display: flex;
  height: 40px !important;
  justify-content: center;
  margin: 0;
  opacity: 1 !important;
  padding: 0 !important;
  width: 40px !important;
}

.modal :global(.close):hover {
  color: var(--ui-hover) !important;
}

.startAudioLabel {
  color: var(--red);
  margin: -0.5rem 1.5rem 0.5rem 1.5rem;
  text-align: center;
}

.content {
  display: flex !important;
  max-width: calc(100vw - 80px);
  min-width: 450px;
}

.panels {
  display: flex;
  flex: 1;
  overflow-x: auto;
  padding-right: 1px;
}

.leftMaskOuter {
  position: relative;
}

.leftMaskInner {
  background: linear-gradient(-90deg, transparent, var(--bg-dark-mid));
  border-left: 2px solid var(--bg-dark);
  bottom: 0;
  left: 0; 
  position: absolute;
  top: 0;
  width: 15px;
  z-index: 1;
}

.rightMaskOuter {
  position: relative;
}

.rightMaskInner {
  background: linear-gradient(90deg, transparent, var(--bg-dark-mid));
  bottom: 0;
  position: absolute;
  right: 0; 
  top: 0;
  width: 15px;
}
