.container {
  aspect-ratio: 16 / 9;
  background-color: #000000;
  flex-shrink: 0;
  overflow: hidden;
  position: relative;
  border: 0px solid red;
}

.clickable {
  cursor: pointer;
}

.rounded {
  border-radius: 5px;
}

.content {
  height: 1080px;
  left: 50%;
  position: absolute;
  top: 50%;
  width: 1920px;
}

.body {
  height: 100%;
  position: relative;
  width: 100%;
}

.background {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.text {
  align-items: center;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  left: 0;
  padding: 96px;
  position: absolute;
  right: 0;
  text-align: center;
  top: 0;
  user-select: none;
}

.textDark {
  color: var(--tx-light);
  filter: drop-shadow(0 0 5px black);
}

.textLight {
  color: var(--tx-black);
  filter: drop-shadow(0 0 5px white);
}

.title {
  font-size: 96px;
  font-weight: 600;
  letter-spacing: 0.02em;
  line-height: 1.2;
  margin-bottom: 96px;
  border: 0px solid red;
}

.message {
  font-size: 48px;
  letter-spacing: 0.01em;
  line-height: 1.5;
  border: 0px solid green;
}

.footer {
  font-size: 48px;
  letter-spacing: 0.01em;
  line-height: 1.2;
  margin-top: 96px;
  border: 0px solid blue;
}

.dismissible {
  bottom: 0;
  cursor: pointer;
  left: 0;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: 0.3s;
}

.dismissible:hover {
  background-color: rgba(0, 0, 0, 0.15);
  opacity: 1;
}

.dismissible:active {
  background-color: rgba(0, 0, 0, 0.20);
}

.dismissibleIcon {
  position: absolute;
  right: 10px;
  top: 10px;
}

.notDismissible {
  bottom: 0;
  cursor: not-allowed;
  left: 0;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: 0.3s;
}

.notDismissible:hover {
  background-color: rgba(0, 0, 0, 0.15);
  opacity: 1;
}

.notDismissibleIcon {
  position: absolute;
  right: 10px;
  top: 10px;
}
