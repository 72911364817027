.container {
  align-items: center;
  background-color: var(--bd-mid);
  border: 1px solid var(--bd-light);
  border-radius: 4px;
  display: flex;
  height: 60px;
  justify-content: center;
  padding: 5px;
  position: relative;
  width: 160px;
}

.name {
  font-weight: bold;
  text-align: center;

  /* line clamp */
  line-clamp: 1;
  line-height: 1.5;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.cap {
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px;
  bottom: 0;
  left: 0;
  position: absolute;
  top: 0;
  width: 4px;
}

.popupOffline {
  margin-left: 5px;
  opacity: 0.5;
}
