
.field :global(input[type=tel]) {
  padding: 0 0 0 60px !important;
  font-family: var(--th-font-main) !important;
  height: 39px;
}
.field :global(.flag-dropdown) {
  border: none;
  background-color: transparent;
}
.field :global(.flag-dropdown .selected-flag) {
  background-color: var(--th-ui-actionable);
  width: 50px;
  border-radius: 5px;
  padding-left: 13px;
}
.field :global(.flag-dropdown .selected-flag:hover) {
  background-color: var(--th-ui-actionable);
}
.field :global(.flag-dropdown.open .selected-flag) {
  background-color: var(--th-ui-actionable);
}
.field :global(.arrow) {
  border-top: 4px solid var(--tx-light) !important;
}
.field :global(.flag-dropdown.open) {
  /* position: relative; */
}
.field :global(.country-list) {
  background-color: var(--th-ui-actionable) !important;
  border: 1px solid var(--bg-dark) !important;
  border-radius: 4px;
  color: var(--tx-light) !important;
}
.field :global(.country-list .divider) {
  border-bottom: 1px solid var(--bg-dark);
}
.field :global(.country-list .country) {
  color: var(--tx-light);
}
.field :global(.country-list .country.highlight) {
  /* background-color: var(--th-ui-actionable); */
  background: rgba(0,0,0,.05);
  color: var(--ui-selected);
}
.field :global(.country-list .country:hover) {
  /* background-color: var(--th-ui-actionable); */
  background: rgba(0,0,0,.05);
  color: var(--ui-hover);
}

/* custom 'large' input mode - increases the default sizes a bit.. */
.field:global(.large) :global(input[type=tel]) {
  font-size: 1.4em !important;
  height: 48px;
}
.field:global(.large) :global(.flag-dropdown .selected-flag .flag) {
  transform: scale(1.2);
}

/* custom 'right' position/align the dropdown (useful if showing in a modal & don't want it cropped off the bottom of the modal) */
.field:global(.alignRight) :global(.country-list) {
  top: -80px;
  left: 50px;
}

.notice {
  margin-top: 5px;
  font-size: 0.9em;
}