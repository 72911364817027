.helpSection {
  margin-bottom: 20px;
}
.helpSection p {
  margin-bottom: 2px;
}

.subHeader {
  margin-top: 0 !important;
  font-weight: bold !important;
}

.linkContainer {
  text-align: center;
}
.linkContainer a.link {
  text-decoration: underline;
}

.loginLink {
  text-align: center;
}
.loginLink a {
  cursor: pointer;
}
.loginLink a:hover {
  color: #aaa;
  text-decoration: underline;
}

.resetEmailSent:global(.ui.message.positive) {
  margin-bottom: 10px !important;
}

.passwordResetForm2 .email, .passwordResetForm2FA .email {
  margin-top: 5px;
  text-align: center;
}
.passwordResetForm2 .email .title, .passwordResetForm2FA .email .title {
  font-weight: bold;
  margin-right: 5px;
}
.passwordResetForm2 .divider, .passwordResetForm2FA .divider {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}
.resetCancel, .tfaCancel {
  margin-top: 10px;
  text-align: center;
}
.resetTokenNotFound :global(.header) {
  margin-bottom: 5px !important;
}
.resetTokenNotFound p {
  margin-top: 0px !important;
  margin-bottom: 5px !important;
}

.passwordResetForm2FA .fieldset {
  position: relative;
  padding: 18px 20px 20px 20px;
  margin: 0 0 2px 0;
}
.passwordResetForm2FA .fieldsetBorder {
  border-radius: 5px;
  border: 1px solid var(--bd-light);
}
.passwordResetForm2FA .divider {
  margin-top: 10px !important;
  margin-bottom: 20px !important;
}
