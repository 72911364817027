
hr.divider {
  border: none;
  border-bottom: 1px solid var(--bd-light);
}

.warning {
  color: orange;
}

.siteWideselectionNotice {
  font-size: 0.8em;
  color: orange;
  opacity: 0.75;
  margin: 0 0 10px 0 !important;
}
.siteWideselectionNotice .title {
  font-weight: bold;
}
.siteWideselectionNotice p {
  margin: 0;
}

.serverField {
  margin: 0 0 5px 0 !important;
}
.serverField .serverLabel {
  display: flex;
  flex-direction: row;
}
.serverField .serverLabel label {
  padding-right: 5px;
}

.programSelectDisabled :global(.fieldsetTitle) {
  color: rgb(80, 81, 85);
}
.programSelectDisabled .serverLabel, 
.programSelectDisabled .serverLabel label {
  color: rgb(80, 81, 85) !important;
}

.serverWarning {
  color: orange;
}
.serverWarning h3 {
  font-weight: bold;
  margin: 0;
}
.serverWarning p, .serverWarning ul {
  margin: 0;
}
.serverWarning .serverName {
  font-weight: bold;
}

.summary .field {
  display: flex;
  flex-direction: row;
}
.summary .field label {
  font-weight: bold;
  min-width: 80px;
  text-align: right;
  padding-right: 10px;
}
.summary .field .val {
}