.container {
  align-items: center;
  background-color: var(--bg-dark-card);
  border: 1px solid var(--bd-mid);
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  margin-bottom: 10px;
  overflow: hidden;
  padding: 10px;
}

.container:hover {
  background-color: var(--bg-hover);
}

.container.active {
  background-color: var(--bg-selected);
  border-color: var(--bg-light);
}

.disabled {
  opacity: 0.65;
}

.mobile {}

.left {
  display: flex;
}

.middle {
  flex: 1;
  min-width: 100px;
  overflow: hidden;
}

.title {
  font-size: 18px;
  font-weight: 500;
  line-height: 1.2;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.description {
  color: var(--tx-muted);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  
  display: flex;
  flex-direction: row;
  align-items: center;
}

.right {
  align-items: center;
  display: flex;
}
