.container {
  align-items: center;
  background-color: var(--bd-mid);
  border-radius: 4px;
  display: flex;
  height: 40px;
  justify-content: center;
  padding: 5px 1px 5px 1px;
  width: 40px;
}

.name {
  font-weight: bold;
  font-size: 12px;

  /* line clamp */
  line-clamp: 1;
  line-height: 1.5;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.popupOnlineOn {
  color: var(--green);
}

.popupOnlineOff {
  color: var(--red);
}
