.container {
  align-items: center;
  background-color: black;
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  overflow: hidden;
  pointer-events: all;
  position: absolute;
  right: 0;
  top: 0;
  user-select: none;
  /* background-color: rgba(255, 0, 0, 0.25); */
}

.semiTransparent {
  background-color: rgba(0, 0, 0, 0.5);
}

.messageContainer {
  position: relative;
  /* background-color: rgba(255, 0, 0, 0.25); */
}

.message {
  color: var(--tx-muted);
  text-align: center;
  text-transform: uppercase;
  left: 50%;
  margin-top: 15px;
  position: absolute;
  text-wrap: nowrap;
  top: 100%;
  transform: translate(-50%);
  /* background-color: rgba(255, 0, 0, 0.25); */
}
