.container {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.containerNoScroll {
  touch-action: none;
}

.mainContainer {
  display: flex;
  flex: 1;
  flex-basis: 0;
  margin: -10px;
  min-height: 0;
}

.mainContainer > * {
  margin: 10px;
}

.loader {
  background-color: var(--bg-dark);
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 2000; /* above node graph */
}