.pagination {
  background-color: var(--bg-dark-mid) !important;
  border-color: var(--th-ui-actionable) !important;
  border-radius: 4px;
}

.pagination :global(.item)  {
  color: var(--tx-light) !important;
}

.pagination :global(.active.item)  {
  background-color: rgba(255, 255, 255, 0.125) !important;
}

.pagination :global(.item:hover)  {
  background-color: rgba(255, 255, 255, 0.25) !important;
}
