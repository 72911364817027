.popup {
  width: 300px;
}

.label {
  color: rgba(255, 255, 255, 0.5);
  font-size: 12px;
  text-transform: uppercase;
}

.description {
  color: rgba(255, 255, 255, 0.75);
  font-size: 12px;
}

.advancedButton {
  align-items: center;
  color: var(--tx-light);
  cursor: pointer;
  display: inline-flex;
  font-size: 12px;
  text-transform: uppercase;
  transition: opacity 0.5s;
  user-select: none;
}

.advancedButton:hover {
  opacity: 0.65;
}

.advancedButton:active {
  opacity: 0.825;
  transition: opacity 0.1s;
}

.bufferLabelContainer {
  display: flex;
}

.bufferValue {
  font-size: 12px;
  font-variant-numeric: tabular-nums;
  margin-left: auto;
  text-transform: uppercase;
}

.advancedParametersForm {
  position: relative;
}

.advancedParametersFormField {
  margin-bottom: 0 !important;
}

.advancedParametersInput > input {
  padding-right: 38px !important;
}

.advancedParametersButton {
  height: 38px;
  position: absolute;
  right: 0;
  top: 0;
  width: 38px;
}

.defaultsContainer {
  text-align: right;
}
