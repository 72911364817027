.deviceLogin {
}
.deviceLoginSegment {
  max-width: 600px;
  font-size: 1.2em !important;
}
.deviceLoginForm .deviceCodeInput label {
  font-size: 1.2em !important;
}
.deviceLoginForm .deviceCodeInput input {
  font-size: 1.5em !important;
  letter-spacing: 0.5em;
  text-align: center !important;
  padding: 10px 10px !important;
}

.loginMsg {
  text-align: center;
}