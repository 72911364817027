
/* don't blur the popup if used within a modal with blurring enabled */
:global(.blurring.dimmed.dimmable) > .popupWrapper {
  filter: none !important;
}

.popup.compact {
  padding: 4px 10px !important;
}
.popup.compact :global(.content) {
  padding-top: 0 !important;
}