.userRow {
  width: 100;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  /* border: 1px solid red; */
}
.userRow .text {
  display: block;
  margin-left: 10px;
}

.mirroredGroupAdminBtn {
  margin: 5px 0 !important;
}