.button {
  align-items: center;
  color: var(--tx-light);
  display: flex;
  flex-shrink: 0;
  height: 40px;
  justify-content: center;
  position: relative;
  transition: 0.5s;
  width: 40px;
  -webkit-tap-highlight-color: transparent;
  /* background-color: rgba(255, 0, 0, 0.25);
  border: 1px solid red; */
}

.button:not(.disabled) {
  cursor: pointer;
}

.hoverOpacity:not(.disabled):hover {
  opacity: 0.65;
}

.button:not(.disabled):active {
  opacity: 0.825;
  transition: 0.1s;
}

.disabled {
  cursor: not-allowed;
  opacity: 0.35;
}

.hidden {
  opacity: 0;
}

.open {
  opacity: 0.65;
  transition: 0.3s;
  transform: scale(0.9);
}
