.userOption {
  display: flex !important;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: nowrap;
}
.userAvatar {
  /* margin-right: 10px; */
}
.userName {
  margin-left: 5px;
}