.container {
  position: relative;
  width: 100%;
  margin-top: 10px;
}

.loading {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 2000; /* above node graph */
}

.content {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.mainContainer {
  display: flex;
  height: 100%;
  margin: -10px;
  /* background-color: rgba(255, 0, 0, 0.25); */
}

.mainContainer > * {
  margin: 10px;
}
