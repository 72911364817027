.programRow {
  width: 100;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  /* border: 1px solid red; */
}
.programRow .avatar {
}
.programRow .avatarText {
  font-size: 0.8em;
}
.programRow .text {
  display: block;
  margin-left: 10px;
}