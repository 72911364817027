.sidebarHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.sidebarTitle {
  flex-grow: 1;
  padding: 10px 0px 10px 10px !important;
  margin: 0 !important;
}

.sidebarPanel {
  padding: 10px 0 10px 0;
  margin-bottom: 10px;
}

.list ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: inline;
  overflow-wrap: break-word;
}
.list li {
  display: inline-block; /* NB: was `inline-flex` - flipped so user avatars with icons align with those without */
  margin: 0px 5px 5px 0px!important;
}

.list.groupUsers ul li {
  padding-bottom: 5px;
}

.channelAvatar {
  font-family: var(--th-font-main);
  font-weight: 500;
  text-transform: uppercase;
  border-radius: 5px!important;
}

.loader {
  margin: 10px 10px 20px 10px;
}

.mirroredGroupAdminBtn {
  max-width: 360px; /* NB: work-around to show the right side margin */
  margin: 10px 10px 0px 10px !important;
}
