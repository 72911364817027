.badge {
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 4px;
  display: flex;
  flex-shrink: 0;
  height: 30px;
}

.leftBorder {
  border: 1px solid rgba(0, 0, 0, 0.35);
  border-bottom-left-radius: 4px;
  border-right: none;
  border-top-left-radius: 4px;
  width: 4px;
}

.middle {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.top {
  display: flex;
  height: 1px;
}

.topBorder {
  border-top: 1px solid rgba(0, 0, 0, 0.35);
  flex: 1;
}

.body {
  align-items: center;
  display: flex;
  flex: 1;
  font-family: var(--th-font-mono);
  font-size: 13px;
  justify-content: center;
  line-height: 1;
  padding: 0 5px;
  text-align: center;
}

.bottom {
  display: flex;
  height: 1px;
}

.bottomBorder {
  border-bottom: 1px solid rgba(0, 0, 0, 0.35);
  flex: 1;
}

.rightBorder {
  border: 1px solid rgba(0, 0, 0, 0.35);
  border-bottom-right-radius: 4px;
  border-left: none;
  border-top-right-radius: 4px;
  width: 4px;
}

.label {
  color: var(--tx-muted);
  font-family: var(--th-font-small);
  font-size: 6px;
  font-weight: 600;
  text-align: center;
  text-transform: uppercase;
  line-height: 1;
  margin: 0 2px;
  transform: translateY(-2px);
  /* background-color: rgba(255, 0, 0, 0.25); */
}
