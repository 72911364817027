.container {
  bottom: 0;
  left: 0;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 0;
  /* background-color: rgba(0, 255, 0, 0.25) */
}

.image {
  height: 100%;
  width: 100%;
}
