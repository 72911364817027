/**
 * card
 */

.card {
  background-color: var(--bg-dark-card);
  border-radius: 5px;
  display: flex;
  flex: 1;
  flex-direction: column;
  min-height: 150px;
  padding: 10px;
}

.card.mobile {
  min-height: auto;
}

.cardTop {
  display: flex;
  justify-content: space-between;
  padding: 10px;
}

.cardRight {
  text-align: right;
}

.cardTitle {
  font-weight: bold;
}

.cardName {
  align-items: center;
  display: flex;
  text-align: right;
}

.cardNumber {
  font-size: 28px;
}

.cardHidden {
  cursor: pointer;
  font-size: 12px;
  opacity: 0.5;
  transition: 0.3s;
}

.cardHidden:hover {
  opacity: 1;
}

.cardHidden:active {
  opacity: 0.825;
  transition: 0.1s;
}

.cardButton {
  align-self: flex-end;
}

/**
 * group
 */

.group {
  display: flex;
  margin: -20px;
  padding: 10px;
}

.group.mobile {
  align-items: stretch;
  flex: 1;
  flex-direction: column;
}

.group > * {
  margin: 10px;
}
