.projectRow {
  width: 100;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  /* border: 1px solid red; */
}
.projectRow .text {
  display: block;
  margin-left: 10px;
}