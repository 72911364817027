.container {
  background-color: var(--bg-dark-card);
  border-radius: 6px;
  flex: 1;
  opacity: 0;
  position: relative;
  transition: 0.3s;
}

.containerLoaded {
  opacity: 1;
}

.containerMobile {
  background-color: transparent;
  margin: -10px !important;
}

.reactFlow {
  cursor: grab;
  transition: 0.3s;
}

.reactFlow:active {
  cursor: grabbing;
}

.reactFlowDisabled {
  opacity: 0.75;
}

.topRightControls {
  display: flex;
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 1000;
}

.topRightControlsMobile {}

.bottomRightControls {
  bottom: 10px;
  position: absolute;
  right: 10px;
  z-index: 1000;
}

.bottomRightControlsMobile {}

.controlsButton {
  filter: drop-shadow(0 0 5px var(--bg-dark-card));
  height: 40px;
  min-height: 0;
  min-width: 0;
  padding: 0;
  width: 40px;  
}

.controlsButtonMobile {
  height: 30px;
  width: 30px;
}