.settingsPage {
  width: 100%;
  margin-top: 6px;
  padding: 4px;
  display: flex;
  gap: 10px;
}

.transcoderSettings {
  width: 400px;
  border: 0px solid red;
}

.transcoderSettings .loading {
  margin: 20px;
}

.transcoderSettings .transcoderHeader {
  padding: 0px;
  text-align: left;
}
.transcoderSettings .transcoderHeader :global(.content) {
  padding: 15px 15px 15px 15px !important;
}
.transcoderSettings .transcoderFooter {
  padding: 10px !important;
}
.transcoderSettings table {
  /* border-collapse: collapse; */
  /* padding: 6px 0px; */
}
/* .transcoderSettings :global() {

} */
.transcoderSettings table tbody tr {
  /* border-bottom: 1px solid var(--th-ui-actionable) !important; */
}
.transcoderSettings table tbody tr td {
  height: 30px;
  /* border-bottom: 0px solid red !important; */
}

.propertyTitle {
  /* width: 220px !important; */
}
.propertyValueOff {
  color: var(--orange);
}

.propertyValue {
  display: flex;
}
.propertyValue .loading {
  margin-left: 10px;
}

.numberInput :global(.ui.input) > input {
  width: 35px;
  padding: 0;
  text-align: center !important;
}
.numberInput button:global(.ui.button.mini) {
  padding: 9px 5px;
}

.transcoderSettings .transcoderFooter .footerWrapper {
  /* display: flex !important;
  justify-content: space-between;
  align-items: center; */
}
.transcoderSettings .transcoderFooter .buttons {
  width: 100%;
  display: flex !important;
  justify-content: space-between;
  align-items: center; 
}
.transcoderSettings .transcoderFooter .saveChanges {
  color: var( --orange);
}


.transcoderSettings .transcoderSync {}
.transcoderSettings .transcoderSync .transcoderSyncTitle {
  font-weight: bold;
  margin:  0 0 5px 10px;
  color: var(--tx-muted);
}

.transcoderSettings .transcoderSync .transcoderSyncForce {
  margin-top: 10px;
}

.transcoderSettings .transcoderSync .transcoderSyncRequired {
  color: var(--orange);
}
.transcoderSettings .transcoderSync .transcoderSyncRequired .transcoderSyncWarning {
  border: 1px solid var(--orange);
  border-radius: 10px;
  padding: 10px;
  /* font-weight: bold; */
  display: flex;
  flex: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  gap: 10px;
  width: 100%;
}
.transcoderSettings .transcoderSync .transcoderSyncRequired .transcoderSyncWarning .notice {
  font-weight: bold;
}
.transcoderSettings .transcoderSync .transcoderSyncRequired .transcoderSyncWarning .title {
  font-weight: bold;
}
.transcoderSettings .transcoderSync .transcoderSyncRequired button {
  background-color: var(--orange) !important;
}
.transcoderSettings .transcoderSyncSuccess {
  color: var(--green);
  text-align: center;
  margin-bottom: 10px;
  border: 1px solid var(--green);
  border-radius: 10px;
  padding: 10px;
}
.transcoderSettings .transcoderSyncSuccess .title {
  font-weight: bold;
}
.transcoderSettings .transcoderSyncError {
  color: var(--red);
  text-align: center;
  border: 1px solid var(--red);
  border-radius: 10px;
  margin-bottom: 10px;
  padding: 5px;
}
.transcoderSettings .transcoderSyncError .title {
  font-weight: bold;
}


.accessSettings {
  width: 400px;
  margin-bottom: 10px;
}
.accessSettings .header {
  padding: 0px;
  text-align: left;
}
.accessSettings .header :global(.content) {
  padding: 15px 15px 15px 15px !important;
}

.loginServiceSettings .loadingError {
  color: var(--red);
  font-size: 0.9em;
}

.userSettings {
  width: 400px;
  margin-bottom: 10px;
}
.userSettings .header :global(.content) {
  padding: 15px 15px 15px 15px !important;
}
.userSettings .userFooter {
  padding: 10px !important;
}
.userSettings .userFooter .buttons {
  width: 100%;
  display: flex !important;
  justify-content: space-between;
  align-items: center; 
}
.userSettings .userFooter .saveChanges {
  color: var( --orange);
}

/**
 * common
 */

.panel {
  width: 400px;
}

.panelHeader {
  padding: 15px;
}

.panelFooter {
  padding: 10px;
}

.panelFooterButtons {
  display: flex;
  justify-content: space-between;
}

.panelFooterText {
  color: var(--tx-muted);
  font-weight: bold;
  margin-bottom: 5px;
  text-align: center;
}

.panelFooterTextDanger {
  color: var(--th-danger);
}

.panelFooterTextSuccess {
  color: var(--th-positive);
}
