.container {
  flex: 1;
  position: relative;
  border: 0px solid red;
}

.body {
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  border: 0px solid green;
}
