
.modal {
  max-width: 400px;
}

.modal :global(.content) {
  max-height: 80vh;
  overflow-y: scroll;
}

.title {
  font-size: 16px;
  opacity: 0.875;
  text-align: center;
}
