.sidebarHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.sidebarTitle {
  flex-grow: 1;
  padding: 10px 0px 10px 10px !important;
  margin: 0 !important;
}

.panelTitle {
  text-transform: capitalize;
}

.status {
  width: 80px;
  text-align: center;
  /* margin-left: 20px;
  margin-right: 20px; */
}
.status.online {
  text-align: left;
  color: green;
}
.status.offline {
  text-align: left;
  color: red;
}

.programFlagTitle {
  align-self: flex-start;
}
.programFlagValues {
  flex-direction: column;
  align-items: flex-start !important;
}
.programFlagWarningNotice {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 10px 10px 10px;
}
.programFlagWarningNotice .warningIcon {
  display: block;
}
.programFlagWarningNotice .warningText {
  display: block;
  margin-left: 8px;
}
.programFlagWarningNotice .warningText a {
  text-decoration: underline;
}
.programUpdateAvailableNotice {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 10px 0px 10px;
}
.programUpdateAvailableNotice .warningIcon {
  display: block;
}
.programUpdateAvailableNotice .warningText {
  display: block;
  margin-left: 8px;
}
.programUpdateAvailableNotice .warningText a {
  text-decoration: underline;
}
.programUpdateAction {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.programUpdateAvailableNotice .warningHint {
  margin-left: 8px;
  padding-top: 5px;
}