/* FIXME remove spacing in <RightSidebar> */
.container {
  bottom: 0;
  display: flex;
  flex-direction: column;
  left: 0;
  position: absolute;
  right: 2px;
  top: 1px;
}

.body {
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow-y: hidden;
  position: relative;
}

.main {
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow-y: auto;
  padding: 0 10px;
}

.status {
  color: var(--tx-muted);
  font-size: 13px;
}

.footer {
  padding: 0 10px 10px 10px;
}

.loading {
  background-color: rgba(0, 0, 0, 0.35);
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}
