.container {
  align-items: center;
  background-color: var(--bd-mid);
  border: 1px solid var(--bd-light);
  border-radius: 20px;
  display: flex;
  height: 40px;
  justify-content: center;
  padding: 5px;
  width: 160px;
}

.name {
  font-weight: bold;
  text-align: center;

  /* line clamp */
  line-clamp: 1;
  line-height: 1.5;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
