.phoneUnverified,
.phoneVerified {
  margin-left: 5px;
}
.phoneUnverified {
  color: var(--red);
}
.phoneVerified {
  color: var(--green);
}

.tfaEditLink {
  margin-left: 5px;
}