.button {
  align-items: center;
  background-color: var(--bg-dark-mid);
  border-radius: 20px;
  cursor: pointer;
  display: flex;
  height: 40px;
  justify-content: center;
  transition: 0.5s;
  width: 40px;
}

.button:not(.active):hover {
  opacity: 0.65;
}

.active {
  background-color: var(--tx-light);
}
