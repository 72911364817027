.meter {
  border-radius: 1px;
  display: flex;
  flex-direction: column;
  height: 24px;
  justify-content: flex-end;
  overflow: hidden;
  position: relative;
  width: 6px;
}

.track {
  bottom: 0;
  left: 0;
  opacity: 0.15;
  position: absolute;
  right: 0;
  top: 0;
}

.bar {
  min-height: 2px;
  z-index: 1;
}
