.broadcastView {
  position: relative;
}

.broadcastEnableOverlay {
  /* overlay the default view/page content */
  position: absolute;
  top: 5px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  /* centre the overlays content */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.broadcastEnableMask {
  /* fill the overlay & mask/block the content underneath it */
  position: absolute;
  top: 5px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background-color: var(--bg-dark);
  opacity: 0.95;
}
.broadcastEnableContent {
  max-width: 400px;
  padding: 10px;
  background-color: var(--bg-black);
  border-radius: 5px;
  z-index: 1;
}

.progOnline {
  color: green;
}
.progOffline {
  color: red;
}

.programsRefreshField {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-left: 0px !important;
}

.controlsFieldset {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.controlsGroup {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 0 !important;
}

video {
  width: 100%;
  height: auto;
}
.flipVideo {
  transform: scale(-1, 1); /* Firefox (& IE) */
  -webkit-transform: scale(-1, 1); /* Chrome & Opera (& Safari) */
}

.tabWarningFieldset {
  border-color: #f3be0e;
}
.tabWarningNotice {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
}
.tabWarningNotice .warningIcon {
  margin-right: 15px;
}
.tabWarningNotice .warningTxt {
  margin-right: 15px;
  flex-grow: 1;
}
.tabWarningNotice .warningTxt p {
  margin: 5px 0;
}
.tabWarningNotice .warningBtn {
  flex-grow: 1;
}