.topbar {
  display: flex;
  width: 100%;
  height: 40px;
  margin-bottom: 10px;
  border: 0px solid green;

  /* ORIGINAL STYLING (from App.css) */
  /*
  padding: 4px;
  color: var(--tx-light);
  font-family: var(--th-font-main);
  text-transform: none;
  text-shadow: none;
  font-weight: 500;
  font-size: 14px;
  line-height: 1em;
  font-style: normal;
  text-decoration: none;
  */
}
.topbarLeft {
  justify-content: flex-start;
  align-items: flex-end;
  display: flex;
  width: 50%;
  height: 100%;
  border: 0px solid green;
}
  
.topbarRight {
  justify-content: flex-end;
  align-items: flex-end;
  display: flex;
  width: 50%;
  border: 0px solid blue;
  gap: 10px;
}

.topbarSelect {
  width: 200px;
}

.topbarIcon {
  margin-left: 10px;
}

.topbarButton {
  margin: 0px 10px 0px 10px;
  /*
  button{
    min-width: 200px;
    min-height: 40px;
  }
  */
}

.updating {
  padding-left: 15px;
  opacity: 0.7;
}
.updating .updatingTxt {
  padding: 0px 0px 0px 10px;
}
