.panel {
  border: 1px solid var(--bg-dark);
  border-bottom: 0;
  border-top: 0;
  flex-shrink: 0;
  margin: 9px 0;
  padding: 15px;
  width: 151px;
}

.panelMaster {
  background-color: var(--tx-light-mid);
  border-radius: 4px;
  box-shadow: 0px 0px 8px 2px var(--bg-dark);
  z-index: 500;
}

.autoSolo {
  position: relative;
}

.autoSoloLabel {
  position: absolute;
  top: -5px;
  right: -5px;
}

.noPaddingButton {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.fader {
  display: flex;
  justify-content: center;
}

.faderPre,
.faderPost {
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 10px;
}

.faderLabel {
  color: var(--tx-muted);
  font-size: 12px;
  margin-top: 10px;
  text-align: center;
}

.volume {
  display: flex;
  justify-content: center;
}

.volumeLabel {
  font-variant-numeric: tabular-nums;
  white-space: nowrap;
  width: 60px;
}

.volumeLabelMute {
  color: var(--tx-muted);
}

.disabledLabel {
  color: var(--tx-muted);
  text-align: center;
}

.name {
  align-items: center;
  background-color: rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  display: flex;
  height: 60px;
  justify-content: center;
}

.nameLabel {
  font-size: 15px;
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
}
