.notEmailLink {
  text-align: center;
  margin-top: 12px;
  /* text-decoration: underline; */
}

.notEmailLink:hover {
  cursor: pointer;
  color: #aaa;
  text-decoration: underline;
}
