.border {
  border-radius: 5px;
  border: 1px solid var(--bg-light-mid);
  position: relative;
  margin-top: 10px; /* adds some space for the label above the border */
}
.borderLight {
  border: 1px solid var(--bd-light);
}
.label {
  position: absolute;
  transform: translateY(-50%);
  padding: 5px 5px 5px 5px;
  margin-left: 10px;
  background-color: var(--bg-dark-mid); /* mask/hide the fieldset border the title overlaps */
}
.labelPad {
  padding-bottom: 10px; /* pads the top border edge with the content, to allow space for the label */
}
.padded .content {
  padding: 10px 15px;
}