.container {
  /* hit area for popup */
  margin: -5px;
  padding: 5px;
  /* background-color: rgba(255, 0, 0, 0.25); */
}

.indicator {
  background-color: var(--grey);
  border: 1px solid rgba(0, 0, 0, 0.5);
  border-radius: 100px;
  height: 10px;
  width: 10px;
}
