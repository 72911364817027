.hint {
  background-color: var(--bg-dark-card) !important;
  color: var(--tx-bg-light) !important;
  border-radius: 6px !important;
  box-shadow: 0 10px 20px #00000059 !important;
  /* max-width: 200px !important;
  min-width: 120px !important; */
  /* padding: 15px !important; */
}

.hint a {
  text-decoration: underline;
}

.hint::before {
  background-color: inherit !important;
  transform: translateY(-1px) rotate(45deg) !important;
}

.triggerWithText {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.triggerWithText .triggerIcon {
  margin-right: 4px;
}
.triggerWithText .triggerText {
  display: inline-block;
}

/* don't blur the hint popup if used within a modal with blurring enabled */
:global(.blurring.dimmed.dimmable) > .hintWrapper {
  filter: none !important;
}
