.hasChanged label,
.hasChanged label .flagTitle span {
  color: var(--green) !important; /* --orange */
  font-style: italic;
}

.projectVEForm {}

/* video engine dropdown */
.videoEngineDropdown .videoEngineDropdownTrigger {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-right: 32px !important;
}
.videoEngineDropdown .videoEngineDropdownTrigger.videoEngineNone {
  font-style: italic;
}
.videoEngineDropdown .videoEngineOption {
  color: var(--bg-light);
  display: flex;
  justify-content: space-between;
}
.videoEngineDropdown .status.statusEnabled {
  color: var(--green) !important;
}
.videoEngineDropdown .status.statusDisabled {
  color: var(--red) !important;
}
.videoEngineDropdown .videoEngineOption.videoEngineOptionNone {
  font-style: italic;
}
