.container {
  align-items: center;
  display: flex;
  margin-bottom: 5px;
}

.label {
  /* font-size: 14px; */
  font-weight: 600;
}

.required {
  color: var(--th-danger);
}
