.phoneNo {
  font-size: 1.4em;
  margin-top: 10px;
  /* text-align: center; */
}
.phoneNoTitle {
  font-weight: bold;
}
.dividerTitle {
  color: var(--tx-light) !important;
}

.requestCode {
  display: flex;
  align-items: center;
}
.requestCode .requestCodeTitle {
  flex: 1;
}
.requestCode .requestCodeBtn {
  flex: 1;
}

.requestCodeSent {}

.fieldVerifyCodeBtn {
  /* align with the field to its left */
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}
.fieldVerifyCodeBtn button {
  width: 100%;
}

.verifiedMsg {
  margin: 20px 0px;
}
.verifiedBackBtn {
  margin-top: 10px !important;
}