.form {
  width: 100%;
}

.clearFilter {
  padding: 0px 5px !important;
}
.clearFilter svg {
  padding: 2px 0 0 0;
}
.clearFilter:hover svg {
  fill: var(--tx-light);
}
.clearFilter:active svg {
  opacity: 0.8;
}
