.container {
  text-align: center;
}

.badge {
  transition: 0.3s;
}

.badge:hover {
  opacity: 0.65;
}

.badge:active {
  opacity: 0.825;
  transition: 0.1s;
}
