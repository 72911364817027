.main {
  align-items: center;
  display: flex;
}

.dot {
  animation: blink 1s infinite alternate;
  background-color: var(--tx-light);
  border-radius: 3px;
  height: 6px;
  margin-right: 3px;
  width: 6px;
}

@keyframes blink {
  0%, 49% {
    opacity: 1;
  }
  50%, 100% {
    opacity: 0;
  }
}
