.view {
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
}

.title {
  font-size: 28px;
  text-align: center;
}

.message {
  color: var(--tx-dim);
  line-height: 1.5;
  max-width: 640px;
  text-align: center;
}
