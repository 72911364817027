.breadcrumb {
  border: 0 solid red;
}

.breadcrumb :global(a) {
  color: var( --tx-muted) !important;
}

.breadcrumb :global(.divider) {
  color: var( --tx-muted) !important;
  opacity: 1 !important;
}
