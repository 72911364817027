.header {
  align-items: center;
  border-bottom: 1px solid var(--th-ui-actionable);
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  height: 60px;
  justify-content: center;
  position: relative;
}

.title {
  font-size: 16px;
  font-weight: 600;
  text-align: center;
}

.left {
  display: flex;
  left: 10px;
  position: absolute;
  top: 10px;
  /* background-color: rgba(255, 0, 0, 0.25); */
}

.right {
  display: flex;
  position: absolute;
  right: 10px;
  top: 10px;
  /* background-color: rgba(255, 0, 0, 0.25); */
}
