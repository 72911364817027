.info {
  margin-bottom: 10px;
  text-align: center;
}
.info p {
  margin-bottom: 10px;
}

.notUser {
  padding-top: 5px;
}

.loginHelp {
  text-align: center;
  padding-top: 15px;
}
.loginHelp a.loginHelpLink {
  cursor: pointer;
}
.loginHelp a.loginHelpLink:hover {
  color: #aaa;
  text-decoration: underline;
}

/* TODO: DEPRECIATE .forgotPassword/.forgotPasswordLink - see .loginHelp instead */
.forgotPassword {
  text-align: center;
  padding-top: 12px;
}
.forgotPassword a.forgotPasswordLink {
  cursor: pointer;
}
.forgotPassword a.forgotPasswordLink:hover {
  color: #aaa;
  text-decoration: underline;
}

.emailPassEmailTitle {
  margin-bottom: 0 !important;
}
.emailPassEmailAddress {
  display: block;
  font-weight: bold;
  font-size: 1.2em;
  padding-top: 4px;
}
.emailPassHideEmail {
  display: none;
}

.tfaForm {
  margin-bottom: 0px !important;
}
.tfaNotUser {
  margin-top: 2px !important;
}

.register {
  text-align: center;
  padding: 0 0 20px 0;
}

