.listItem {
  /**
   * TESTING: set the scroll offset so the site header, page title & filter (& all padding/margins) are taken into account when `scrollIntoView` is used
   * NB: calc = ('header-height' + 'title height' + 'filter height' + 'various vertical padding/margins')
   * TODO: should remove the hard-coded values here & instead dynamically calc & apply in JS instead
   */
  scroll-margin-top: calc(var(--header-height) + 25px + 40px + 35px);
  scroll-padding-top: calc(var(--header-height) + 25px + 40px + 35px);
}
.detailText {
  font-size: 18px;
}
