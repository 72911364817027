.container {
  background-color: var(--bg-dark-card);
  border-radius: 5px;
  position: relative;
  width: 100%;
  /* border: 1px solid red; */
}

.scroll {
  height: 40px;
  overflow-y: hidden;
  /* border: 1px solid green; */
}

.content {
  padding: 15px;
  position: relative;
  /* border: 1px solid blue; */
}

.track {
  background-color: var(--th-ui-actionable);
  border-radius: 2px;
  height: 10px;
  overflow: hidden;
  position: relative;
}

.bar {
  background-color: var(--th-feature1);
  height: 10px;
  position: absolute;
  right: 0;
}

.sliderContainer {
  left: 15px;
  position: absolute;
  right: 15px;
  top: 0;
}

.caret {
  left: 0;
  padding-top: 10px;
  pointer-events: none;
  position: absolute;
  top: 0;
  transition: opacity 0.3s;
  z-index: 1; /* above markers */
}

.caretBody {
  background-color: var(--ui-hover);
  height: 20px;
  width: 1px;
}

/**
 * scrollbar
 */

.scroll::-webkit-scrollbar {
  height: 10px;
}

.scroll::-webkit-scrollbar-thumb {
  background-color: var(--tx-dim) !important; /* `!important` for window blur */
  border: 2.5px solid var(--bg-dark-card);
  border-radius: 100px;
}

.scroll::-webkit-scrollbar-thumb:hover {
  background-color: var(--ui-hover) !important;
  border-width: 1.5px;
}

.scroll::-webkit-scrollbar-thumb:active {
  background-color: var(--ui-selected) !important;
}

/* firefox */
@supports not selector(::-webkit-scrollbar) {
  .scroll {
    scrollbar-color: var(--ui-selected) transparent;
    scrollbar-width: thin;
                       
  }
}
