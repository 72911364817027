.container {
  background-color: black;
  position: relative;
  width: 100%;
}

.clickable {
  cursor: pointer;
}

.body {
  height: 100%;
  overflow: hidden;
  position: relative;
}

.autoSoloBorder {
  pointer-events: none;
  border: 2px solid var(--green);
  bottom: 0;
  left: 0;
  margin: -2px;
  position: absolute;
  right: 0;
  top: 0;
}
