.toolbar {
  align-items: center;
  border: 1px solid var(--bg-dark-mid);
  border-radius: 4px;
  display: flex;
  height: 38px;
  position: relative;
}

.label {
  background-color: var(--bg-dark);
  color: var(--tx-light-mid);
  font-size: 10px;
  left: 50%;
  padding: 0 5px;
  position: absolute;
  top: 0;
  transform: translate(-50%, -50%);
}

.fullscreen .label {
  background-color: var(--bg-black);
}
