.projectDropdown {
  display: flex !important;
  /* align-content: center !important; */
  padding: 0px !important;
  padding-left: 15px !important;
  /* border: 1px solid red !important; */
  z-index: 3000 !important; /* above loader view */
}

/* project dropdown - items */
.projectDropdown :global(.item) {
  flex-grow: 1;
  display: flex !important;
  justify-content: space-between;
  /* border: 1px solid red !important; */
}
.projectDropdown :global(.item .text) {
  line-height: 1.2em !important;
  /* vertically align the content */
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.projectDropdown :global(.item .description) {
  /* color: red !important; */
  float: none !important;
  order: 1;
  text-align: right;
  /* vertically align the content */
  display: flex;
  flex-direction: column;
  justify-content: center;
}

/* 'Select Project' placeholder text */
.projectDropdown :global(.divider.text) {
  flex-grow: 1;
  /* vertically align the content */
  display: flex !important;
  flex-direction: column;
  justify-content: center;
}

.projectDropdown :global(.icon) {
  position: relative !important;
  top: auto !important;
  right: auto !important;
  float: none !important;
  margin: 0 !important;
  /* border: 1px solid red !important; */
}

/* project dropdown - trigger (shows the current selection when the dropdown is closed) */
/* NB: tries to miimic what the project item looks like in the dropdown list/menu when its open */
.dropdownTrigger {
  font-size: 1rem;
  line-height: 1em;
}
.dropdownTrigger :global(.text) {
  font-size: 16px;
  /*border: 1px solid blue;*/
}
.dropdownTrigger :global(.description) {
  /*border: 1px solid red;*/
}