.card {
  background-color: var(--bg-dark-card);
  border-radius: 6px;
  overflow: hidden;
  position: relative;
  width: 400px;
}

.header {
  align-items: center;
  display: flex;
  height: 60px;
  justify-content: center;
  position: relative;
}

.title {
  font-size: 16px;
  opacity: 0.875;
  text-align: center;
}

.closeButton {
  position: absolute;
  right: 0;
  top: 10px;
}

.body {
  height: 100%;
  overflow-y: scroll;
  padding: 0 20px 20px 20px;
}
