.main {
  align-items: center;
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  position: relative;

  /* defaults */
  background-color: var(--grey);
  border-radius: 5px; /* 12.5% of 40px */
  height: 30px;
  width: 40px;
}

.initials {
  font-family: var(--th-font-small);
  font-weight: 600;

  /* defaults */
  font-size: 14px; /* 35% of 40px */
}

.onlineStatusIndicator {
  bottom: 0;
  position: absolute;
  right: 0;
  transform: translate(calc(100% / 3), calc(100% / 3));
}
