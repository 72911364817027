.sidebarHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.sidebarTitle {
  flex-grow: 1;
  padding: 10px 0px 10px 10px !important;
  margin: 0 !important;
}

.editPortsBtn {
  display: block !important;
  width: 100% !important;
  max-width: 360px !important;
  margin: 10px auto !important;
}