.container {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
}

.container > * {
  max-width: 400px;
  width: 100%;
}
