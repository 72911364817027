.avatar {
  border-radius: 5px;
}

.status {}

.status.online {
  color: var(--green);
}

.status.offline {
  color: var(--red);
}

.programWarningHint {
  margin: 0 3px 0 5px;
}
.programWarningHintTitle {
  font-size: 1.1em;
  font-weight: bold;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 2px;
}
.programWarningHintTitleIcon {
  margin-right: 3px;
  margin-bottom: 2px;
}

.programHotlinkBtn {
  margin-left: 5px !important;
  width: auto;
  height: auto;
}
.programHotlinkPopup {
  min-width: 150px !important;
  padding: 5px 0px !important;
  margin-left: -13.5px !important; /* centre the popup arrow/pointer on the button */
}
.programHotlinkPopupTitle {
  margin: 0px;
}
.programHotlinkPopupContent {
  text-align: center;
}
.programUpdateAvailableNotice {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 10px 10px 10px;
}
.programUpdateAvailableNotice .warningIcon {
  display: flex;
  align-items: center;
}
.programUpdateAvailableNotice .warningText {
  display: block;
  margin-left: 4px;
}
.programUpdateAvailableNotice .warningText a {
  text-decoration: underline;
}