.checkbox :global(label) {
  color: var(--tx-muted) !important;
}

.checkbox :global(label):hover {
  opacity: 0.875;
}

.checkbox:global(.ui.toggle.checkbox input:checked~label),
.checkbox:global(.ui.toggle.checkbox input:checked:focus~label) {
  color: var(--tx-light) !important;
}

.checkbox:global(.ui.toggle.checkbox label:before) {
  background-color: var(--th-ui-actionable) !important;
}

.checkbox:global(.ui.toggle.checkbox input:checked~label:before),
.checkbox:global(.ui.toggle.checkbox input:checked:focus~label:before) {
  background-color: var(--th-feature1) !important;
}

.checkbox:global(.ui.checkbox.disabled label) {
  cursor: not-allowed !important;
  opacity: 0.4;
}

.disabledPopup {
  color: var(--text-light) !important;
  border: 1px solid var(--bd-light) !important;
  background-color: var(--bg-dark-card) !important;
  border-radius: 6px !important;
  padding: 5px 10px !important;
}
.disabledPopup::before {
  background-color: var(--bg-dark-card) !important;
  box-shadow: -1px -1px 0 0 var(--bd-light) !important;
}
