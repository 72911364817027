.sidebarHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.sidebarTitle {
  flex-grow: 1;
  padding: 10px 0px 10px 10px !important;
  margin: 0 !important;
}

.channelAvatar {
  border-radius: 5px;
}

.channelPrograms {
  white-space: initial; /* allow overflow onto new lines */
  line-height: 1.2em;
}
.channelPrograms h4 {
  font-weight: bold;
  margin-bottom: 2px;
}
.channelPrograms ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: inline;
  overflow-wrap: break-word;
}

.channelPrograms .list ul {
  list-style: none;
}
.channelPrograms .list li {
  display: inline-flex;
  margin: 0px 5px 5px 0px;
}