.accessToggle {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.loader {
  margin-left: 10px !important;
}

.error {
  /* max-height: 40px; */
  margin: 5px 10px 0px 0px !important;
  padding-top: 2px !important;
  /* margin-top: 10px !important; TODO: ideally add top margin to the error in the sidebar but not in the listing */
}
