.container {
  align-items: center;
  animation: containerAnimation 0.5s;
  display: flex;
  flex-direction: column;
  height: 80px;
  justify-content: center;
  width: 200px;
  /* background-color: rgba(255, 0, 0, 0.25); */
}

@keyframes containerAnimation {
  from { opacity: 0 }
  to { opacity: 1 }
}

/* .label {} */

.hidden {
  cursor: pointer;
  font-size: 12px;
  opacity: 0.5;
  transition: 0.3s;
}

.hidden:hover {
  opacity: 1;
}

.hidden:active {
  opacity: 0.825;
  transition: 0.1s;
}
