.container {
  height: 100%;
  pointer-events: none; /* prevent double-click fullscreen */
  width: 100%;
  /* background-color: rgba(255, 0, 0, 0.25); */
}

.hidden {
  display: none;
}

/* fix player resize */
.container :global(.sldp_player_wrp) {
  height: 100% !important;
  width: 100% !important;
}

/* hide player error messages */
.container :global(.sldp_message_wrp)  {
  display: none;
}
