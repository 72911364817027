.container {
  align-items: center;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 1000px;
  display: flex;
  height: 40px;
  justify-content: center;
  position: relative;
  width: 40px;

  /* animation */
  opacity: 0;
  transform: scale(0.75);
  transition: 0.5s;
}

.container.show {
  opacity: 1;
  transform: scale(1);
}

.progressContainer {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;

  /* animation */
  transition: 0.5s;
}

.progressContainer.hide {
  opacity: 0;
}

.popup {
  max-width: none !important;
}

.lockedText {
  color: var(--th-danger);
}
