.modal {
  width: auto !important;
}

.split {
  align-items: flex-start;
  display: flex;
}

.card {
  width: 720px;
}

.right {
  display: flex;
  flex-direction: column;
  width: 315px;
}

.fieldset {
  height: 400px;
}

.backgroundColorContainer {
  position: relative;
}

.backgroundColorInput input {
  cursor: pointer;
}

.backgroundColorColorInput {
  bottom: 0;
  left: 0;
  opacity: 0;
  position: absolute;
  z-index: -1;
}

.backgroundImageInput input {
  cursor: pointer;
}

.backgroundImageFileInput {
  display: none;
}

.footer {
  display: flex;
}

.footerButton {
  margin: 0 !important;
}
