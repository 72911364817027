.container {
  bottom: 0;
  left: 0;
  pointer-events: none;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1800; /* below popups */
}

.target {
  opacity: 0;
  position: fixed;
  transition: 0.5s;
}

.target.show {
  opacity: 1;
  transition: opacity 1s;
}

.background {
  border-radius: 5px;
  bottom: 0;
  box-shadow: 0 0 0 max(200vw, 200vh) rgba(0, 0, 0, 0.65);
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.border {
  animation: borderAnimation 2s infinite;
  border: 2px solid var(--yellow);
  border-radius: 5px;
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

@keyframes borderAnimation {
  0% { opacity: 0.25 }
  50% { opacity: 1 }
  100% { opacity: 0.25 }
}

.preventClick {
  pointer-events: all;
  position: absolute;
}

.preventClick.top {
  bottom: 100%;
  height: 100vh;
  left: -100vw;
  width: 200vw;
}

.preventClick.right {
  height: 100%;
  left: 100%;
  top: 0;
  width: 100vw;
}

.preventClick.bottom {
  height: 100vh;
  left: -100vw;
  top: 100%;
  width: 200vw;
}

.preventClick.left {
  height: 100%;
  right: 100%;
  top: 0;
  width: 100vw;
}

.popup {
  border: 1px solid var(--bd-lighter) !important;
  opacity: 0;
  transition: 0.5s;
}

.popup::before {
  border: 1px solid var(--bd-lighter);
  border-left-width: 0;
  border-top-width: 0;
  transform: translateY(1px) rotate(45deg) !important;
}

.popup.show {
  opacity: 1;
  transition: 1s;
}
