.orgGroupUserSummary {
  padding: 10px 5px 0px 5px;
  /* border-radius: 5px;
  border: 1px solid var(--bd-light); */
  border-top: 1px solid var(--bd-light);
  /* border-bottom: 1px solid var(--bd-light); */
}
.orgGroupUserSummary .orgGroupName {
  font-weight: bold;
}
.orgGroupUserSummary .orgGroupUserCount {
  font-weight: bold;
}
.orgGroupUserSummary .orgGroupUserSummaryInfo {
  margin-bottom: 5px;
}
.orgGroupUserSummary ul.orgGroupUsersBreakdown {
  margin: 0;
  padding: 0 0 0px 0px;
  list-style-type: none;
}
.orgGroupUserSummary ul.orgGroupUsersBreakdown li {}
.orgGroupUserSummary ul.orgGroupUsersBreakdown .orgGroupUserBreakdownInfo {
  margin-bottom: 10px;
}

.groupUsers.list ul {
  list-style: none;
  margin: 0 0 0 10px;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  overflow-wrap: break-word;
}
.groupUsers.list li {
  /* display: inline-block; */ /* UPDATE: no longer needed now ul is set to `flex` instead of `inline`? NB: was `inline-flex` - flipped so user avatars with icons align with those without */
  margin: 0px 5px 5px 0px !important;
  padding-bottom: 5px;
}

.orgGroupUserSummary .loader {
  margin: 10px 10px 20px 10px;
}

.cloneLoading {
  margin: 20px 0;
}
.cloneLoading div:last-of-type {
  color: var(--tx-light) !important;
}

.orgGroupUsersImportResult p {
  margin: 0 !important;
}
.orgGroupUsersImportResult ul {
  margin: 0 0 5px 0 !important;
  padding: 0 0 0 20px;
}

.orgGroupUsersImportResult .cloneUserCount {
  font-weight: bold;
}
.orgGroupUsersImportResult .cloneUserWarning {
  font-weight: bold;
}

.orgGroupUsersImportResult .groupUsers.list ul {
  margin: 2px 0 0 5px !important;
}
.orgGroupUsersImportResult .groupUsers.list li {
  padding-bottom: 0px;
}

.hasChanged label,
.hasChanged label .flagTitle span,
.hasChanged :global(.ui.toggle.checkbox input:checked~label),
.hasChanged :global(.ui.toggle.checkbox.checked input:checked~label),
.hasChanged :global(.ui.toggle.checkbox input:focus:checked~label) {
  color: var(--green) !important; /* --orange */
  font-style: italic;
}
.hasChanged :global(.field .fieldsetTitle) {
  color: var(--green) !important; /* --orange */
  font-style: italic;
}
