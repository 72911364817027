.text {
  color: var(--tx-muted);
  font-size: 13px;
  margin-top: 5px;
}

.alignRight {
  text-align: right;
}

.danger {
  color: var(--th-danger);
}
