.header {
  align-items: center;
  display: flex;
  margin-bottom: 10px;
}

.fullscreen {
  margin: 10px;
}

.channelDropdown {
  width: 280px !important;
}

.channelDropdownOption {
  align-items: center;
  display: flex;
}
