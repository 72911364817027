.container {
  height: 100%;
  overflow: hidden;
  pointer-events: none;
  position: relative;
  width: 100%;
  /* background-color: rgba(255, 0, 0, 0.25);
  border: 2px solid red; */
  
}

.active {
  cursor: grab;
  pointer-events: all;
}

.canvas {
  display: none;
  /* background-color: rgba(0, 255, 0, 0.25); */
}

.video {
  /* background-color: rgba(0, 0, 255, 0.25); */
}
