.projectForm {}

.detailsFieldset {
  margin-bottom: 0px;
}

.flagsFieldset {
  padding: 18px 15px 5px 15px; /* reduce the left & right padding to make more room for warning messages */
  margin-bottom: 0px;
}
.flagsFieldset :global(.field .field) {
  padding: 0 0 10px 20px;
}
.flagsFieldset :not(.flagWrapper) + .flagWrapper { /* first flag (wrapper) element only - ref: https://stackoverflow.com/a/13287975 */
  padding-top: 8px !important;
}
.projectForm .flagLabel .flagTitle {
  min-width: 80px;
}
.projectForm .flagLabel .flagTitle span {
  color: #fff; /* override the default toggle label colour change when its enabled (we just force it regardless of its on/off state) */
}
.flagsFieldset .flagWrapper {
  /* border: 1px solid red; */
}
.flagsFieldset .flagHint {
  /* border: 1px solid blue; */
  align-self: flex-start !important;
}

.hasChanged label,
.hasChanged label .flagTitle span {
  color: var(--green) !important; /* --orange */
  font-style: italic;
}

/* video engine dropdown */
.videoEngineDropdown .videoEngineDropdownTrigger {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-right: 32px !important;
}
.videoEngineDropdown .videoEngineDropdownTrigger.videoEngineNone {
  font-style: italic;
}
.videoEngineDropdown .videoEngineOption {
  color: var(--bg-light);
  display: flex;
  justify-content: space-between;
}
.videoEngineDropdown .status.statusEnabled {
  color: var(--green) !important;
}
.videoEngineDropdown .status.statusDisabled {
  color: var(--red) !important;
}
.videoEngineDropdown .videoEngineOption.videoEngineOptionNone {
  font-style: italic;
}

/* video engine placeholder */
.videoEngineGroup {
  justify-content: space-between;
  align-items: center;
}
.videoEngineLabelField {
  margin: 0 0 5px 0 !important;
  padding: 0 !important;
}
.videoEngineLabelField .videoEngineLabel {
  display: flex;
  flex-direction: column;
}
.videoEngineLabelField .videoEngineLabel label {
  padding-right: 5px;
}
