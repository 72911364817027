/**
 * toolbar bottom bar icon
 */

 .toolbarBottomBarIcon {
  background-color: rgba(255, 0, 0, 0.25);
}

.toolbarBottomBarIconGrid {
  display: flex;
  flex-wrap: wrap;
  margin: -1px;
  width: 30px;
  /* background-color: rgba(255, 0, 0, 0.25); */
}

.toolbarBottomBarIconGridItemLarge {
  border: 1.5px solid var(--bg-light);
  border-radius: 1px;
  height: 14px;
  margin: 1px;
  width: 26px;
  margin-left: 2px;

  background-color: var(--bg-light);
}

.toolbarBottomBarIconGridItemSmall {
  border: 1.5px solid var(--bg-light);
  border-radius: 1px;
  height: 6px;
  margin: 1px;
  width: 8px;
}

.toolbarBottomBarIconGridItemSmallActive {
  background-color: var(--bg-light);
}

/**
 * toolbar grid icon
 */

/* .toolbarGridIcon {
  background-color: rgba(255, 0, 0, 0.25);
} */

.toolbarGridIconGrid {
  display: flex;
  flex-wrap: wrap;
  margin: -1px;
  width: 30px;
  /* background-color: rgba(255, 0, 0, 0.25); */
}

.toolbarGridIconGridItem {
  border: 1.5px solid var(--bg-light);
  border-radius: 1px;
  height: 6px;
  margin: 1px;
  width: 8px;
}

.toolbarGridIconGridItemActive {
  background-color: var(--bg-light);
}

/**
 * popup
 */

.popup {
  text-align: center;
}

.buttons * {
  width: 140px;
}

.buttons .button {
}

.buttons.button:active, .buttons .button:focus{
  background-color: var(--th-feature1)!important;
}


/**
 * grid
 */

.gridContainer {
  display: flex;
  justify-content: center;
}

.grid {
  display: flex;
  flex-wrap: wrap;
  margin: -4px;
  width: 174px;
}

.gridItemContainer {
  padding: 4px;
}

.gridItem {
  align-items: center;
  border: 1px solid var(--tx-light-mid);
  border-radius: 3px;
  cursor: pointer;
  display: flex;
  height: 29px;
  justify-content: center;
  width: 50px;
}

.gridItemActive {
  background-color: var(--th-feature1);
}

/* .gridItemAutoActive {
  background-color: var(--green);
} */

.gridItemHover {
  border-color: var(--tx-light);
}

.gridItemText {
  font-size: 12px;
}

/* .gridLabel {} */

.toggle :global(label:before) {
  background: var(--th-ui-actionable) !important;
}
.toggle:global(.ui.toggle.checkbox input:checked~label:before) {
  background-color: var(--th-feature1) !important;
}
.toggle:global(.ui.toggle.checkbox input:checked~label:after) {
  background: white!important;
}
