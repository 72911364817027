.hasChanged label,
.hasChanged label .flagTitle span {
  color: var(--green) !important; /* --orange */
  font-style: italic;
}

.okButton {
  padding-right: 0 !important;
}
.cancelButton {
  padding-left: 0 !important;
}
.deleteButton {
  display: flex;
  justify-content: center;
}