/**
 * ArkForm
 */
.form {
   border: 0px solid red;
}
.form label {
  text-transform: capitalize!important;
}

/**
 * Form Error (form wide error display, not field specific errors)
 */
 .formError :global(.header) {
  margin-bottom: 4px !important;
 }
.formError ul {
  margin: 0 !important;
  list-style-type: none;
}
.formError ul li {
  margin: 0 0 0 0px !important;
  padding: 0 0 2px 0px !important;
}
.formError ul li::before {
  content: none !important;
}

/** 
 * Fieldset
 */
.fieldset {
  position: relative;
  padding: 18px 20px 20px 20px;
  margin: 20px 0 20px 0;
}
.fieldsetBorder {
  border-radius: 5px;
  border: 1px solid var(--bd-light); /* --th-ui-actionable */
}
.fieldsetSlimline {
  padding: 12px 20px 20px 20px;
  margin: 0;
}
.fieldsetTitle {
  position: absolute; /* NB: bring the element out of the DOM flow so it collapses to its actual width & doesn't leave space where it was */
  padding: 0px 10px 0 15px;
  top: -13px; /* position the title over the fieldset border */
  left: 5px;
  background-color: var(--bg-dark); /* mask/hide the fieldset border the title overlaps */
  font-weight: bold;
  font-size: 1.1em !important;
  text-transform: capitalize;
}
.formInModal .fieldsetTitle { /* match the mask to the modal background colour */
  background-color: var(--bg-dark-mid);
}
.fieldset.fieldsetCollapsible > :global(.ui.field) > .fieldsetTitle {
  top: -20px;
  left: 0px;
}
.fieldset.fieldsetCollapsible .fieldsetTitle::after {
  content: "   "; /* NB: add a little space after the title (padding-right doesn't seem to be working) */
  white-space: pre;
}
.fieldset.fieldsetCollapsible .fieldsetTitle :global(.dropdown.icon) {
  margin-right: 2px;
}
.fieldset.fieldsetCollapsible.fieldsetClosed {
  border: none;
  border-top: 1px solid var(--bg-dark);
}
.fieldset.fieldsetWithFieldsGroup { /* reduce bottom padding when a fields/group is the last/only child (enable manually) */
  padding-bottom: 8px;
}

/**
 * Field defauts/overrides
 */
.form:global(.ui.form .fields) {
  margin: 0 0 1em 0; /* SUIR default: `0 -0.5em 1em;` */
}

/**
 * Group
 */
.group.groupSlimline {
  /*margin: 1em -.5em 0em 0 !important;*/
  margin-bottom: 0 !important;
}

/**
 * Button
 */
 .button :global(.button) {
  font-family: var(--th-font-main) !important;
  font-weight: normal !important;
  /* margin: 0 !important; */
  text-transform: uppercase !important;
  transition: background-color 0.3s ease, color 0.3s ease, opacity 0.3s ease !important;
 }
.button.defaultColor :global(.button) {
  background-color: var(--th-ui-actionable) !important;
  color: var(--tx-light) !important;
}
.button.defaultColor :global(.button):hover {
  background-color: var(--th-ui-actionable-hover) !important;
  color: var(--ui-hover) !important;
  /* color: var(--tx-light) !important;
  opacity: 0.875; */
}
.button:global(.ui.basic.blue.button, .ui.basic.blue.buttons .button) {
  text-transform: uppercase !important;
}
.button:global(.field.disabled) {
  opacity: 1 !important;
  pointer-events: initial !important;
}
.button:global(.field.disabled button.disabled) {
  opacity: 0.6 !important;
  pointer-events: initial !important;
  cursor: not-allowed;
}
.button:global(.field.disabled button.disabled):hover {
  border: 0px solid red !important;
}

/**
 * Radio
 */
.radioGroup {
  padding-top: 4px;
}
.radioGroup label {
  color: rgba(255,255,255,.9);
}
.radioGroup :global(.radio.checkbox) {
  border: 0px solid red;
}
.radioGroup :global(.ui.radio.checkbox label:before) {
  background-color: var(--bg-dark) !important;
  border: 1px solid var(--bg-light) !important;
}
.radioGroup :global(.ui.radio.checkbox label:after) {
  background-color: var(--th-feature1) !important;
}
/* NB: when a radioGroup is within a fieldset reduce its bottom margin */
/* TODO: should this only be done if its the last item within a fieldset? */
.fieldset .radioGroup {
  margin-bottom: 0px !important;
}

/**
 * Toggle (radio with toggle mode enabled)
 */
 .radioToggle :global(.ui.toggle.checkbox input:checked~label),
 .radioToggle :global(.ui.toggle.checkbox input:focus:checked~label) {
  color: var(--tx-light) !important;
 }
.radioToggle :global(.ui.toggle.checkbox label:before) {
  background: rgba(255, 255, 255, .20);
}
.radioToggle :global(.ui.toggle.checkbox input:focus~label:before) {
  background: rgba(255, 255, 255, .20);
}
.radioToggle :global(.ui.toggle.checkbox label:hover::before) {
  background: rgba(255, 255, 255, .20);
}
 
/**
 * Checkbox
 */
.checkbox {
  padding-left: 4px;
}

/**
 * Dropdown
 * NB: slightly different structure to ArkDropdown as its wrapped in a field & contains a label etc.
 * NB: so we use :global(.dropdown) to get to the actual SUI dropdown element
 */
 .dropdownField :global(.dropdown) {
  background-color: var(--th-ui-actionable) !important;
  border: 0 !important;
  color: var(--tx-light) !important;
}
.dropdownField > :global(.dropdown) {
  min-height: 41px !important;
  padding: 10px 0 10px 12px !important;
  display: flex !important;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  /* border: 1px solid red !important; */
}

/* styles the placeholder text */
.dropdownField :global(.dropdown > .text.default) {
  font-style: italic !important;
}

/* WORK-AROUND: hide the active item in the main/base area when the dropdown is open with no value currently set for it (SUIR shows the default one instead of the placeholder which is really flipping confusing) */
.dropdownField:not(.hasValue) :global(.dropdown.active > .text) {
  display: none;
}

.dropdownField :global(.dropdown .menu) {
  background-color: var(--th-ui-actionable) !important;
  border: 0 !important;
}

.dropdownField :global(.dropdown .item) {
  border-color: var(--bg-dark-mid) !important;
}

.dropdownField :global(.dropdown .item.selected .text)  {
  color: var(--ui-selected) !important;    
}

.dropdownField :global(.dropdown .item:hover .text)  {
  color: var(--ui-hover) !important;
}

.dropdownField :global(.dropdown .text:not(.default))  {
  color: var(--bg-light) !important;
}

/* TESTING: dropdown custom children elements (of any type) instead of only the normal default text values (disabled selection mode to enable trigger mode usage instead) */
.dropdownField div:global(.dropdown:not(.selection)) {
  padding: 10px;
  border-radius: 6px;
  position: relative;
}
.dropdownField div:global(.dropdown:not(.selection) > div:first-child) {
  margin-right: 30px;
}
.dropdownField div:global(.dropdown:not(.selection):not(.loading) i.icon) {
  /* float: right !important; */
  position: absolute;
  top: 0px;
  right: 0px;
  height: 100%;
  padding: 5px 15px;
  border-radius: 6px;
  /* border: 1px solid red !important; */
  display: flex !important;
  flex-direction: column;
  justify-content: center;
}
.dropdownField div:global(.dropdown:not(.selection) .menu.visible) {
  width: 100%;
}

/* dropdown loading spinning icon once content has loaded, or maybe item is selected? (doesn't seem to kick in if the dropdown just has a default entry only) */
/* NB: work-around: the dropdown spinning loading indicator seems to be mis-aligned once content has loaded, so adjusting to realign */
.dropdownField div:global(.dropdown:not(.selection).dropdown.loading i.icon) {
  margin-top: 4px;
  margin-right: 8px;
}
.dropdownField div:global(.dropdown:not(.selection).dropdown.loading i.icon::before) {}

/* remove/override the SUIR error theming on the dropdown, it looks aweful with our dark colour scheme even when inverted is enabled on it */
.dropdownField:global(.error) {
  /* border: 1px solid red !important; */
}
.dropdownField:global(.field.error) :global(.ui.dropdown) {
  /* background-color: var(--th-ui-actionable) !important;
  border: 1px solid red !important;
  color: var(--tx-light) !important; */
}
.dropdownField:global(.field.error) :global(.ui.dropdown > .text.default)  {
  background-color: var(--th-ui-actionable) !important;
}
.dropdownField:global(.field.error) :global(.ui.dropdown .menu) {
  background-color: var(--th-ui-actionable) !important;
  border: 0 !important;
}
.dropdownField:global(.field.error) :global(.ui.dropdown .item) {
  border-color: var(--bg-dark-mid) !important;
  background-color: var(--th-ui-actionable) !important;
}
.dropdownField:global(.field.error) :global(.ui.dropdown .item .text)  {
  background-color: inherit !important;
}

/**
 * custom field wrapper - when adding any extra to a field like description and/or hint etc.
 */
.fieldWrapper {
}
.fieldWrapper .fieldElement {
}
.fieldWrapper .fieldDesc {
  font-size: 0.9em;
  padding: 4px 0 0 4px;
}
.fieldWrapper .fieldHintWrapper {
  display: flex;
}
/* .fieldWrapper .fieldHintWrapper.fieldHintInline {} */
.fieldWrapper .fieldHintWrapper:not(.fieldHintInline) .fieldElement {
  flex-grow: 1; /* expand the field content to fill the available width, leave the hint to be as small as needed/possible */
}
.fieldWrapper .fieldHintWrapper .fieldHint {
  align-self: flex-end;
  padding-left: 8px;
}

/**
 * data-tooltip (NB: not form specific, but we currently only use it in forms)
 */
:global([data-tooltip]:hover:after), :global([data-tooltip]:hover:before) {
  transition: all 0.2s ease-in-out;
  transition-delay: 0.5;
  trans: 1!important;
}

/**
 * ORIGINAL SUI FORM OVERRIDES (from App.css)
 */
 .form:global(.ui.form.inverted input[type=text]),
 .form:global(.ui.form.inverted input:not([type])) {
  font-family: var(--th-font-main);
}
.form:global(.ui.form.inverted input:not([type])),
.form:global(.ui.form.inverted input[type=date]),
.form:global(.ui.form.inverted input[type=datetime-local]),
.form:global(.ui.form.inverted input[type=email]),
.form:global(.ui.form.inverted input[type=file]),
.form:global(.ui.form.inverted input[type=number]),
.form:global(.ui.form.inverted input[type=password]),
.form:global(.ui.form.inverted input[type=search]),
.form:global(.ui.form.inverted input[type=tel]),
.form:global(.ui.form.inverted input[type=text]),
.form:global(.ui.form.inverted input[type=time]),
.form:global(.ui.form.inverted input[type=url]) {
  color:var(--tx-light);
  background-color: var(--bg-dark);
}
.form:global(.ui.form .field textarea) {
  color:var(--tx-light);
  background-color: var(--bg-dark);
}