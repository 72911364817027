.serverhubServerForm {}

.serverhubServerForm .detailsUUIDGroup {
  margin-bottom: 10px !important;
}
.serverhubServerForm .detailsUUIDGroup .uuidGenerateBtn {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
.serverhubServerForm .detailsUUIDGroup .uuidGenerateBtn.uuidGenerateBtnHidden {
  display: none;
}

.detailsAndSettingsGroup {
  padding: 0px !important;
  margin: 0px !important;
}

.detailsFieldset {
  margin: 0px;
  margin-top: 5px;
  margin-bottom: 20px;
  padding: 15px 12px 10px 12px;
}

.keysFieldset {
  margin-top: 10px;
  padding: 10px 12px 10px 12px;
}
.keysFieldset .keysPremiumComputeFieldset {
  margin-top: 10px;
  padding: 10px 12px 10px 12px;
}
.keysFieldset .keysPremiumPlusFieldset {
  padding: 10px 12px 10px 12px;
}
.keysFieldset .keysS3FSFieldset {
  padding: 10px 12px 10px 12px;
}
.keysFieldset .keysHeartbeatFieldset {
  margin-bottom: 4px;
  padding: 10px 12px 10px 12px;
}

.configFieldset {
  margin-top: 20px;
  padding: 10px 12px 10px 12px;
}
.configFieldset .configSSLFieldset {
  margin-top: 10px;
  padding: 10px 12px 0px 12px;
}
.configFieldset .configSSLFieldset .configSSLPortsFieldset {
  margin-top: 0px;
  padding: 0px 10px 10px 10px !important;
}
.configFieldset .configSSLFieldset .configSSLCertsFieldset {
  margin-top: 0px;
  margin-bottom: 12px;
  padding: 0px 10px 10px 10px !important;
}
.configFieldset .configOptionsFieldset {
  margin-top: 10px;
  padding: 0px 12px 10px 12px !important;
}
.configFieldset .configRemoteFieldset {
  padding: 0px 12px 10px 12px !important;
}
.configFieldset .configSystemNetworkFieldset {
  padding: 0px 12px 10px 12px !important;
  margin-bottom: 4px;
}

.packagesFieldset {
  /* padding-bottom: 0px; */
  /* margin-bottom: 20px; */
  margin-top: 10px;
  padding: 10px 12px 10px 12px;
}

.driversFieldset {
  margin-top: 10px;
  padding: 10px 12px 0px 12px !important;
}
.driversFieldset .driversGPUFieldset {
  margin-top: 10px;
  margin-bottom: 12px;
  padding: 0px 12px 0px 12px !important;
}
.driversFieldset .driversGPUFieldset .driversGPUNVidiaFieldset {
  margin-top: 10px;
  padding: 0px 10px 10px 10px !important;
}
.driversFieldset .driversGPUFieldset .driversGPUCudaFieldset {
  margin-top: 10px;
  margin-bottom: 12px;
  padding: 0px 10px 10px 10px !important;
}

.cronLogField {
  margin-bottom: 8px !important;
}
.cronLogField :global(.ui.checkbox.toggle) {
  /* border: 1px solid red;
  display: flex !important;
  flex-direction: column-reverse !important; */
}
.cronLogField input {
  /* border: 1px solid blue !important; */
}

.authFieldset {
  margin-top: 10px;
  padding: 10px 12px 0px 12px !important;
}
.authFieldset .authApiIPsFieldset {
  margin-top: 10px;
  margin-bottom: 12px;
  padding: 0px 12px 10px 12px !important;
}

.inputKeyValueFields {}
.inputKeyValueFields .inputKeyValueBtnGroup {
  margin: 8px 0 3px 1px !important;
}
.inputKeyValueFields .inputKeyValueBtnGroup .inputKeyValueFieldsAddBtn {}
.inputKeyValueFields .inputKeyValueBtnGroup .inputKeyValueFieldsInfo {
  text-align: right;
  margin-right: 5px;
  color: var(--green);
}
.inputKeyValueFields .inputKeyValueGroup {}
.inputKeyValueFields .inputKeyValueGroup {
  margin: 0 0 5px 0 !important;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-end;
}
.inputKeyValueFields .inputKeyValueGroup .inputKeyValueRemoveBtn {
  margin-bottom: 6px !important;
}
.inputKeyValueFields .inputKeyValueGroup .hasChanged input {
  border: 1px solid var(--green) !important;
}
.inputKeyValueFields .inputKeyValueGroup .hasChanged label {
  /* reverse the default `hasChanged` label styling for `.inputKeyValueGroup` as we only show the label for the first row & don't want changes to only that row to change the column label */
  color: inherit !important;
  font-style: inherit !important;
}

.inputMultiValueFields {
  padding-top: 10px;
  padding: 10px 10px 10px 10px !important;
  margin-bottom: 20px;
}
.inputMultiValueFields .inputMultiValueValue {}
.inputMultiValueFields .inputMultiValueBtnGroup {
  margin: 8px 0 3px 1px !important;
}
.inputMultiValueFields .inputMultiValueBtnGroup .inputMultiValueFieldsAddBtn {}
.inputMultiValueFields .inputMultiValueBtnGroup .inputMultiValueFieldsInfo {
  text-align: right;
  margin-right: 5px;
  color: var(--green);
}
.inputMultiValueFields .inputMultiValueGroup {}
.inputMultiValueFields .inputMultiValueGroup {
  margin: 0 0 5px 0 !important;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-end;
}
.inputMultiValueFields .inputMultiValueGroup .inputMultiValueRemoveBtn {
  margin-bottom: 6px !important;
}
.inputMultiValueFields .inputMultiValueGroup .hasChanged input {
  border: 1px solid var(--green) !important;
}
.inputMultiValueFields .inputMultiValueGroup .hasChanged label {
  /* reverse the default `hasChanged` label styling for `.inputKeyValueGroup` as we only show the label for the first row & don't want changes to only that row to change the column label */
  color: inherit !important;
  font-style: inherit !important;
}

.hasChanged label,
.hasChanged label .flagTitle span,
.hasChanged :global(.ui.checkbox.toggle) label {
  color: var(--green) !important; /* --orange */
  font-style: italic;
}