/* FIXME remove spacing in <RightSidebar> */

.container {
  bottom: 0;
  display: flex;
  flex-direction: column;
  left: 0;
  position: absolute;
  right: 2px;
  top: 1px;
}


.body {
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow-y: hidden;
  position: relative;
}

.main {
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow-y: auto;
  padding: 15px;
}

.labelButton {
  cursor: pointer;
  text-decoration: underline;
  transition: 0.5s;
}

.labelButton:hover {
  opacity: 0.65;
}

.labelButton:active {
  opacity: 0.825;
  transition: 0.1s;
}

.fieldset {
  border: 1px solid var(--bd-mid);
  border-radius: 5px;
  margin: 0;
  padding: 15px;
}

.footer {
  padding: 0 15px 15px 15px;
}

.loading {
  background-color: rgba(0, 0, 0, 0.35);
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}
