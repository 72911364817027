.mainContainer {
  display: flex;
  flex: 1;
  flex-direction: column;
  position: relative;
  /* background-color: rgba(255, 0, 0, 0.25); */
}

.topContainerOuter {
  flex: 1;
  position: relative;
  /* background-color: rgba(255, 0, 0, 0.25); */
}

.topContainerInner {
  align-items: center;
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  /* background-color: rgba(0, 255, 0, 0.25); */
}

/* .topPositioner {
  background-color: rgba(255, 0, 0, 0.25);
  border: 1px solid red;
  pointer-events: none;
  z-index: 1000;
} */

/* .bottomContainerOuter {
  background-color: rgba(255, 0, 0, 0.25);
} */

.bottomContainerInner {
  display: flex;
  justify-content: center;
  margin: -10px;
  padding: 5px;
  /* background-color: rgba(0, 255, 0, 0.25); */
}

.bottomPositioner {
  display: flex;
  margin: 5px;
  /* background-color: rgba(255, 0, 0, 0.25);
  border: 1px solid red;
  z-index: 1000; */
}

.item {
  display: flex;
  position: absolute;
  /* background-color: rgba(255, 0, 0, 0.25);
  border: 1px solid green; */
}

.itemHidden {
  height: 0 !important;
  overflow: hidden;
  position: absolute;
  width: 0 !important;
}

.active {
  align-items: center;
  border: 2px solid var(--th-feature1);
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  line-height: 1.5;
  padding: 10px;
  position: absolute;
  text-align: center;
}

.activeAutoSolo {
  border-color: var(--green);
  /* border-width: 2px; */
}

.activeSmall {
  font-size: 10px;
  padding: 5px;
}

.activeName {
  overflow: hidden;
  text-overflow: ellipsis;
  user-select: none;
  white-space: nowrap;
  width: 100%;
}

.activeLabel {
  color: var(--tx-light-mid);
  user-select: none;
}

.pagination {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}
