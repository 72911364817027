.numberInput {}

.numberInput.darkMode input {
  color: var(--tx-light) !important;
  background-color: var(--bg-dark) !important;
  border-top: 1px solid var(--tx-light) !important;
  border-bottom: 1px solid var(--tx-light) !important;
}
.numberInput.darkMode input::selection { /* text selection */
  color: var(--tx-light) !important;
  background-color: var(--bd-lighter) !important;
}
.numberInput.darkMode button {
  color: var(--tx-light) !important;
  background-color: var(--th-ui-actionable) !important;
  border: 1px solid var(--tx-light);
}
