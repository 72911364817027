.button {
  align-items: center;
  display: flex;
  flex-shrink: 0;
  height: 50px;
  overflow-x: hidden;
  transition: 0.5s;
  user-select: none;
}

.button:not(.disabled) {
  cursor: pointer;
}

.button:not(.disabled):hover {
  opacity: 0.65;
}

.button:not(.disabled):active {
  opacity: 0.825;
  transition: 0.1s;
}

.button.active {
  background-color: var(--bg-selected);
}

.button.disabled {
  opacity: 0.35;
}

.iconContainer {
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  transition: 0.5s;
  width: 50px;
}

.compact .iconContainer {
  width: var(--left-sidebar-compact-width);
}

.title {
  font-size: 12px;
  text-transform: uppercase;
  transition: 0.5s;
  white-space: nowrap;
}

.compact .title {
  opacity: 0;
}
