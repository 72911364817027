
.basic2FAInput {
  font-size: 1.5em;
  /* letter-spacing: 2em; */
  /* box-sizing: border-box; */
}

.split2FAContainer {
  display: flex;
  justify-content: space-between;
  padding: 2px 16px 18px 16px;
  max-width: 400px;
  margin: 0 auto;
}
.split2FAInput {
  width: 2ch !important;
  padding: 8px !important;
  border-radius: 8px !important;
  /* font-size: 40px; */
  font-size: 3em !important;
  text-align: center !important;
  /* margin-right: 12px; */
  border: 1px solid white !important;
  text-transform: uppercase !important;
}
.split2FAInput:nth-child(3) {
  margin-right: 12px !important;
}

@media screen and (max-width: 400px) {
  .split2FAInput {
    width: 1.9ch !important;
    height: 2.5ch !important;
    font-size: 2.5em !important;
  }
}
@media screen and (max-width: 350px) {
  .split2FAInput {
    width: 1.9ch !important;
    height: 2.4ch !important;
    font-size: 2.0em !important;
  }
}
