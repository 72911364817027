.itemsTable {
  border-collapse: separate;
  border-spacing: 0 0 !important;
  background-color: transparent !important;
}
.itemsTable tbody {
  margin: auto;
}
.itemsTable td {
  border: none !important;
  border-style: solid none;
  line-height: 1.5em;
  padding: 0 !important;
}
.itemsTable td.itemCheckbox {
  width: 120px;
  padding-right: 20px !important;
  text-align: right !important;
}
.itemsTable td.itemTitle {
  padding: 0px 5px 5px 5px !important;
}

.arkFormSubmitButtons {
  display: flex;
  margin: 2em 1.5em 1em;
}

.msg,
.msg:global(.ui.message.positive),
.msg:global(.ui.message.negative) {
  margin-left: 10px !important;
  margin-right: 10px !important;
  margin-bottom: 10px !important;
}

.noItems {
  text-align: center;
  padding: 10px 10px 0px 10px;
}


.filterForm {
  padding: 0px 10px 5px 40px;
  margin-bottom: 5px;
}
.filterHeader {
  padding: 5px 10px 5px 40px;
  margin-bottom: 10px;
  background-color: var(--blue);
}
.filterFooter {
  padding: 5px 10px 5px 40px;
  background-color: var(--blue);
}
.filterClear {
  cursor: pointer;
}
.filterClear:hover {
  text-decoration: underline;
}