.container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

/**
 * header
 */

.header {
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 8; /* above right sidebar */
}

.mobile .header {
  z-index: 4; /* above left sidebar */
}

.menuButton {
  height: var(--header-height);
  left: 0;
  position: fixed;
  top: 0;
  width: var(--header-height);
  z-index: 6; /* above left sidebar */
}

/**
 * main
 */

.mainContainer {
  display: flex;
  flex: 1;
}

.main {
  flex: 1;
  margin: var(--header-height) 0 var(--footer-height) 0;
  min-width: 0; /* fix ellipsis */
  overflow-x: hidden;
  padding: 10px;
}

/**
 * footer
 */

.footer {
  align-items: center;
  background-color: var(--bg-black);
  border-top: 1px solid var(--th-ui-actionable);
  bottom: 0;
  color: var(--tx-light-mid);
  display: flex;
  height: var(--footer-height);
  justify-content: flex-end;
  left: 0;
  padding: 0 10px;
  position: fixed;
  right: 0;
  user-select: none;
  z-index: 2; /* above checkboxes */
}

.version {
  user-select: none;
}

.poweredBy {
  align-items: center;
  display: flex;
}

.poweredByText {
  color: var(--tx-muted);
}

.poweredByLogo {
  height: 20px;
  border: 0px solid green;
}
