.divider {
  height: 1px !important;
  border: 0px solid red;
  color: var(--bd-light) !important; /* --tx-light */
  width: 100%;
}

/** 
 * NB: for some reason the horizontal divider alignment is at the bottom despite being set to 'top 50%' normally 
 * NB: so we override it to 0% which seems to fix it for now, is some old/original Ark.css overriding the defaults, can we remove both if so?
 * UPDATE: no longer needed?
 */
/* .divider:before {
  background-position: right 1em bottom 50% !important;
}
.divider:after {
  background-position: left 1em bottom 50% !important;
} */

/* TESTING: remove the padding horizontal padding/spacing when empty (or you get a small gap in the middle where the content/text usually would go when not empty) */
.divider.empty:before {
  background-position: right 0em bottom 50% !important;
}
.divider.empty:after {
  background-position: left 0em bottom 50% !important;
}
