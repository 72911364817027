:root {
  --streamhubHeaderTop: calc(var(--header-height) + 1px);
  --streamhubHeaderHeight: 105px;
  --streamhubStreamsHeaderHeight: 36px;
}
:root .streams {
  /* NB: only the streams page currently has a filters header row that we account for when setting heights/offsets for sticky table headers */
  --streamhubStreamsHeaderHeight: 80px; /* 76px */
}
:root .videoEngines {
  --streamhubStreamsHeaderHeight: 72px; /* 76px */
}

/**
 * Server Select Page (when multi-server is enabled)
 */
.selectServer {
  margin-top: 10px;
}


/**
 * Base Page (common across all streamhub section pages)
 */
.streamhubPage {
  position: relative;
}
.streamhubPage .streamhubHeader {
  position: sticky;
  top: var(--streamhubHeaderTop);
  height: var(--streamhubHeaderHeight);
  z-index: 5;
  background-color: var(--bg-dark); /* stop content showing when scrolled behind */
}
.streamhubPage .streamhubContent {}


/**
 * Base Page Header
 */
.streamhubPage .streamhubHeader {}
.streamhubPage .streamhubHeader .currentServerHeader {
  padding-top: 10px;
}
.streamhubPage .streamhubHeader .currentServerHeader .border {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  margin: 0px 0 10px 0;
  border: 1px solid #fff;
  border-radius: 5px;
  padding: 2px 10px;
}
.streamhubPage .streamhubHeader .currentServerHeader .border .leftMenu {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  gap: 10px;
}
.streamhubPage .streamhubHeader .currentServerHeader .border .rightMenu {
}
.streamhubPage .streamhubHeader .currentServerHeader .currentServerName {
  font-size: 1.1em;
  display: flex;
  flex-direction: row;
  gap: 5px;
}
.streamhubPage .streamhubHeader .currentServerHeader .currentServerName .currentServerTitle {
  font-weight: bold;
}
.streamhubPage .streamhubHeader .currentServerHeader .currentServerName .currentServerValue {

}
.streamhubPage .streamhubHeader .currentServerHeader .currentServerClose {
  margin-left: 10px;
  padding: 5px 8px !important;
}
.streamhubPage .streamhubHeader .currentServerHeader.socketConnected .border {
  border-color: green;
}
.streamhubPage .streamhubHeader .currentServerHeader.socketNoConnection .border {
  border-color: orange;
}

.streamhubPage .streamhubHeader .currentServerHeader .currentServerSocket {
  min-width: 50px;
  min-height: 30px;
  display: flex;
  align-items: center;
  /* border: 1px solid red; */
}
.streamhubPage .streamhubHeader .currentServerHeader .currentServerSocket .statusTitle {
  font-weight: bold;
  padding-right: 5px;
}
.streamhubPage .streamhubHeader .currentServerHeader .currentServerSocket .statusValue {
  margin-right: 5px;
}
.streamhubPage .streamhubHeader .currentServerHeader .currentServerSocket.socketConnecting .statusValue {
  color: orange;
}
.streamhubPage .streamhubHeader .currentServerHeader .currentServerSocket.socketConnected .statusValue {
  color: green;
}
.streamhubPage .streamhubHeader .currentServerHeader .currentServerSocket.socketDisconnected .statusValue {
  color: red;
}
.streamhubPage .streamhubHeader .currentServerHeader .currentServerSocket.socketError .statusValue {
  color: red;
}

.streamhubPage .streamhubHeader .currentServerHeader .socketConnect,
.streamhubPage .streamhubHeader .currentServerHeader .socketDisconnect,
.streamhubPage .streamhubHeader .currentServerHeader .socketRefresh {
  padding: 5px 10px !important;
}

.streamhubPage.mobile .streamhubHeader {
  position: relative; /* disable sticky headers on mobile */
  top: auto;
  height: auto;
  z-index: auto;
}
.streamhubPage.mobile .streamhubHeader .currentServerHeader {
  font-size: 0.8em;
}
.streamhubPage.mobile .streamhubHeader .currentServerHeader .border .leftMenu {
  flex-direction: column;
  align-items: flex-start;
  gap: 2px;
  font-size: 0.9em;
}

/**
 * Page Specific sub-headers
 */
 .streamhubPage .contentHeader {
  position: sticky;
  top: calc(var(--streamhubHeaderHeight) + var(--header-height));
  z-index: 5;
  margin-bottom: 4px;
  height: var(--streamhubStreamsHeaderHeight);
  background-color: var(--bg-dark); /* stop content showing when scrolled behind */
}
.streamhubPage .contentHeader .contentHeaderMain {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}
.streamhubPage .contentHeader h2 {
  margin: 0;
}
.streamhubPage .contentHeader .actions {
  display: flex;
  flex-direction: row;
  gap: 5px;
  max-height: 30px;
}
.streamhubPage .contentHeader .actions button {
  font-size: 0.85em;
  font-weight: bold !important;
}
.streamhubPage .contentHeader .actions .divider {
  border-left: 1px solid #fff;
}
.streamhubPage .contentHeader .listSummary {
  text-align: center;
  flex-grow: 1;
}

.streamhubPage.mobile .contentHeader {
  position: relative; /* disable sticky headers on mobile */
  top: auto;
  height: auto;
  z-index: auto;
}
.streamhubPage.mobile .contentHeader .contentHeaderMain {
  flex-direction: column;
  align-items: flex-start;
}
.streamhubPage.mobile .contentHeader .actions {
  font-size: 0.8em;
}


.streamhubPage .contentHeaderFilter {
  margin: 8px 0 0 0;
}
.streamhubPage .contentHeaderFilter :global(.fields>.field) {
  padding: 0 !important;
}
.streamhubPage .contentHeaderFilter :global(.ui.button) {
  margin-right: 0 !important;
}

/**
 * Streamhub Tabs
 */
.tabs {
}
.tabs :global(.item) {
  background-color: var(--th-ui-actionable) !important;
  border-radius: 6px 6px 0 0 !important;
  margin-right: 2px;
}
.tabs :global(.ui.tabular.menu .item.active) {
  background-color: var(--blue) !important;
  border-radius: 6px 6px 0 0 !important;
}
.tabs :global(.item):hover {
  color: var(--tx-light) !important;
  background-color: var(--th-ui-actionable-hover) !important;
  opacity: 1.0 !important;
}

/**
 * Streamhub Tables
 * NB: this is currently overriding a load of SUI Table default css, 
 *     most of which seems to require !important adding to each flipping line :-/ 
 * UPDATE: starting to migrate away from SUI Table to using TanStack Table (React Table) v8
 *         re-using the existing css, with some minor tweaks
 * TODO: once all streamhub tables are migrated to TanStack Table, remove the SUI Table specific overrides
 */
 .streamhubPage .table { 
  width: 100%;
  border-collapse: separate !important;
  border-spacing: 0 10px !important; 
  margin-top: -10px !important; /* correct offset on first border spacing if desired */
  background-color: transparent !important;
}
.streamhubPage .table th {
  background-color: var(--bg-dark-mid) !important;
  color: rgba(255,255,255,.9)!important;
}
.streamhubPage .table thead th {
  padding: 10px 4px !important;
  border: none !important;
  text-align: left;
}
/* .streamhubPage .table tr {
  border-color: rgba(255,255,255,.1) !important;
} */
.streamhubPage .table td {
  /* border: solid 1px #000 !important; */
  border: solid 1px rgba(255,255,255,.1) !important;
  border-style: solid none !important; 
  line-height: 1.5em !important;
  padding: 10px 4px !important;
  background-color: var(--bg-dark) !important;
}
.streamhubPage .table td.collapsing {
  border-left: none !important;
}
.streamhubPage .table td:first-child {
  /* border-left-color: var(--bg-light) !important; */
  border-left-style: solid !important;
  border-top-left-radius: 6px !important;
  border-bottom-left-radius: 6px !important;
  padding-left: 10px !important;
}
.streamhubPage .table td:last-child {
  border-right-style: solid !important;
  border-bottom-right-radius: 6px !important;
  border-top-right-radius: 6px !important;
  padding-right: 10px !important;
}
.streamhubPage .table tr.active {
  background-color: transparent !important;
  color: #fff !important; /* NB: not kicking in, see the .ui.table override below to override the sui's annoying imternal !important usage */
}
.streamhubPage .table tr.active td {
  border-color: var(--ui-selected) !important;
  background-color: var(--bg-selected) !important; /* var(--bg-pg-content) !important; */
}
.streamhubPage .table:global(.ui.table tr.active) {
  color: #fff !important;
}
.streamhubPage .table tr:hover td {
  /* border-color: var(--ui-hover) !important; */
  background-color: var(--bg-hover) !important;
  /* color: var(--ui-hover) !important; */
}
.streamhubPage .table tr:hover.active td {
  /* border-color: blue !important; */
  /* background-color: red; */
  border-color: var(--ui-selected) !important;
}
.streamhubPage .table td.tableActions {
}


/**
 * Streams
 */
.streams {
  position: relative;
  padding-top: 10px;
}

.streams .startStopButtonWrapper {
  position: relative;
}

.streams .startStopButtonWrapper .buttonError {
  background-color: var(--orange) !important;
}

.streams .startStopButtonWrapper .startError {
  position: absolute;
  font-size: 0.8em;
  font-weight: bold;
  line-height: 1.0em;
  padding: 4px 0 0 0;
  text-align: center;
  width: 100%;
  color: var(--orange);
}
.streams .startStopButtonWrapper .startError .startErrorMsg {
  visibility: hidden;
  opacity: 0;
  position: absolute;
  top: -20px;
  right: 60px;
  min-width: 200px;
  max-width: 400px;
  padding: 10px;
  font-size: 1.2em;
  line-height: 1.1em;
  z-index: 100;
  background-color: #333;
  border-radius: 5px;
  border: 1px solid var(--th-ui-actionable);
  transition: opacity 0.25s linear;
}
.streams .startStopButtonWrapper .startError:hover .startErrorMsg {
  visibility: visible;
  opacity: 1;
}
.streams .restartButton {
  margin-right: 8px !important;
}

.streams .buttonLeftPad {
  margin-left: 8px !important;
}

.streams .actionButtons {
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-end;
  align-items: flex-start;
}


/**
 * NB: streams table - tr AND td need `height: 100%` (or a variation of) for div within a td cell to fill the height!
 * ref: https://stackoverflow.com/a/49797662
 */
 .streams table.streamsTable {
  height: 100%;
}
.streams tr.streamRow {
  /* height: 100%; */
}

.streams table.streamsTable thead {
  position: sticky;
  top: calc(var(--streamhubHeaderHeight) + var(--header-height) + var(--streamhubStreamsHeaderHeight));
  z-index: 4;
  background-color: var(--bg-dark); /* stop content showing when scrolled behind */
}
.streams table.streamsTable th.streamSelect {
  padding: 5px 10px 0 10px !important;
}

.streams td.streamIdAndImg {
  width: 110px;
}
.streams td.streamIdAndImg .idRow {
  font-size: 0.8em;
  font-weight: bold;
  line-height: 1.6em;
  padding: 2px 2px 2px 5px;
  background-color: var(--bg-dark-mid);
  text-align: center;
}
.streams td.streamIdAndImg .idRow.idStream {
  font-size: 0.9em;
}
.streams td.streamIdAndImg .idRow.idSource {
  /* font-weight: normal; */
}
.streams td.streamDetailsCell {
  height: 100%;
}
.streams .streamDetails {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start; 
  height: 100%; /* NB: parent table, tr & td need heights set for this to work! */
}
.streams .streamDetails .streamName {
  font-weight: bold;
}
.streams .streamDetails .detailsRow {
  display: flex;
  flex-direction: row;
  font-size: 0.8em;
  line-height: 1.2em;
}
.streams .streamDetails .detailsRow .detailsTitle {
  font-weight: bold;
  text-align: right;
  margin-right: 5px;
  min-width: 50px;
}
.streams .streamDetails .detailsRow .detailsValue {}

.streams .statusRow {
  display: flex;
  flex-direction: row;
  font-size: 1em;
  line-height: 1.2em;
}
.streams .statusRow .statusTitle {
  font-weight: bold;
  text-align: right;
  margin-right: 5px;
  min-width: 60px;
}
.streams .statusRow .statusValue {
}

.streams .streamUrl {
}

.streams .streamTags {
  display: flex;
  gap: 5px;
  margin-top: 5px;
}
.streams .streamTags .streamTag {
  padding: 2px 5px;
  border: 1px solid var(--bg-dark);
  border-radius: 5px;
  background-color: var(--th-ui-actionable);
  color: var(--tx-light);

}

.streams.mobile {
  /* border: 1px solid red; */
}
.streams.mobile table.streamsTable thead {
  position: relative; /* disable sticky headers on mobile */
  top: auto;
}


/**
 * Sources
 */
.sources {
  position: relative;
  padding-top: 10px;
  /* border: 1px solid red; */
}
.sources td.sourcePreview {
  width: 200px;
  vertical-align: top;
}
.sources td.sourceDetails {
  vertical-align: top;
}
.sources td.sourceDetails .propertyRow {
  align-items: flex-start;
}
.sources td.sourceDetails .propertyValue {
  text-transform: none;
}
.sources td.sourceDetails .title {
  font-weight: bold;
  padding-right: 5px;
}
.sources td.sourceDetails .value {
  padding-right: 5px;
}
.sources td.sourceDetails pre {
  margin: 0;
  text-wrap: pretty;
}
.sources td.sourceDetails .overlayValue pre {
  /* border: 1px solid red; */
  max-width: 40%; /* TODO: get this to wrap when it maxes the available space in the parent without having to set a specific max-width? */
  white-space: pre-wrap;
  word-wrap: break-word;
}


/**
 * Processes
 */
.processes {
  position: relative;
  padding-top: 10px;
  /* border: 1px solid red; */
}
.processes .processTitle {
  font-weight: bold;
  padding-right: 5px;
}
.processes .processValue {}

.processes td.streamIdAndImg {
  width: 110px;
  vertical-align: top;
}
.processes td.streamIdAndImg .idRow {
  font-size: 0.8em;
  font-weight: bold;
  line-height: 1.6em;
  padding: 2px 2px 2px 5px;
  background-color: var(--bg-dark-mid);
  text-align: center;
}
.processes td.streamIdAndImg .idRow.idStream {
  font-size: 0.9em;
}
.processes td.streamIdAndImg .idRow.idSource {
  /* font-weight: normal; */
}
.processes td.pid {
  width: 50px;
  text-align: center;
  vertical-align: top;
}
.processes td.cpu {
  width: 40px;
  text-align: center;
  vertical-align: top;
}
.processes td.cmd {
  /* width: 200px; */
}
.processes td.cmd .ids .processValue {
  padding-right: 10px;
}
.processes td.actions {
  width: 80px;
  vertical-align: top;
}


/**
 * Assets
 */
.assets {
  position: relative;
  padding-top: 10px;
}
.assets .assetList {
  max-width: 800px;
}
.assets .assetList .assetItemContent {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.assets .assetList .assetItemContent .assetTitle {
  color: inherit;
  flex-grow: 1;
  color: var(--tx-light) !important;
}
.assets .assetList .assetItemContent .assetDesc {
  color: inherit;
  min-width: 100px;
  color: var(--tx-light) !important;
}
.assets .assetList .assetItemContent .assetActions {
  color: inherit;
  min-width: 105px;
  color: var(--tx-light) !important;
}
.assets .assetList .assetItemContent .assetTitle .assetFilename {
  font-weight: bold;
}
.assets .assetList .assetItemContent .assetTitle .assetAVInfo {
  max-width: 570px;
  word-wrap: break-word;
  padding-top: 2px;
  font-size: 0.9em;
}

/**
 * Video Engines
 */
.videoEngines {
  position: relative;
  padding-top: 10px;
}
.videoEnginesSelectFields {
  display: flex;
  flex-direction: row;
  justify-content:flex-start;
  align-items: center;
  gap: 5px;
  margin-bottom: 10px;
}
.videoEngines .idTitle {
  font-weight: bold;
  padding-right: 5px;
}
.videoEngines .idValue {}

.videoEngines .streamDetails .detailsRow {
  display: flex;
  flex-direction: row;
  font-size: 0.8em;
  line-height: 1.2em;
}
.videoEngines .streamDetails .detailsRow .detailsTitle {
  font-weight: bold;
  text-align: right;
  margin-right: 5px;
  min-width: 50px;
}
.videoEngines .streamDetails .detailsRow .detailsValue {}
