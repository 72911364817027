.fieldset {
  border: 1px solid var(--bd-light);
  border-radius: 5px;
  margin: 0;
  padding: 20px;
}

.legend {
  font-weight: 600;
  margin: 0 -5px;
  padding: 0 5px;
}
