/* manage */
.manage2FA .successMsg:global(.ui.message.positive) {
  margin-bottom: 20px !important;
}
.manage2FA .successMsg .successMsgContent {
  margin-top: 5px;
}

/* enable */
.enable2FA .title {
  margin-bottom: 5px;
}
.enable2FA .cancel {
  text-align: center;
  margin-top: 10px;
}
.enable2FA :global(h3) {
  margin-top: 0;
  margin-bottom: 5px;
}

/*
.enable2FA .tfaEnableSummary {
}
*/

/* 
.enable2FA .tfaPhoneInput {
}
*/

.enable2FA .tfaPhoneVerify {
  margin-top: 20px;
}

.enable2FA .tfaConfirm .generate2FA {
  margin-bottom: 20px;
}
.enable2FA .tfaConfirm .generate2FA .generate2FAQR {
  margin-top: 10px;
  margin-bottom: 10px;
}
.enable2FA .tfaConfirm .generate2FA .generate2FAKey {
  overflow-wrap: break-word;
}
.enable2FA .tfaConfirm .generate2FA .enter2FA {
  margin-top: 20px;
}

/* disable */
.disable2FA .title {
  margin-bottom: 5px;
}
.disable2FA .cancel {
  text-align: center;
  margin-top: 10px;
}
.disable2FA :global(h3) {
  margin-top: 0;
  margin-bottom: 5px;
}
