.container {
  position: relative;
}

.line {
  border-right: 2px dotted var(--bd-light);
  bottom: -30px;
  left: 20px;
  position: absolute;
  top: -30px;
  transform: translateX(-1px);
  width: 2px;
}

.active {
  border-right: 2px solid var(--green);
}
