/* TODO: fix sticky header not working? & the --serverhubHeaderTop var being incorrect when its not sticky? Temp added a - 20px offset to save vertical space */
:root {
  --serverhubHeaderTop: calc(var(--header-height) + 1px - 20px);
  --serverhubHeaderHeight: 77px;
  --serverhubStreamsHeaderHeight: 36px;
}
/* :root .videoEngines {
  --serverhubStreamsHeaderHeight: 72px;
} */

/**
 * Server Select Page (when multi-server is enabled)
 */
.selectServer {
  margin-top: 10px;
}


/**
 * Base Page (common across all serverhub section pages)
 */
.serverhubPage {
  position: relative;
}
.serverhubPage .serverhubHeader {
  position: sticky;
  top: var(--serverhubHeaderTop);
  height: var(--serverhubHeaderHeight);
  z-index: 5;
  background-color: var(--bg-dark); /* stop content showing when scrolled behind */
  /* border: 1px solid red; */
}
.serverhubPage .serverhubContent {}

.serverhubPage .listView {
  margin-top: 28px;
}

/**
 * Base Page Header
 */
.serverhubPage .serverhubHeader {}
.serverhubPage .serverhubHeader .currentServerHeader {
  padding-top: 10px;
}
.serverhubPage .serverhubHeader .currentServerHeader .border {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  margin: 0px 0 10px 0;
  border: 1px solid var(--bd-mid);
  border-radius: 5px;
  padding: 6px 10px;
}
.serverhubPage .serverhubHeader .currentServerHeader .border .leftMenu {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  gap: 10px;
}
.serverhubPage .serverhubHeader .currentServerHeader .border .rightMenu {
}
.serverhubPage .serverhubHeader .currentServerHeader .currentServerName {
  font-size: 1.1em;
  display: flex;
  flex-direction: row;
  gap: 5px;
}
.serverhubPage .serverhubHeader .currentServerHeader .currentServerName .currentServerTitle {
  font-weight: bold;
}
.serverhubPage .serverhubHeader .currentServerHeader .currentServerName .currentServerValue {

}
.serverhubPage .serverhubHeader .currentServerHeader .currentServerClose {
  margin-left: 10px;
  padding: 5px 8px !important;
}
.serverhubPage .serverhubHeader .currentServerHeader.socketConnected .border {
  border-color: green;
}
.serverhubPage .serverhubHeader .currentServerHeader.socketNoConnection .border {
  border-color: orange;
}

.serverhubPage .serverhubHeader .currentServerHeader .currentServerSocket {
  min-width: 50px;
  min-height: 30px;
  display: flex;
  align-items: center;
  /* border: 1px solid red; */
}
.serverhubPage .serverhubHeader .currentServerHeader .currentServerSocket .statusTitle {
  font-weight: bold;
  padding-right: 5px;
}
.serverhubPage .serverhubHeader .currentServerHeader .currentServerSocket .statusValue {
  margin-right: 5px;
}
.serverhubPage .serverhubHeader .currentServerHeader .currentServerSocket.socketConnecting .statusValue {
  color: orange;
}
.serverhubPage .serverhubHeader .currentServerHeader .currentServerSocket.socketConnected .statusValue {
  color: green;
}
.serverhubPage .serverhubHeader .currentServerHeader .currentServerSocket.socketDisconnected .statusValue {
  color: red;
}
.serverhubPage .serverhubHeader .currentServerHeader .currentServerSocket.socketError .statusValue {
  color: red;
}

.serverhubPage .serverhubHeader .currentServerHeader .socketConnect,
.serverhubPage .serverhubHeader .currentServerHeader .socketDisconnect,
.serverhubPage .serverhubHeader .currentServerHeader .socketRefresh {
  padding: 5px 10px !important;
}

.serverhubPage.mobile .serverhubHeader {
  position: relative; /* disable sticky headers on mobile */
  top: auto;
  height: auto;
  z-index: auto;
}
.serverhubPage.mobile .serverhubHeader .currentServerHeader {
  font-size: 0.8em;
}
.serverhubPage.mobile .serverhubHeader .currentServerHeader .border .leftMenu {
  flex-direction: column;
  align-items: flex-start;
  gap: 2px;
  font-size: 0.9em;
}

/**
 * Page Specific sub-headers
 */
 .serverhubPage .contentHeader {
  position: sticky;
  top: calc(var(--serverhubHeaderHeight) + var(--header-height));
  z-index: 5;
  margin-bottom: 4px;
  height: var(--serverhubStreamsHeaderHeight);
  background-color: var(--bg-dark); /* stop content showing when scrolled behind */
}
.serverhubPage .contentHeader .contentHeaderMain {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}
.serverhubPage .contentHeader h2 {
  margin: 0;
}
.serverhubPage .contentHeader .actions {
  display: flex;
  flex-direction: row;
  gap: 5px;
  max-height: 30px;
}
.serverhubPage .contentHeader .actions button {
  font-size: 0.85em;
  font-weight: bold !important;
}
.serverhubPage .contentHeader .actions .divider {
  border-left: 1px solid #fff;
}
.serverhubPage .contentHeader .listSummary {
  text-align: center;
  flex-grow: 1;
}

.serverhubPage.mobile .contentHeader {
  position: relative; /* disable sticky headers on mobile */
  top: auto;
  height: auto;
  z-index: auto;
}
.serverhubPage.mobile .contentHeader .contentHeaderMain {
  flex-direction: column;
  align-items: flex-start;
}
.serverhubPage.mobile .contentHeader .actions {
  font-size: 0.8em;
}


.serverhubPage .contentHeaderFilter {
  margin: 8px 0 0 0;
}
.serverhubPage .contentHeaderFilter :global(.fields>.field) {
  padding: 0 !important;
}
.serverhubPage .contentHeaderFilter :global(.ui.button) {
  margin-right: 0 !important;
}

/**
 * Streamhub Tabs
 */
.tabs {
}
.tabs :global(.item) {
  background-color: var(--th-ui-actionable) !important;
  border-radius: 6px 6px 0 0 !important;
  margin-right: 2px;
}
.tabs :global(.ui.tabular.menu .item.active) {
  background-color: var(--blue) !important;
  border-radius: 6px 6px 0 0 !important;
}
.tabs :global(.item):hover {
  color: var(--tx-light) !important;
  background-color: var(--th-ui-actionable-hover) !important;
  opacity: 1.0 !important;
}

/**
 * Streamhub Tables
 * NB: this is currently overriding a load of SUI Table default css, 
 *     most of which seems to require !important adding to each flipping line :-/ 
 * UPDATE: starting to migrate away from SUI Table to using TanStack Table (React Table) v8
 *         re-using the existing css, with some minor tweaks
 * TODO: once all serverhub tables are migrated to TanStack Table, remove the SUI Table specific overrides
 */
 .serverhubPage .table { 
  width: 100%;
  border-collapse: separate !important;
  border-spacing: 0 10px !important; 
  margin-top: -10px !important; /* correct offset on first border spacing if desired */
  background-color: transparent !important;
}
.serverhubPage .table th {
  background-color: var(--bg-dark-mid) !important;
  color: rgba(255,255,255,.9)!important;
}
.serverhubPage .table thead th {
  padding: 10px 4px !important;
  border: none !important;
  text-align: left;
}
/* .serverhubPage .table tr {
  border-color: rgba(255,255,255,.1) !important;
} */
.serverhubPage .table td {
  /* border: solid 1px #000 !important; */
  border: solid 1px rgba(255,255,255,.1) !important;
  border-style: solid none !important; 
  line-height: 1.5em !important;
  padding: 10px 4px !important;
  background-color: var(--bg-dark) !important;
}
.serverhubPage .table td.collapsing {
  border-left: none !important;
}
.serverhubPage .table td:first-child {
  /* border-left-color: var(--bg-light) !important; */
  border-left-style: solid !important;
  border-top-left-radius: 6px !important;
  border-bottom-left-radius: 6px !important;
  padding-left: 10px !important;
}
.serverhubPage .table td:last-child {
  border-right-style: solid !important;
  border-bottom-right-radius: 6px !important;
  border-top-right-radius: 6px !important;
  padding-right: 10px !important;
}
.serverhubPage .table tr.active {
  background-color: transparent !important;
  color: #fff !important; /* NB: not kicking in, see the .ui.table override below to override the sui's annoying imternal !important usage */
}
.serverhubPage .table tr.active td {
  border-color: var(--ui-selected) !important;
  background-color: var(--bg-selected) !important; /* var(--bg-pg-content) !important; */
}
.serverhubPage .table:global(.ui.table tr.active) {
  color: #fff !important;
}
.serverhubPage .table tr:hover td {
  /* border-color: var(--ui-hover) !important; */
  background-color: var(--bg-hover) !important;
  /* color: var(--ui-hover) !important; */
}
.serverhubPage .table tr:hover.active td {
  /* border-color: blue !important; */
  /* background-color: red; */
  border-color: var(--ui-selected) !important;
}
.serverhubPage .table td.tableActions {
}


/**
 * Streams
 */
.streams {
  position: relative;
  padding-top: 10px;
}

.streams .buttonLeftPad {
  margin-left: 8px !important;
}

.streams .actionButtons {
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-end;
  align-items: flex-start;
}


/**
 * NB: streams table - tr AND td need `height: 100%` (or a variation of) for div within a td cell to fill the height!
 * ref: https://stackoverflow.com/a/49797662
 */
 .streams table.streamsTable {
  height: 100%;
}
.streams tr.streamRow {
  /* height: 100%; */
}

.streams table.streamsTable thead {
  position: sticky;
  top: calc(var(--serverhubHeaderHeight) + var(--header-height) + var(--serverhubStreamsHeaderHeight));
  z-index: 4;
  background-color: var(--bg-dark); /* stop content showing when scrolled behind */
}
.streams table.streamsTable th.streamSelect {
  padding: 5px 10px 0 10px !important;
}

.streams td.streamIdAndImg {
  width: 110px;
}
.streams td.streamIdAndImg .idRow {
  font-size: 0.8em;
  font-weight: bold;
  line-height: 1.6em;
  padding: 2px 2px 2px 5px;
  background-color: var(--bg-dark-mid);
  text-align: center;
}
.streams td.streamIdAndImg .idRow.idStream {
  font-size: 0.9em;
}
.streams td.streamIdAndImg .idRow.idSource {
  /* font-weight: normal; */
}
.streams td.streamDetailsCell {
  height: 100%;
}
.streams .streamDetails {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start; 
  height: 100%; /* NB: parent table, tr & td need heights set for this to work! */
}
.streams .streamDetails .streamName {
  font-weight: bold;
}
.streams .streamDetails .detailsRow {
  display: flex;
  flex-direction: row;
  font-size: 0.8em;
  line-height: 1.2em;
}
.streams .streamDetails .detailsRow .detailsTitle {
  font-weight: bold;
  text-align: right;
  margin-right: 5px;
  min-width: 50px;
}
.streams .streamDetails .detailsRow .detailsValue {}

.streams .statusRow {
  display: flex;
  flex-direction: row;
  font-size: 1em;
  line-height: 1.2em;
}
.streams .statusRow .statusTitle {
  font-weight: bold;
  text-align: right;
  margin-right: 5px;
  min-width: 60px;
}

.streams.mobile {
  /* border: 1px solid red; */
}
.streams.mobile table.streamsTable thead {
  position: relative; /* disable sticky headers on mobile */
  top: auto;
}
